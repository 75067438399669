

   /*Не записанные префиксы *08*30*31*32*33*34*38*39*50*53*55*56*58*59*60*61*6263*64*65*66*67*68*70*71*77*78*79*80*81*82*83*84*85*86*87*88*89*90*91*92*93*94*95*96*97*/    
/***************************************  900  ********************************************************* */
type Operator = {
    startNum: string;
    operator: string;
};
type Country = {
    code: string; // Код страны
    length: number; // Общая длина номера
    countryName: string; // Название страны
};
const operators: Operator[] = [
        { startNum: "900000", operator: "Tele2" },
        { startNum: "900001", operator: "Tele2" },
        { startNum: "900002", operator: "Tele2" },
        { startNum: "900003", operator: "Tele2" },
        { startNum: "900004", operator: "Tele2" },
        { startNum: "900005", operator: "Tele2" },
        { startNum: "9000060", operator: "Tele2" },
        { startNum: "9000061", operator: "Tele2" },
        { startNum: "9000062", operator: "Tele2" },
        { startNum: "9000063", operator: "Tele2" },
        { startNum: "9000064", operator: "Tele2" },
        { startNum: "9000065", operator: "Tele2" },
        { startNum: "9000066", operator: "Tele2" },
        { startNum: "9000067", operator: "Tele2" },
        { startNum: "9000068", operator: "Tele2" },
        { startNum: "9000069", operator: "Tele2" },
        { startNum: "900007", operator: "Tele2" },
        { startNum: "900008", operator: "Tele2" },
        { startNum: "900009", operator: "Tele2" },
        { startNum: "90001", operator: "Tele2" },
        { startNum: "90002", operator: "Tele2" },
        { startNum: "90003", operator: "Tele2" },
        { startNum: "90004", operator: "Tele2" },
        { startNum: "90005", operator: "Tele2" },
        { startNum: "90006", operator: "Tele2" },
        { startNum: "90007", operator: "Tele2" },
        { startNum: "90008", operator: "Tele2" },
        { startNum: "90009", operator: "Tele2" },
        { startNum: "90010", operator: "Tele2" },
        { startNum: "90011", operator: "Tele2" },
        { startNum: "90012", operator: "Tele2" },
        { startNum: "90013", operator: "Tele2" },
        { startNum: "90014", operator: "Tele2" },
        { startNum: "90015", operator: "Tele2" },
        { startNum: "900160", operator: "Tele2" },
        { startNum: "900161", operator: "Tele2" },
        { startNum: "900162", operator: "Tele2" },
        { startNum: "900163", operator: "Tele2" },
        { startNum: "900164", operator: "Tele2" },
        { startNum: "900165", operator: "Tele2" },
        { startNum: "900166", operator: "Tele2" },
        { startNum: "900167", operator: "Tele2" },
        { startNum: "900168", operator: "Tele2" },
        { startNum: "900169", operator: "Tele2" },
        { startNum: "900170", operator: "Tele2" },
        { startNum: "900171", operator: "Tele2" },
        { startNum: "900172", operator: "Tele2" },
        { startNum: "900173", operator: "Tele2" },
        { startNum: "900174", operator: "Tele2" },
        { startNum: "900175", operator: "Tele2" },
        { startNum: "900176", operator: "Tele2" },
        { startNum: "900177", operator: "Tele2" },
        { startNum: "900178", operator: "Tele2" },
        { startNum: "900179", operator: "Tele2" },
        { startNum: "900180", operator: "Tele2" },
        { startNum: "900181", operator: "Tele2" },
        { startNum: "900182", operator: "Tele2" },
        { startNum: "900183", operator: "Tele2" },
        { startNum: "900184", operator: "Tele2" },
        { startNum: "900185", operator: "Tele2" },
        { startNum: "900186", operator: "Tele2" },
        { startNum: "900187", operator: "Tele2" },
        { startNum: "900188", operator: "Tele2" },
        { startNum: "900189", operator: "Tele2" },
        { startNum: "900190", operator: "Tele2" },
        { startNum: "900191", operator: "Tele2" },
        { startNum: "900192", operator: "Tele2" },
        { startNum: "900193", operator: "Tele2" },
        { startNum: "900194", operator: "Tele2" },
        { startNum: "900195", operator: "Tele2" },
        { startNum: "900196", operator: "Tele2" },
        { startNum: "900198", operator: "МОТИВ" },
        { startNum: "900199", operator: "МОТИВ" },
        { startNum: "90020", operator: "МОТИВ" },
        { startNum: "900210", operator: "МОТИВ" },
        { startNum: "900211", operator: "МОТИВ" },
        { startNum: "900212", operator: "МОТИВ" },
        { startNum: "900213", operator: "МОТИВ" },
        { startNum: "900214", operator: "МОТИВ" },
        { startNum: "900215", operator: "МОТИВ" },
        { startNum: "900216", operator: "МОТИВ" },
        { startNum: "900217", operator: "Tele2" },
        { startNum: "9002180", operator: "Tele2" },
        { startNum: "9002181", operator: "Tele2" },
        { startNum: "9002182", operator: "Tele2" },
        { startNum: "9002183", operator: "Tele2" },
        { startNum: "9002184", operator: "Tele2" },
        { startNum: "9002185", operator: "Tele2" },
        { startNum: "9002186", operator: "Tele2" },
        { startNum: "9002187", operator: "Tele2" },
        { startNum: "900219", operator: "Tele2" },
        { startNum: "900220", operator: "Tele2" },
        { startNum: "900221", operator: "Tele2" },
        { startNum: "900222", operator: "Tele2" },
        { startNum: "900223", operator: "Tele2" },
        { startNum: "900224", operator: "Tele2" },
        { startNum: "900225", operator: "Tele2" },
        { startNum: "900226", operator: "Tele2" },
        { startNum: "900227", operator: "Tele2" },
        { startNum: "900228", operator: "Tele2" },
        { startNum: "900229", operator: "Tele2" },
        { startNum: "9002189", operator: "Сим Телеком" },
        { startNum: "9002188", operator: "Сим  Телеком" },
        { startNum: "90023", operator: "Tele2" },
        { startNum: "90024", operator: "Tele2" },
        { startNum: "90025", operator: "Tele2" },
        { startNum: "90026", operator: "Tele2" },
        { startNum: "90027", operator: "Tele2" },
        { startNum: "90028", operator: "Tele2" },
        { startNum: "900290", operator: "Tele2" },
        { startNum: "900291", operator: "Tele2" },
        { startNum: "900292", operator: "Tele2" },
        { startNum: "900293", operator: "Tele2" },
        { startNum: "900294", operator: "Tele2" },
        { startNum: "900295", operator: "Tele2" },
        { startNum: "900296", operator: "Tele2" },
        { startNum: "900297", operator: "Tele2" },
        { startNum: "900298", operator: "Tele2" },
        { startNum: "900299", operator: "Tele2" },
        { startNum: "90030", operator: "Tele2" },
        { startNum: "900310", operator: "Tele2" },
        { startNum: "900311", operator: "Tele2" },
        { startNum: "900312", operator: "Tele2" },
        { startNum: "900313", operator: "Tele2" },
        { startNum: "900314", operator: "Tele2" },
        { startNum: "900315", operator: "Tele2" },
        { startNum: "900316", operator: "Tele2" },
        { startNum: "900317", operator: "Tele2" },
        { startNum: "900318", operator: "Tele2" },
        { startNum: "900319", operator: "Tele2" },
        { startNum: "90032", operator: "Tele2" },
        { startNum: "900330", operator: "Tele2" },
        { startNum: "900331", operator: "Tele2" },
        { startNum: "900332", operator: "Tele2" },
        { startNum: "900333", operator: "Tele2" },
        { startNum: "900334", operator: "Tele2" },
        { startNum: "900335", operator: "Beeline" },
        { startNum: "900336", operator: "Beeline" },
        { startNum: "900337", operator: "Beeline" },
        { startNum: "900338", operator: "Beeline" },
        { startNum: "900339", operator: "Beeline" },
        { startNum: "900340", operator: "Beeline" },
        { startNum: "900341", operator: "Beeline" },
        { startNum: "900342", operator: "Beeline" },
        { startNum: "900343", operator: "Beeline" },
        { startNum: "900344", operator: "Beeline" },
        { startNum: "900345", operator: "Tele2" },
        { startNum: "900346", operator: "Tele2" },
        { startNum: "900347", operator: "Tele2" },
        { startNum: "900348", operator: "Tele2" },
        { startNum: "900349", operator: "Tele2" },
        { startNum: "900350", operator: "Tele2" },
        { startNum: "900351", operator: "Tele2" },
        { startNum: "900351", operator: "Tele2" },
        { startNum: "900352", operator: "Tele2" },
        { startNum: "900353", operator: "Tele2" },
        { startNum: "900354", operator: "Tele2" },
        { startNum: "900355", operator: "Tele2" },
        { startNum: "900356", operator: "Tele2" },
        { startNum: "900357", operator: "Tele2" },
        { startNum: "900358", operator: "Tele2" },
        { startNum: "900359", operator: "Tele2" },
        { startNum: "90036", operator: "Tele2" },
        { startNum: "900370", operator: "Tele2" },
        { startNum: "900371", operator: "Tele2" },
        { startNum: "900372", operator: "Tele2" },
        { startNum: "900373", operator: "Tele2" },
        { startNum: "900374", operator: "Tele2" },
        { startNum: "900375", operator: "МОТИВ" },
        { startNum: "900376", operator: "МОТИВ" },
        { startNum: "900377", operator: "МОТИВ" },
        { startNum: "900378", operator: "МОТИВ" },
        { startNum: "900379", operator: "МОТИВ" },
        { startNum: "900380", operator: "МОТИВ" },
        { startNum: "900381", operator: "МОТИВ" },
        { startNum: "900382", operator: "МОТИВ" },
        { startNum: "900383", operator: "МОТИВ" },
        { startNum: "900384", operator: "МОТИВ" },
        { startNum: "900385", operator: "МОТИВ" },
        { startNum: "900386", operator: "МОТИВ" },
        { startNum: "900387", operator: "МОТИВ" },
        { startNum: "900388", operator: "МОТИВ" },
        { startNum: "900389", operator: "МОТИВ" },
        { startNum: "900390", operator: "МОТИВ" },
        { startNum: "900391", operator: "МОТИВ" },
        { startNum: "900392", operator: "МОТИВ" },
        { startNum: "900393", operator: "МОТИВ" },
        { startNum: "900394", operator: "МОТИВ" },
        { startNum: "900395", operator: "МОТИВ" },
        { startNum: "900396", operator: "МОТИВ" },
        { startNum: "900397", operator: "МОТИВ" },
        { startNum: "900398", operator: "МОТИВ" },
        { startNum: "900399", operator: "МОТИВ" },
        { startNum: "900400", operator: "МОТИВ" },
        { startNum: "900401", operator: "МОТИВ" },
        { startNum: "900402", operator: "МОТИВ" },
        { startNum: "900403", operator: "МОТИВ" },
        { startNum: "900404", operator: "МОТИВ" },
        { startNum: "900405", operator: "Tele2" },
        { startNum: "900406", operator: "Tele2" },
        { startNum: "900407", operator: "Tele2" },
        { startNum: "900408", operator: "Tele2" },
        { startNum: "900409", operator: "Tele2" },
        { startNum: "900410", operator: "Tele2" },
        { startNum: "900411", operator: "Tele2" },
        { startNum: "900412", operator: "Tele2" },
        { startNum: "900413", operator: "Tele2" },
        { startNum: "900414", operator: "Tele2" },
        { startNum: "900415", operator: "Tele2" },
        { startNum: "900416", operator: "Tele2" },
        { startNum: "900417", operator: "Tele2" },
        { startNum: "900418", operator: "Tele2" },
        { startNum: "900419", operator: "Tele2" },
        { startNum: "900420", operator: "Tele2" },
        { startNum: "900421", operator: "Tele2" },
        { startNum: "900422", operator: "Tele2" },
        { startNum: "900423", operator: "Tele2" },
        { startNum: "900424", operator: "Tele2" },
        { startNum: "900425", operator: "Tele2" },
        { startNum: "900426", operator: "Tele2" },
        { startNum: "900427", operator: "Tele2" },
        { startNum: "900428", operator: "Tele2" },
        { startNum: "900429", operator: "Tele2" },
        { startNum: "900430", operator: "Tele2" },
        { startNum: "900431", operator: "Tele2" },
        { startNum: "900432", operator: "Tele2" },
        { startNum: "900433", operator: "Tele2" },
        { startNum: "900434", operator: "Tele2" },
        { startNum: "900435", operator: "Tele2" },
        { startNum: "900436", operator: "Tele2" },
        { startNum: "900437", operator: "Tele2" },
        { startNum: "900438", operator: "Tele2" },
        { startNum: "900439", operator: "Tele2" },
        { startNum: "900440", operator: "Tele2" },
        { startNum: "900441", operator: "Tele2" },
        { startNum: "900442", operator: "Tele2" },
        { startNum: "900443", operator: "Tele2" },
        { startNum: "900444", operator: "Tele2" },
        { startNum: "900445", operator: "Tele2" },
        { startNum: "900446", operator: "Tele2" },
        { startNum: "900447", operator: "Tele2" },
        { startNum: "900448", operator: "Tele2" },
        { startNum: "900449", operator: "Tele2" },
        { startNum: "900450", operator: "Tele2" },
        { startNum: "900451", operator: "Tele2" },
        { startNum: "900452", operator: "Tele2" },
        { startNum: "900453", operator: "Tele2" },
        { startNum: "900454", operator: "Tele2" },
        { startNum: "900455", operator: "Tele2" },
        { startNum: "900456", operator: "Tele2" },
        { startNum: "900457", operator: "Tele2" },
        { startNum: "900458", operator: "Tele2" },
        { startNum: "900459", operator: "Tele2" },
        { startNum: "900460", operator: "Tele2" },
        { startNum: "900461", operator: "Tele2" },
        { startNum: "900462", operator: "Tele2" },
        { startNum: "900463", operator: "Tele2" },
        { startNum: "900464", operator: "Tele2" },
        { startNum: "9004651", operator: "Сим Телеком" },
        { startNum: "9004652", operator: "Сим Телеком" },
        { startNum: "9004653", operator: "Сим Телеком" },
        { startNum: "9004654", operator: "Сим Телеком" },
        { startNum: "9004655", operator: "Tele2" },
        { startNum: "9004656", operator: "Tele2" },
        { startNum: "9004657", operator: "Tele2" },
        { startNum: "9004658", operator: "Tele2" },
        { startNum: "9004659", operator: "Tele2" },
        { startNum: "9004660", operator: "Tele2" },
        { startNum: "900466", operator: "Tele2" },
        { startNum: "900470", operator: "Tele2" },
        { startNum: "90047", operator: "Tele2" },
        { startNum: "900471", operator: "Tele2" },
        { startNum: "900472", operator: "Tele2" },
        { startNum: "900473", operator: "Tele2" },
        { startNum: "900474", operator: "Tele2" },
        { startNum: "900475", operator: "Tele2" },
        { startNum: "900476", operator: "Tele2" },
        { startNum: "900477", operator: "Tele2" },
        { startNum: "900478", operator: "Tele2" },
        { startNum: "900479", operator: "Tele2" },
        { startNum: "900480", operator: "Tele2" },
        { startNum: "900481", operator: "Tele2" },
        { startNum: "900482", operator: "Tele2" },
        { startNum: "900483", operator: "Tele2" },
        { startNum: "900484", operator: "Tele2" },
        { startNum: "900485", operator: "Tele2" },
        { startNum: "900486", operator: "Tele2" },
        { startNum: "900487", operator: "Tele2" },
        { startNum: "900488", operator: "Tele2" },
        { startNum: "9004890", operator: "Tele2" },
        { startNum: "9004891", operator: "Tele2" },
        { startNum: "9004892", operator: "Tele2" },
        { startNum: "9004893", operator: "Tele2" },
        { startNum: "9004894", operator: "Tele2" },
        { startNum: "9004895", operator: "Сим Телеком" },
        { startNum: "9004896", operator: "Сим Телеком" },
        { startNum: "9004897", operator: "Сим Телеком" },
        { startNum: "9004898", operator: "Сим Телеком" },
        { startNum: "9004899", operator: "Сим Телеком" },
        { startNum: "90049", operator: "Tele2" },
        { startNum: "90050", operator: "Tele2" },
        { startNum: "90051", operator: "Tele2" },
        { startNum: "90052", operator: "Tele2" },
        { startNum: "90053", operator: "Tele2" },
        { startNum: "90054", operator: "Tele2" },
        { startNum: "900550", operator: "Tele2" },
        { startNum: "900551", operator: "Tele2" },
        { startNum: "900552", operator: "Tele2" },
        { startNum: "900553", operator: "Tele2" },
        { startNum: "900554", operator: "Tele2" },
        { startNum: "900555", operator: "Cим Телеком" },
        { startNum: "900556", operator: "Tele2" },
        { startNum: "900557", operator: "Tele2" },
        { startNum: "900558", operator: "Tele2" },
        { startNum: "900559", operator: "Tele2" },
        { startNum: "900560", operator: "Tele2" },
        { startNum: "90057", operator: "Tele2" },
        { startNum: "90058", operator: "Tele2" },
        { startNum: "90059", operator: "Tele2" },
        { startNum: "90060", operator: "Tele2" },
        { startNum: "90061", operator: "Tele2" },
        { startNum: "90062", operator: "Tele2" },
        { startNum: "90063", operator: "Tele2" },
        { startNum: "90064", operator: "Tele2" },
        { startNum: "90065", operator: "Tele2" },
        { startNum: "90066", operator: "Tele2" },
        { startNum: "90067", operator: "Tele2" },
        { startNum: "90068", operator: "Tele2" },
        { startNum: "90069", operator: "Tele2" },
        { startNum: "90070", operator: "Антарес" },
        { startNum: "90071", operator: "Антарес" },
        { startNum: "90072", operator: "Антарес" },
        { startNum: "90073", operator: "Антарес" },
        { startNum: "90074", operator: "Антарес" },
        { startNum: "90075", operator: "Антарес" },
        { startNum: "90076", operator: "Антарес" },
        { startNum: "90077", operator: "Антарес" },
        { startNum: "90078", operator: "Антарес" },
        { startNum: "90079", operator: "Антарес" },
        { startNum: "900800", operator: "Интеграл" },
        { startNum: "900801", operator: "Интеграл" },
        { startNum: "900802", operator: "Интеграл" },
        { startNum: "900803", operator: "Интеграл" },
        { startNum: "900804", operator: "Интеграл" },
        { startNum: "900805", operator: "Интеграл" },
        { startNum: "900806", operator: "Интеграл" },
        { startNum: "900807", operator: "Интеграл" },
        { startNum: "900808", operator: "Интеграл" },
        { startNum: "900809", operator: "Интеграл" },
        { startNum: "900810", operator: "Интеграл" },
        { startNum: "900811", operator: "Интеграл" },
        { startNum: "9008120", operator: "Интеграл" },
        { startNum: "9008121", operator: "Интеграл" },
        { startNum: "9008122", operator: "Интеграл" },
        { startNum: "9008123", operator: "Интеграл" },
        { startNum: "9008124", operator: "Интеграл" },
        { startNum: "9008125", operator: "Интеграл" },
        { startNum: "9008126", operator: "Интеграл" },
        { startNum: "9008127", operator: "Интеграл" },
        { startNum: "9008128", operator: "Интеграл" },
        { startNum: "9008129", operator: "Интеграл" },
        { startNum: "900813", operator: "Интеграл" },
        { startNum: "900814", operator: "Интеграл" },
        { startNum: "900815", operator: "Интеграл" },
        { startNum: "900816", operator: "Интеграл" },
        { startNum: "900817", operator: "Интеграл" },
        { startNum: "900818", operator: "Интеграл" },
        { startNum: "900819", operator: "Интеграл" },
        { startNum: "900820", operator: "Интеграл" },
        { startNum: "900821", operator: "Интеграл" },
        { startNum: "900822", operator: "Интеграл" },
        { startNum: "900823", operator: "Интеграл" },
        { startNum: "900824", operator: "Интеграл" },
        { startNum: "900825", operator: "Интеграл" },
        { startNum: "900826", operator: "Интеграл" },
        { startNum: "900827", operator: "Интеграл" },
        { startNum: "900828", operator: "Интеграл" },
        { startNum: "900829", operator: "Интеграл" },
        { startNum: "900830", operator: "Интеграл" },
        { startNum: "900831", operator: "Интеграл" },
        { startNum: "900832", operator: "Интеграл" },
        { startNum: "900833", operator: "Интеграл" },
        { startNum: "900834", operator: "Интеграл" },
        { startNum: "900835", operator: "Интеграл" },
        { startNum: "900836", operator: "Интеграл" },
        { startNum: "900837", operator: "Интеграл" },
        { startNum: "900838", operator: "Интеграл" },
        { startNum: "900839", operator: "Интеграл" },
        { startNum: "90084", operator: "Интеграл" },
        { startNum: "90085", operator: "Арктур" },
        { startNum: "900900", operator: "Газпром телеком" },
        { startNum: "900900", operator: "Tele2" },
        { startNum: "900901", operator: "Tele2" },
        { startNum: "900902", operator: "Tele2" },
        { startNum: "900903", operator: "Tele2" },
        { startNum: "900904", operator: "Tele2" },
        { startNum: "900905", operator: "Tele2" },
        { startNum: "900906", operator: "Tele2" },
        { startNum: "900907", operator: "Tele2" },
        { startNum: "900908", operator: "Tele2" },
        { startNum: "900909", operator: "Tele2" },
        { startNum: "900910", operator: "Tele2" },
        { startNum: "900911", operator: "Tele2" },
        { startNum: "900912", operator: "Tele2" },
        { startNum: "900913", operator: "Tele2" },
        { startNum: "900914", operator: "Tele2" },
        { startNum: "900915", operator: "Tele2" },
        { startNum: "900916", operator: "Tele2" },
        { startNum: "900917", operator: "Tele2" },
        { startNum: "900918", operator: "Tele2" },
        { startNum: "900919", operator: "Tele2" },
        { startNum: "90092", operator: "Tele2" },
        { startNum: "90093", operator: "Tele2" },
        { startNum: "90094", operator: "Tele2" },
        { startNum: "90095", operator: "Tele2" },
        { startNum: "90096", operator: "Tele2" },
        { startNum: "90097", operator: "Tele2" },
        { startNum: "90098", operator: "Tele2" },
        { startNum: "90099", operator: "Tele2" },
/***************************************  999  ********************************************************* */
          { startNum: "999000", operator: "Yota" },
          { startNum: "999001", operator: "Yota" },
          { startNum: "999002", operator: "Yota" },
          { startNum: "999003", operator: "Yota" },
          { startNum: "9990040", operator: "Yota" },
          { startNum: "9990041", operator: "Yota" },
          { startNum: "9990042", operator: "Yota" },
          { startNum: "9990043", operator: "Yota" },
          { startNum: "9990044", operator: "Yota" },
          { startNum: "9990045", operator: "Мегафон" },
          { startNum: "9990046", operator: "Мегафон" },
          { startNum: "9990047", operator: "Мегафон" },
          { startNum: "9990048", operator: "Мегафон" },
          { startNum: "9990049", operator: "Мегафон" },
          { startNum: "999005", operator: "Мегафон" },
          { startNum: "999006", operator: "Мегафон" },
          { startNum: "999007", operator: "Мегафон" },
          { startNum: "999008", operator: "Мегафон" },
          { startNum: "999009", operator: "Мегафон" },
          { startNum: "9990100", operator: "Yota" },
          { startNum: "9990101", operator: "Yota" },
          { startNum: "9990102", operator: "Yota" },
          { startNum: "999013", operator: "Мегафон" },
          { startNum: "999014", operator: "Мегафон" },
          { startNum: "999015", operator: "Мегафон" },
          { startNum: "999016", operator: "Мегафон" },
          { startNum: "999017", operator: "Мегафон" },
          { startNum: "999018", operator: "Мегафон" },
          { startNum: "999019", operator: "Мегафон" },
        { startNum: "9990200", operator: "Yota" },
        { startNum: "9990201", operator: "Yota" },
        { startNum: "9990202", operator: "Yota" },
        { startNum: "999023", operator: "Мегафон" },
        { startNum: "999024", operator: "Мегафон" },
        { startNum: "999025", operator: "Мегафон" },
        { startNum: "999026", operator: "Мегафон" },
        { startNum: "999027", operator: "Мегафон" },
        { startNum: "999028", operator: "Мегафон" },
        { startNum: "999029", operator: "Мегафон" },
        { startNum: "9990300", operator: "Yota" },
        { startNum: "9990301", operator: "Yota" },
        { startNum: "9990302", operator: "Yota" },
{ startNum: "999033", operator: "Мегафон" },
{ startNum: "999034", operator: "Мегафон" },
{ startNum: "999035", operator: "Мегафон" },
{ startNum: "999036", operator: "Мегафон" },
{ startNum: "999037", operator: "Мегафон" },
{ startNum: "999038", operator: "Мегафон" },
{ startNum: "999039", operator: "Мегафон" },
{ startNum: "999040", operator: "Yota" },
{ startNum: "999041", operator: "Мегафон" },
{ startNum: "999042", operator: "Мегафон" },
{ startNum: "999043", operator: "Мегафон" },
{ startNum: "999044", operator: "Мегафон" },
{ startNum: "999045", operator: "Мегафон" },
{ startNum: "999046", operator: "Мегафон" },
{ startNum: "999047", operator: "Мегафон" },
{ startNum: "999048", operator: "Мегафон" },
{ startNum: "999049", operator: "Мегафон" },
{ startNum: "999050", operator: "Мегафон" },
{ startNum: "999051", operator: "Мегафон" },
{ startNum: "999052", operator: "Мегафон" },
{ startNum: "999053", operator: "Мегафон" },
{ startNum: "999054", operator: "Мегафон" },
{ startNum: "999055", operator: "МТТ" },
{ startNum: "999056", operator: "Мегафон"},
{ startNum: "999057", operator: "Yota"},
{ startNum: "999058", operator: "Yota" },
{ startNum: "999059", operator: "Yota"},
{ startNum: "9990700", operator: "Yota" },
{ startNum: "9990701", operator: "Yota" },
{ startNum: "9990702", operator: "Yota" },
{ startNum: "9990703", operator: "Мегафон" },
{ startNum: "9990704", operator: "Мегафон" },
{ startNum: "9990705", operator: "Мегафон" },
{ startNum: "9990706", operator: "Мегафон" },
{ startNum: "9990707", operator: "Мегафон" },
{ startNum: "9990708", operator: "Мегафон" },
{ startNum: "9990709", operator: "Мегафон" },
{ startNum: "999071", operator: "Мегафон" },
{ startNum: "999072", operator: "Мегафон" },
{ startNum: "999073", operator: "Мегафон" },
{ startNum: "999074", operator: "Мегафон" },
{ startNum: "999075", operator: "Мегафон" },
{ startNum: "999076", operator: "Мегафон" },
{ startNum: "999077", operator: "Мегафон" },
{ startNum: "999078", operator: "Мегафон" },
{ startNum: "999079", operator: "Мегафон" },
{ startNum: "999080", operator: "Yota" },
{ startNum: "999081", operator: "Мегафон" },
{ startNum: "999082", operator: "Yota" },
{ startNum: "999083", operator: "Yota" },
{ startNum: "999084", operator: "Yota" },
{ startNum: "999085", operator: "Yota" },
{ startNum: "999086", operator: "Yota" },
{ startNum: "999087", operator: "Yota" },
{ startNum: "999088", operator: "Yota" },
{ startNum: "999089", operator: "Yota" },
{ startNum: "9990900", operator: "Yota" },
{ startNum: "9990901", operator: "Yota" },
{ startNum: "9990902", operator: "Yota" },
{ startNum: "9990903", operator: "Мегафон" },
{ startNum: "9990904", operator: "Мегафон" },
{ startNum: "9990905", operator: "Мегафон" },
{ startNum: "9990906", operator: "Мегафон" },
{ startNum: "9990907", operator: "Мегафон" },
{ startNum: "9990908", operator: "Мегафон" },
{ startNum: "9990909", operator: "Мегафон" },
{ startNum: "9990910", operator: "Мегафон" },
{ startNum: "9990911", operator: "Мегафон" },
{ startNum: "9990912", operator: "Мегафон" },
{ startNum: "9990913", operator: "Мегафон" },
{ startNum: "9990914", operator: "Мегафон" },
{ startNum: "9990915", operator: "Мегафон" },
{ startNum: "9990916", operator: "Мегафон" },
{ startNum: "9990917", operator: "Мегафон" },
{ startNum: "9990918", operator: "Мегафон" },
{ startNum: "9990919", operator: "Мегафон" },
{ startNum: "9990920", operator: "Мегафон" },
{ startNum: "9990921", operator: "Мегафон" },
{ startNum: "9990922", operator: "Мегафон" },
{ startNum: "9990923", operator: "Мегафон" },
{ startNum: "9990924", operator: "Мегафон" },
{ startNum: "9990925", operator: "Мегафон" },
{ startNum: "9990926", operator: "Мегафон" },
{ startNum: "9990927", operator: "Мегафон" },
{ startNum: "9990928", operator: "Мегафон" },
{ startNum: "9990929", operator: "Мегафон" },
{ startNum: "9990930", operator: "Мегафон" },
{ startNum: "9990931", operator: "Мегафон" },
{ startNum: "9990932", operator: "Мегафон" },
{ startNum: "9990933", operator: "Мегафон" },
{ startNum: "9990934", operator: "Мегафон" },
{ startNum: "9990935", operator: "Мегафон" },
{ startNum: "9990936", operator: "Мегафон" },
{ startNum: "9990937", operator: "Мегафон" },
{ startNum: "9990938", operator: "Мегафон" },
{ startNum: "9990939", operator: "Мегафон" },
{ startNum: "9990940", operator: "Мегафон" },
{ startNum: "9990941", operator: "Мегафон" },
{ startNum: "9990942", operator: "Мегафон" },
{ startNum: "9990943", operator: "Мегафон" },
{ startNum: "9990944", operator: "Мегафон" },
{ startNum: "9990945", operator: "Мегафон" },
{ startNum: "9990946", operator: "Мегафон" },
{ startNum: "9990947", operator: "Мегафон" },
{ startNum: "9990948", operator: "Мегафон" },
{ startNum: "9990949", operator: "Мегафон" },
{ startNum: "999095", operator: "Yota" },
{ startNum: "999096", operator: "Yota" },
{ startNum: "9990970", operator: "Yota" },
{ startNum: "9990971", operator: "Мегафон" },
{ startNum: "9990972", operator: "Мегафон" },
{ startNum: "9990973", operator: "Мегафон" },
{ startNum: "9990974", operator: "Мегафон" },
{ startNum: "9990975", operator: "Мегафон" },
{ startNum: "9990976", operator: "Мегафон" },
{ startNum: "9990977", operator: "Мегафон" },
{ startNum: "9990978", operator: "Мегафон" },
{ startNum: "9990979", operator: "Мегафон" },
{ startNum: "9990980", operator: "Мегафон" },
{ startNum: "9990981", operator: "Мегафон" },
{ startNum: "9990982", operator: "Мегафон" },
{ startNum: "9990983", operator: "Мегафон" },
{ startNum: "9990984", operator: "Мегафон" },
{ startNum: "9990985", operator: "Мегафон" },
{ startNum: "9990986", operator: "Мегафон" },
{ startNum: "9990987", operator: "Мегафон" },
{ startNum: "9990988", operator: "Мегафон" },
{ startNum: "9990989", operator: "Мегафон" },
{ startNum: "999099", operator: "Мегафон" },
{ startNum: "999100", operator: "Yota" },
{ startNum: "999101", operator: "Мегафон" },
{ startNum: "999102", operator: "Мегафон" },
{ startNum: "999103", operator: "Мегафон" },
{ startNum: "999104", operator: "Мегафон" },
{ startNum: "999105", operator: "Yota" },
{ startNum: "9991060", operator: "Yota" },
{ startNum: "9991061", operator: "Yota" },
{ startNum: "9991062", operator: "Yota" },
{ startNum: "9991063", operator: "Yota" },
{ startNum: "9991064", operator: "Yota" },
{ startNum: "9991065", operator: "Мегафон" },
{ startNum: "9991066", operator: "Мегафон" },
{ startNum: "9991067", operator: "Мегафон" },
{ startNum: "9991068", operator: "Мегафон" },
{ startNum: "9991069", operator: "Мегафон" },
{ startNum: "999107", operator: "Мегафон" },
{ startNum: "999108", operator: "Мегафон" },
{ startNum: "999109", operator: "Yota" },
{ startNum: "999110", operator: "Yota" },
{ startNum: "999111", operator: "Мегафон" },
{ startNum: "999112", operator: "Мегафон" },
{ startNum: "999113", operator: "Мегафон" },
{ startNum: "999114", operator: "Yota" },
{ startNum: "999115", operator: "Yota" },
{ startNum: "999116", operator: "Мегафон" },
{ startNum: "999117", operator: "Мегафон" },
{ startNum: "999118", operator: "Мегафон" },
{ startNum: "999119", operator: "Мегафон" },
{ startNum: "999120", operator: "Yota" },
{ startNum: "999121", operator: "Yota" },
{ startNum: "999122", operator: "Мегафон" },
{ startNum: "999123", operator: "Yota" },
{ startNum: "999124", operator: "Yota" },
{ startNum: "999125", operator: "Yota" },
{ startNum: "999126", operator: "Yota" },
{ startNum: "999127", operator: "Yota" },
{ startNum: "999128", operator: "Yota" },
{ startNum: "999129", operator: "Yota" },
{ startNum: "999130", operator: "Yota" },
{ startNum: "999131", operator: "Yota" },
{ startNum: "999132", operator: "Yota" },
{ startNum: "999133", operator: "Yota" },
{ startNum: "999134", operator: "Yota" },
{ startNum: "999135", operator: "Мегафон" },
{ startNum: "999136", operator: "Yota" },
{ startNum: "999137", operator: "Yota" },
{ startNum: "999138", operator: "Yota" },
{ startNum: "999139", operator: "Yota" },
{ startNum: "999140", operator: "Yota" },
{ startNum: "999141", operator: "Yota" },
{ startNum: "999142", operator: "Yota" },
{ startNum: "999143", operator: "Yota" },
{ startNum: "999144", operator: "Yota" },
{ startNum: "999145", operator: "Yota" },
{ startNum: "999148", operator: "Мегафон" },
{ startNum: "999149", operator: "Мегафон" },
{ startNum: "9991500", operator: "Yota" },
{ startNum: "9991501", operator: "Yota" },
{ startNum: "9991502", operator: "Yota" },
{ startNum: "9991503", operator: "Мегафон" },
{ startNum: "9991504", operator: "Мегафон" },
{ startNum: "9991505", operator: "Мегафон" },
{ startNum: "9991506", operator: "Мегафон" },
{ startNum: "9991507", operator: "Мегафон" },
{ startNum: "9991508", operator: "Мегафон" },
{ startNum: "9991509", operator: "Мегафон" },
{ startNum: "9991510", operator: "Мегафон" },
{ startNum: "9991511", operator: "Мегафон" },
{ startNum: "9991512", operator: "Мегафон" },
{ startNum: "9991513", operator: "Мегафон" },
{ startNum: "9991514", operator: "Мегафон" },
{ startNum: "9991515", operator: "Мегафон" },
{ startNum: "9991516", operator: "Мегафон" },
{ startNum: "9991517", operator: "Мегафон" },
{ startNum: "9991518", operator: "Мегафон" },
{ startNum: "9991519", operator: "Мегафон" },
{ startNum: "999152", operator: "Мегафон" },
{ startNum: "9991530", operator: "Мегафон" },
{ startNum: "9991531", operator: "Мегафон" },
{ startNum: "9991532", operator: "Мегафон" },
{ startNum: "9991533", operator: "Мегафон" },
{ startNum: "9991534", operator: "Мегафон" },
{ startNum: "9991535", operator: "Мегафон" },
{ startNum: "9991536", operator: "Мегафон" },
{ startNum: "9991537", operator: "Мегафон" },
{ startNum: "9991538", operator: "Мегафон" },
{ startNum: "9991539", operator: "Мегафон" },
{ startNum: "9991540", operator: "Мегафон" },
{ startNum: "9991541", operator: "Мегафон" },
{ startNum: "9991542", operator: "Мегафон" },
{ startNum: "9991543", operator: "Мегафон" },
{ startNum: "9991544", operator: "Мегафон" },
{ startNum: "9991545", operator: "Мегафон" },
{ startNum: "9991546", operator: "Мегафон" },
{ startNum: "9991547", operator: "Мегафон" },
{ startNum: "9991548", operator: "Yota" },
{ startNum: "9991549", operator: "Yota" },
{ startNum: "999155", operator: "Yota" },
{ startNum: "999156", operator: "Yota" },
{ startNum: "999157", operator: "Yota" },
{ startNum: "999158", operator: "Yota" },
{ startNum: "999162", operator: "Yota" },
{ startNum: "999163", operator: "Yota" },
{ startNum: "999164", operator: "Yota" },
{ startNum: "999165", operator: "Yota" },
{ startNum: "999166", operator: "Yota" },
{ startNum: "999167", operator: "Yota" },
{ startNum: "999168", operator: "Yota" },
{ startNum: "999169", operator: "Yota" },
{ startNum: "999170", operator: "Yota" },
{ startNum: "999171", operator: "Yota" },
{ startNum: "999172", operator: "Yota" },
{ startNum: "999173", operator: "Yota" },
{ startNum: "999174", operator: "Yota" },
{ startNum: "999175", operator: "Yota" },
{ startNum: "999176", operator: "Yota" },
{ startNum: "9991770", operator: "Yota" },
{ startNum: "9991771", operator: "Yota" },
{ startNum: "9991772", operator: "Yota" },
{ startNum: "9991773", operator: "Yota" },
{ startNum: "9991774", operator: "Yota" },
{ startNum: "9991775", operator: "Yota" },
{ startNum: "9991776", operator: "Yota" },
{ startNum: "9991777", operator: "Yota" },
{ startNum: "9991778", operator: "Yota" },
{ startNum: "9991779", operator: "Yota" },
{ startNum: "9991780", operator: "Yota" },
{ startNum: "9991781", operator: "Yota" },
{ startNum: "9991782", operator: "Yota" },
{ startNum: "9991783", operator: "Yota" },
{ startNum: "9991784", operator: "Yota" },
{ startNum: "9991785", operator: "Yota" },
{ startNum: "9991786", operator: "Yota" },
{ startNum: "9991787", operator: "Yota" },
{ startNum: "9991788", operator: "Yota" },
{ startNum: "9991789", operator: "Yota" },
{ startNum: "9991790", operator: "Yota" },
{ startNum: "9991791", operator: "Yota" },
{ startNum: "9991792", operator: "Yota" },
{ startNum: "9991793", operator: "Yota" },
{ startNum: "9991794", operator: "Yota" },
{ startNum: "9991795", operator: "Yota" },
{ startNum: "9991796", operator: "Yota" },
{ startNum: "9991797", operator: "Yota" },
{ startNum: "9991798", operator: "Yota" },
{ startNum: "9991799", operator: "Yota" },
{ startNum: "9991800", operator: "Yota" },
{ startNum: "9991801", operator: "Yota" },
{ startNum: "9991802", operator: "Yota" },
{ startNum: "9991803", operator: "Мегафон" },
{ startNum: "9991804", operator: "Мегафон" },
{ startNum: "9991805", operator: "Мегафон" },
{ startNum: "9991806", operator: "Мегафон" },
{ startNum: "9991807", operator: "Мегафон" },
{ startNum: "9991808", operator: "Мегафон" },
{ startNum: "9991809", operator: "Мегафон" },
{ startNum: "9991810", operator: "Мегафон" },
{ startNum: "9991811", operator: "Мегафон" },
{ startNum: "9991812", operator: "Мегафон" },
{ startNum: "9991813", operator: "Мегафон" },
{ startNum: "9991814", operator: "Мегафон" },
{ startNum: "9991815", operator: "Мегафон" },
{ startNum: "9991816", operator: "Мегафон" },
{ startNum: "9991817", operator: "Мегафон" },
{ startNum: "9991818", operator: "Мегафон" },
{ startNum: "9991819", operator: "Мегафон" },
{ startNum: "999182", operator: "Мегафон" },
{ startNum: "9991830", operator: "Мегафон" },
{ startNum: "9991831", operator: "Мегафон" },
{ startNum: "9991832", operator: "Мегафон" },
{ startNum: "9991833", operator: "Мегафон" },
{ startNum: "9991834", operator: "Мегафон" },
{ startNum: "9991835", operator: "Мегафон" },
{ startNum: "9991836", operator: "Мегафон" },
{ startNum: "9991837", operator: "Мегафон" },
{ startNum: "9991838", operator: "Yota" },
{ startNum: "9991839", operator: "Yota" },
{ startNum: "999184", operator: "Yota" },
{ startNum: "9991850", operator: "Yota" },
{ startNum: "9991851", operator: "Yota" },
{ startNum: "9991852", operator: "Yota" },
{ startNum: "9991853", operator: "Мегафон" },
{ startNum: "9991854", operator: "Мегафон" },
{ startNum: "9991855", operator: "Мегафон" },
{ startNum: "9991856", operator: "Мегафон" },
{ startNum: "9991857", operator: "Мегафон" },
{ startNum: "9991858", operator: "Мегафон" },
{ startNum: "9991859", operator: "Мегафон" },
{ startNum: "999186", operator: "Мегафон" },
{ startNum: "999187", operator: "Мегафон" },
{ startNum: "9991880", operator: "Мегафон" },
{ startNum: "9991881", operator: "Мегафон" },
{ startNum: "9991882", operator: "Мегафон" },
{ startNum: "9991883", operator: "Мегафон" },
{ startNum: "9991884", operator: "Мегафон" },
{ startNum: "9991885", operator: "Мегафон" },
{ startNum: "9991886", operator: "Мегафон" },
{ startNum: "9991887", operator: "Мегафон" },
{ startNum: "9991903", operator: "Мегафон" },
{ startNum: "9991904", operator: "Мегафон" },
{ startNum: "9991905", operator: "Мегафон" },
{ startNum: "9991906", operator: "Мегафон" },
{ startNum: "9991907", operator: "Мегафон" },
{ startNum: "9991908", operator: "Мегафон" },
{ startNum: "9991909", operator: "Мегафон" },
{ startNum: "999191", operator: "Мегафон" },
{ startNum: "999192", operator: "Мегафон" },
{ startNum: "9991930", operator: "Мегафон" },
{ startNum: "9991931", operator: "Мегафон" },
{ startNum: "9991932", operator: "Мегафон" },
{ startNum: "9991933", operator: "Мегафон" },
{ startNum: "9991934", operator: "Мегафон" },
{ startNum: "9991935", operator: "Мегафон" },
{ startNum: "9991936", operator: "Мегафон" },
{ startNum: "9991937", operator: "Мегафон" },
{ startNum: "9991953", operator: "Мегафон" },
{ startNum: "9991954", operator: "Мегафон" },
{ startNum: "9991955", operator: "Мегафон" },
{ startNum: "9991956", operator: "Мегафон" },
{ startNum: "9991957", operator: "Мегафон" },
{ startNum: "9991958", operator: "Мегафон" },
{ startNum: "9991959", operator: "Мегафон" },
{ startNum: "999196", operator: "Мегафон" },
{ startNum: "999197", operator: "Мегафон" },
{ startNum: "999198", operator: "Мегафон" },
{ startNum: "9991888", operator: "Yota" },
{ startNum: "9991889", operator: "Yota" },
{ startNum: "999189", operator: "Yota" },
{ startNum: "9991900", operator: "Yota" },
{ startNum: "9991901", operator: "Yota" },
{ startNum: "9991902", operator: "Yota" },
{ startNum: "9991938", operator: "Yota" },
{ startNum: "9991939", operator: "Yota" },
{ startNum: "999194", operator: "Yota" },
{ startNum: "9991950", operator: "Yota" },
{ startNum: "9991951", operator: "Yota" },
{ startNum: "9991952", operator: "Yota" },
{ startNum: "999199", operator: "Yota" },
{ startNum: "99920", operator: "Yota" },
{ startNum: "99921", operator: "Yota" },
{ startNum: "9992200", operator: "Tele2" },
{ startNum: "9992201", operator: "Tele2" },
{ startNum: "9992202", operator: "Yota" },
{ startNum: "9992203", operator: "Yota" },
{ startNum: "9992211", operator: "Yota" },
{ startNum: "9992233", operator: "Yota" },
{ startNum: "9992238", operator: "Yota" },
{ startNum: "9992243", operator: "Yota" },
{ startNum: "9992249", operator: "Yota" },
{ startNum: "9992250", operator: "Yota" },
{ startNum: "9992260", operator: "Yota" },
{ startNum: "9992277", operator: "Yota" },
{ startNum: "9992286", operator: "Yota" },
{ startNum: "9992291", operator: "Yota" },
{ startNum: "9992293", operator: "Yota" },
{ startNum: "9992300", operator: "Tele2" },
{ startNum: "9992301", operator: "Tele2" },
{ startNum: "9992302", operator: "Мегафон" },
{ startNum: "9992303", operator: "Мегафон" },
{ startNum: "9992304", operator: "Мегафон" },
{ startNum: "9992305", operator: "Мегафон" },
{ startNum: "9992306", operator: "Мегафон" },
{ startNum: "9992307", operator: "Мегафон" },
{ startNum: "9992308", operator: "Мегафон" },
{ startNum: "9992309", operator: "Мегафон" },
{ startNum: "9992310", operator: "Мегафон" },
{ startNum: "9992311", operator: "Мегафон" },
{ startNum: "9992312", operator: "Мегафон" },
{ startNum: "9992313", operator: "Yota" },
{ startNum: "9992314", operator: "Yota" },
{ startNum: "9992315", operator: "Yota" },
{ startNum: "9992316", operator: "Yota" },
{ startNum: "9992317", operator: "Yota" },
{ startNum: "9992318", operator: "Yota" },
{ startNum: "9992319", operator: "Yota" },
{ startNum: "999232", operator: "Yota" },
{ startNum: "999233", operator: "Yota" },
{ startNum: "9992340", operator: "Yota" },
{ startNum: "9992341", operator: "Yota" },
{ startNum: "9992342", operator: "Yota" },
{ startNum: "9992343", operator: "Yota" },
{ startNum: "9992344", operator: "Yota" },
{ startNum: "9992345", operator: "Yota" },
{ startNum: "9992346", operator: "Yota" },
{ startNum: "9992347", operator: "Yota" },
{ startNum: "9992348", operator: "Yota" },
{ startNum: "9992349", operator: "Yota" },
{ startNum: "999235", operator: "Yota" },
{ startNum: "999236", operator: "Yota" },
{ startNum: "999237", operator: "Yota" },
{ startNum: "999238", operator: "Yota" },
{ startNum: "999239", operator: "Yota" },
{ startNum: "9992400", operator: "Tele2" },
{ startNum: "9992401", operator: "Tele2" },
{ startNum: "9992402", operator: "Yota" },
{ startNum: "9992403", operator: "Yota" },
{ startNum: "9992404", operator: "Yota" },
{ startNum: "9992405", operator: "Yota" },
{ startNum: "9992406", operator: "Yota" },
{ startNum: "9992407", operator: "Yota" },
{ startNum: "9992408", operator: "Yota" },
{ startNum: "9992409", operator: "Yota" },
{ startNum: "9992410", operator: "Yota" },
{ startNum: "9992411", operator: "Yota" },
{ startNum: "9992412", operator: "Yota" },
{ startNum: "9992413", operator: "Yota" },
{ startNum: "9992414", operator: "Yota" },
{ startNum: "9992415", operator: "Yota" },
{ startNum: "9992416", operator: "Yota" },
{ startNum: "9992417", operator: "Yota" },
{ startNum: "9992427", operator: "Yota" },
{ startNum: "9992442", operator: "Yota" },
{ startNum: "9992445", operator: "Yota" },
{ startNum: "9992451", operator: "Yota" },
{ startNum: "9992455", operator: "Yota" },
{ startNum: "999246", operator: "Yota" },
{ startNum: "999247", operator: "Yota" },
{ startNum: "999248", operator: "Yota" },
{ startNum: "999249", operator: "Yota" },
{ startNum: "999250", operator: "Yota" },
{ startNum: "9992540", operator: "Мегафон" },
{ startNum: "9992541", operator: "Мегафон" },
{ startNum: "9992542", operator: "Мегафон" },
{ startNum: "9992543", operator: "Мегафон" },
{ startNum: "9992544", operator: "Мегафон" },
{ startNum: "9992545", operator: "Мегафон" },
{ startNum: "9992546", operator: "Мегафон" },
{ startNum: "9992547", operator: "Мегафон" },
{ startNum: "9992548", operator: "Мегафон" },
{ startNum: "9992549", operator: "Мегафон" },
{ startNum: "999256", operator: "Мегафон" },
{ startNum: "9992586", operator: "Мегафон" },
{ startNum: "9992587", operator: "Мегафон" },
{ startNum: "9992588", operator: "Мегафон" },
{ startNum: "9992589", operator: "Мегафон" },
{ startNum: "9992590", operator: "Мегафон" },
{ startNum: "9992591", operator: "Мегафон" },
{ startNum: "9992592", operator: "Мегафон" },
{ startNum: "9992593", operator: "Мегафон" },
{ startNum: "9992594", operator: "Мегафон" },
{ startNum: "9992595", operator: "Мегафон" },
{ startNum: "9992596", operator: "Мегафон" },
{ startNum: "9992597", operator: "Мегафон" },
{ startNum: "9992598", operator: "Мегафон" },
{ startNum: "9992599", operator: "Мегафон" },
{ startNum: "999255", operator: "Yota" },
{ startNum: "999260", operator: "Yota" },
{ startNum: "9992610", operator: "Yota" },
{ startNum: "9992611", operator: "Yota" },
{ startNum: "9992612", operator: "Yota" },
{ startNum: "9992613", operator: "Yota" },
{ startNum: "9992614", operator: "Yota" },
{ startNum: "9992700", operator: "Yota" },
{ startNum: "9992701", operator: "Yota" },
{ startNum: "9992702", operator: "Yota" },
{ startNum: "9992615", operator: "Мегафон" },
{ startNum: "9992616", operator: "Мегафон" },
{ startNum: "9992617", operator: "Мегафон" },
{ startNum: "9992618", operator: "Мегафон" },
{ startNum: "9992619", operator: "Мегафон" },
{ startNum: "9992620", operator: "Мегафон" },
{ startNum: "9992621", operator: "Мегафон" },
{ startNum: "9992622", operator: "Мегафон" },
{ startNum: "9992623", operator: "Мегафон" },
{ startNum: "9992624", operator: "Мегафон" },
{ startNum: "9992625", operator: "Мегафон" },
{ startNum: "999264", operator: "Мегафон" },
{ startNum: "999265", operator: "Мегафон" },
{ startNum: "999266", operator: "Мегафон" },
{ startNum: "999267", operator: "Мегафон" },
{ startNum: "999268", operator: "Мегафон" },
{ startNum: "999269", operator: "Мегафон" },
{ startNum: "9992703", operator: "Мегафон" },
{ startNum: "9992704", operator: "Мегафон" },
{ startNum: "9992705", operator: "Мегафон" },
{ startNum: "9992706", operator: "Мегафон" },
{ startNum: "9992707", operator: "Мегафон" },
{ startNum: "9992708", operator: "Мегафон" },
{ startNum: "9992709", operator: "Мегафон" },
{ startNum: "9992710", operator: "Мегафон" },
{ startNum: "9992711", operator: "Мегафон" },
{ startNum: "9992712", operator: "Мегафон" },
{ startNum: "9992713", operator: "Мегафон" },
{ startNum: "9992714", operator: "Мегафон" },
{ startNum: "9992715", operator: "Мегафон" },
{ startNum: "9992716", operator: "Мегафон" },
{ startNum: "9992717", operator: "Мегафон" },
{ startNum: "9992718", operator: "Мегафон" },
{ startNum: "9992719", operator: "Мегафон" },
{ startNum: "9992720", operator: "Мегафон" },
{ startNum: "9992721", operator: "Мегафон" },
{ startNum: "9992722", operator: "Мегафон" },
{ startNum: "9992723", operator: "Мегафон" },
{ startNum: "9992724", operator: "Мегафон" },
{ startNum: "9992725", operator: "Мегафон" },
{ startNum: "9992726", operator: "Мегафон" },
{ startNum: "9992727", operator: "Мегафон" },
{ startNum: "9992728", operator: "Мегафон" },
{ startNum: "9992729", operator: "Мегафон" },
{ startNum: "999273", operator: "Мегафон" },
{ startNum: "9992730", operator: "Мегафон" },
{ startNum: "9992731", operator: "Мегафон" },
{ startNum: "9992732", operator: "Мегафон" },
{ startNum: "9992733", operator: "Мегафон" },
{ startNum: "9992734", operator: "Мегафон" },
{ startNum: "9992735", operator: "Мегафон" },
{ startNum: "9992736", operator: "Мегафон" },
{ startNum: "9992737", operator: "Мегафон" },
{ startNum: "9992738", operator: "Мегафон" },
{ startNum: "9992739", operator: "Мегафон" },
{ startNum: "9992740", operator: "Мегафон" },
{ startNum: "9992741", operator: "Мегафон" },
{ startNum: "9992742", operator: "Мегафон" },
{ startNum: "9992743", operator: "Мегафон" },
{ startNum: "9992744", operator: "Мегафон" },
{ startNum: "9992745", operator: "Мегафон" },
{ startNum: "9992746", operator: "Мегафон" },
{ startNum: "9992747", operator: "Мегафон" },
{ startNum: "9992748", operator: "Yota" },
{ startNum: "9992749", operator: "Yota" },
{ startNum: "9992750", operator: "Yota" },
{ startNum: "9992751", operator: "Yota" },
{ startNum: "9992752", operator: "Yota" },
{ startNum: "9992753", operator: "Мегафон" },
{ startNum: "9992754", operator: "Мегафон" },
{ startNum: "9992755", operator: "Мегафон" },
{ startNum: "9992756", operator: "Мегафон" },
{ startNum: "9992757", operator: "Мегафон" },
{ startNum: "9992758", operator: "Мегафон" },
{ startNum: "9992759", operator: "Мегафон" },
{ startNum: "9992760", operator: "Мегафон" },
{ startNum: "9992761", operator: "Мегафон" },
{ startNum: "9992762", operator: "Мегафон" },
{ startNum: "9992763", operator: "Мегафон" },
{ startNum: "9992764", operator: "Мегафон" },
{ startNum: "9992765", operator: "Мегафон" },
{ startNum: "9992766", operator: "Мегафон" },
{ startNum: "9992767", operator: "Мегафон" },
{ startNum: "9992768", operator: "Мегафон" },
{ startNum: "9992769", operator: "Мегафон" },
{ startNum: "999277", operator: "Мегафон" },
{ startNum: "999278", operator: "Мегафон" },
{ startNum: "999279", operator: "Мегафон" },
{ startNum: "9992800", operator: "Yota" },
{ startNum: "9992801", operator: "Yota" },
{ startNum: "9992802", operator: "Yota" },
{ startNum: "9992803", operator: "Мегафон" },
{ startNum: "9992804", operator: "Мегафон" },
{ startNum: "9992805", operator: "Мегафон" },
{ startNum: "9992806", operator: "Мегафон" },
{ startNum: "9992807", operator: "Мегафон" },
{ startNum: "9992808", operator: "Мегафон" },
{ startNum: "9992809", operator: "Мегафон" },
{ startNum: "9992810", operator: "Мегафон" },
{ startNum: "9992811", operator: "Мегафон" },
{ startNum: "9992812", operator: "Мегафон" },
{ startNum: "9992813", operator: "Мегафон" },
{ startNum: "9992814", operator: "Мегафон" },
{ startNum: "9992815", operator: "Мегафон" },
{ startNum: "9992816", operator: "Мегафон" },
{ startNum: "9992817", operator: "Мегафон" },
{ startNum: "9992818", operator: "Мегафон" },
{ startNum: "9992819", operator: "Мегафон" },
{ startNum: "999282", operator: "Мегафон" },
{ startNum: "999283", operator: "Мегафон" },
{ startNum: "9992840", operator: "Мегафон" },
{ startNum: "9992841", operator: "Мегафон" },
{ startNum: "9992842", operator: "Мегафон" },
{ startNum: "9992843", operator: "Мегафон" },
{ startNum: "9992844", operator: "Мегафон" },
{ startNum: "9992845", operator: "Мегафон" },
{ startNum: "9992846", operator: "Мегафон" },
{ startNum: "9992847", operator: "Мегафон" },
{ startNum: "9992848", operator: "Yota" },
{ startNum: "9992849", operator: "Yota" },
{ startNum: "9992850", operator: "Yota" },
{ startNum: "9992851", operator: "Yota" },
{ startNum: "9992852", operator: "Yota" },
{ startNum: "9992853", operator: "Мегафон" },
{ startNum: "9992854", operator: "Мегафон" },
{ startNum: "9992855", operator: "Мегафон" },
{ startNum: "9992856", operator: "Мегафон" },
{ startNum: "9992857", operator: "Мегафон" },
{ startNum: "9992858", operator: "Мегафон" },
{ startNum: "9992859", operator: "Мегафон" },
{ startNum: "999286", operator: "Мегафон" },
{ startNum: "999287", operator: "Мегафон" },
{ startNum: "999288", operator: "Мегафон" },
{ startNum: "999289", operator: "Мегафон" },
{ startNum: "9992900", operator: "Yota" },
{ startNum: "9992901", operator: "Yota" },
{ startNum: "9992902", operator: "Yota" },
{ startNum: "9992903", operator: "Мегафон" },
{ startNum: "9992904", operator: "Мегафон" },
{ startNum: "9992905", operator: "Мегафон" },
{ startNum: "9992906", operator: "Мегафон" },
{ startNum: "9992907", operator: "Мегафон" },
{ startNum: "9992908", operator: "Мегафон" },
{ startNum: "9992909", operator: "Мегафон" },
{ startNum: "999291", operator: "Мегафон" },
{ startNum: "999292", operator: "Мегафон" },
{ startNum: "9992930", operator: "Мегафон" },
{ startNum: "9992931", operator: "Мегафон" },
{ startNum: "9992932", operator: "Мегафон" },
{ startNum: "9992933", operator: "Мегафон" },
{ startNum: "9992934", operator: "Мегафон" },
{ startNum: "9992935", operator: "Мегафон" },
{ startNum: "9992936", operator: "Мегафон" },
{ startNum: "9992937", operator: "Мегафон" },
{ startNum: "9992938", operator: "Мегафон" },
{ startNum: "9992939", operator: "Мегафон" },
{ startNum: "9992940", operator: "Мегафон" },
{ startNum: "9992941", operator: "Мегафон" },
{ startNum: "9992942", operator: "Мегафон" },
{ startNum: "9992943", operator: "Мегафон" },
{ startNum: "9992944", operator: "Мегафон" },
{ startNum: "9992945", operator: "Мегафон" },
{ startNum: "9992946", operator: "Мегафон" },
{ startNum: "9992947", operator: "Мегафон" },
{ startNum: "9992948", operator: "Yota" },
{ startNum: "9992949", operator: "Yota" },
{ startNum: "9992950", operator: "Yota" },
{ startNum: "9992951", operator: "Yota" },
{ startNum: "9992952", operator: "Yota" },
{ startNum: "9992953", operator: "Мегафон" },
{ startNum: "9992954", operator: "Мегафон" },
{ startNum: "9992955", operator: "Мегафон" },
{ startNum: "9992956", operator: "Мегафон" },
{ startNum: "9992957", operator: "Мегафон" },
{ startNum: "9992958", operator: "Мегафон" },
{ startNum: "9992959", operator: "Мегафон" },
{ startNum: "999296", operator: "Мегафон" },
{ startNum: "999297", operator: "Мегафон" },
{ startNum: "999298", operator: "Мегафон" },
{ startNum: "9992990", operator: "Мегафон" },
{ startNum: "9992991", operator: "Мегафон" },
{ startNum: "9992992", operator: "Мегафон" },
{ startNum: "9992993", operator: "Мегафон" },
{ startNum: "9992994", operator: "Мегафон" },
{ startNum: "9992995", operator: "Мегафон" },
{ startNum: "9992996", operator: "Мегафон" },
{ startNum: "9992997", operator: "Мегафон" },
{ startNum: "9992998", operator: "Yota" },
{ startNum: "9992999", operator: "Yota" },
{ startNum: "9993000", operator: "Yota" },
{ startNum: "9993001", operator: "Yota" },
{ startNum: "9993002", operator: "Yota" },
{ startNum: "9993003", operator: "Мегафон" },
{ startNum: "9993004", operator: "Мегафон" },
{ startNum: "9993005", operator: "Мегафон" },
{ startNum: "9993006", operator: "Мегафон" },
{ startNum: "9993007", operator: "Мегафон" },
{ startNum: "9993008", operator: "Мегафон" },
{ startNum: "9993009", operator: "Мегафон" },
{ startNum: "999301", operator: "Мегафон" },
{ startNum: "999302", operator: "Мегафон" },
{ startNum: "999303", operator: "Мегафон" },
{ startNum: "999304", operator: "Мегафон" },
{ startNum: "999305", operator: "Мегафон" },
{ startNum: "999306", operator: "Мегафон" },
{ startNum: "9993070", operator: "Мегафон" },
{ startNum: "9993071", operator: "Мегафон" },
{ startNum: "9993072", operator: "Мегафон" },
{ startNum: "9993073", operator: "Мегафон" },
{ startNum: "9993074", operator: "Мегафон" },
{ startNum: "9993075", operator: "Мегафон" },
{ startNum: "9993076", operator: "Мегафон" },
{ startNum: "9993077", operator: "Мегафон" },
{ startNum: "9993078", operator: "Мегафон" },
{ startNum: "9993079", operator: "Мегафон" },
{ startNum: "9993080", operator: "Мегафон" },
{ startNum: "9993081", operator: "Мегафон" },
{ startNum: "9993082", operator: "Мегафон" },
{ startNum: "9993083", operator: "Мегафон" },
{ startNum: "9993084", operator: "Мегафон" },
{ startNum: "9993085", operator: "Мегафон" },
{ startNum: "9993086", operator: "Мегафон" },
{ startNum: "9993087", operator: "Мегафон" },
{ startNum: "9993088", operator: "Мегафон" },
{ startNum: "9993089", operator: "Мегафон" },
{ startNum: "9993090", operator: "Мегафон" },
{ startNum: "9993091", operator: "Мегафон" },
{ startNum: "9993092", operator: "Мегафон" },
{ startNum: "9993093", operator: "Мегафон" },
{ startNum: "9993094", operator: "Мегафон" },
{ startNum: "9993095", operator: "Мегафон" },
{ startNum: "9993096", operator: "Мегафон" },
{ startNum: "9993097", operator: "Мегафон" },
{ startNum: "9993098", operator: "Yota" },
{ startNum: "9993099", operator: "Yota" },
{ startNum: "999310", operator: "Yota" },
{ startNum: "999311", operator: "Yota" },
{ startNum: "999312", operator: "Мегафон" },
{ startNum: "999313", operator: "Мегафон" },
{ startNum: "999314", operator: "Мегафон" },
{ startNum: "9993150", operator: "Мегафон" },
{ startNum: "9993151", operator: "Мегафон" },
{ startNum: "9993152", operator: "Мегафон" },
{ startNum: "9993153", operator: "Мегафон" },
{ startNum: "9993154", operator: "Мегафон" },
{ startNum: "9993155", operator: "Мегафон" },
{ startNum: "9993156", operator: "Мегафон" },
{ startNum: "9993157", operator: "Мегафон" },
{ startNum: "9993158", operator: "Мегафон" },
{ startNum: "9993159", operator: "Мегафон" },
{ startNum: "999316", operator: "Мегафон" },
{ startNum: "999317", operator: "Мегафон" },
{ startNum: "999318", operator: "Мегафон" },
{ startNum: "999319", operator: "Мегафон" },
{ startNum: "999320", operator: "Мегафон" },
{ startNum: "999321", operator: "Мегафон" },
{ startNum: "9993220", operator: "Мегафон" },
{ startNum: "9993221", operator: "Мегафон" },
{ startNum: "9993222", operator: "Мегафон" },
{ startNum: "9993223", operator: "Мегафон" },
{ startNum: "9993224", operator: "Мегафон" },
{ startNum: "9993225", operator: "Мегафон" },
{ startNum: "9993226", operator: "Мегафон" },
{ startNum: "9993227", operator: "Мегафон" },
{ startNum: "9993228", operator: "Мегафон" },
{ startNum: "9993229", operator: "Мегафон" },
{ startNum: "999323", operator: "Мегафон" },
{ startNum: "999324", operator: "Мегафон" },
{ startNum: "9993250", operator: "Мегафон" },
{ startNum: "9993251", operator: "Мегафон" },
{ startNum: "9993252", operator: "Мегафон" },
{ startNum: "9993253", operator: "Мегафон" },
{ startNum: "9993254", operator: "Мегафон" },
{ startNum: "9993255", operator: "Мегафон" },
{ startNum: "9993256", operator: "Мегафон" },
{ startNum: "9993257", operator: "Мегафон" },
{ startNum: "9993258", operator: "Мегафон" },
{ startNum: "9993259", operator: "Мегафон" },
{ startNum: "999326", operator: "Мегафон" },
{ startNum: "999327", operator: "Мегафон" },
{ startNum: "999328", operator: "Мегафон" },
{ startNum: "999329", operator: "Мегафон" },
{ startNum: "9993300", operator: "Yota" },
{ startNum: "9993301", operator: "Yota" },
{ startNum: "9993302", operator: "Yota" },
{ startNum: "9993303", operator: "Мегафон" },
{ startNum: "9993303", operator: "Мегафон" },
{ startNum: "9993304", operator: "Мегафон" },
{ startNum: "9993305", operator: "Мегафон" },
{ startNum: "9993309", operator: "Мегафон" },
{ startNum: "9993310", operator: "Мегафон" },
{ startNum: "9993324", operator: "Мегафон" },
{ startNum: "9993340", operator: "Мегафон" },
{ startNum: "9993347", operator: "Мегафон" },
{ startNum: "9993348", operator: "Мегафон" },
{ startNum: "9993357", operator: "Мегафон" },
{ startNum: "9993358", operator: "Мегафон" },
{ startNum: "9993397", operator: "Мегафон" },
{ startNum: "9993325", operator: "Мегафон" },
{ startNum: "9993326", operator: "Мегафон" },
{ startNum: "9993327", operator: "Мегафон" },
{ startNum: "9993328", operator: "Мегафон" },
{ startNum: "9993329", operator: "Мегафон" },
{ startNum: "999333", operator: "Интернод" },
{ startNum: "9993340", operator: "Мегафон" },
{ startNum: "9993341", operator: "Мегафон" },
{ startNum: "9993342", operator: "Мегафон" },
{ startNum: "9993343", operator: "Мегафон" },
{ startNum: "9993344", operator: "Мегафон" },
{ startNum: "9993345", operator: "Мегафон" },
{ startNum: "9993346", operator: "Мегафон" },
{ startNum: "9993347", operator: "Мегафон" },
{ startNum: "9993348", operator: "Мегафон" },
{ startNum: "9993349", operator: "Мегафон" },
{ startNum: "9993350", operator: "Мегафон" },
{ startNum: "9993351", operator: "Мегафон" },
{ startNum: "9993352", operator: "Мегафон" },
{ startNum: "9993353", operator: "Мегафон" },
{ startNum: "9993354", operator: "Мегафон" },
{ startNum: "9993355", operator: "Мегафон" },
{ startNum: "9993356", operator: "Мегафон" },
{ startNum: "9993357", operator: "Мегафон" },
{ startNum: "9993358", operator: "Мегафон" },
{ startNum: "9993359", operator: "Мегафон" },
{ startNum: "999336", operator: "Мегафон" },
{ startNum: "999337", operator: "Мегафон" },
{ startNum: "999338", operator: "Мегафон" },
{ startNum: "9993390", operator: "Мегафон" },
{ startNum: "9993391", operator: "Мегафон" },
{ startNum: "9993392", operator: "Мегафон" },
{ startNum: "9993393", operator: "Мегафон" },
{ startNum: "9993394", operator: "Мегафон" },
{ startNum: "9993395", operator: "Мегафон" },
{ startNum: "9993396", operator: "Мегафон" },
{ startNum: "9993397", operator: "Мегафон" },
{ startNum: "9993398", operator: "Yota" },
{ startNum: "9993399", operator: "Yota" },
{ startNum: "9993400", operator: "Yota" },
{ startNum: "9993401", operator: "Yota" },
{ startNum: "9993402", operator: "Yota" },
{ startNum: "9993403", operator: "Мегафон" },
{ startNum: "9993404", operator: "Мегафон" },
{ startNum: "9993405", operator: "Мегафон" },
{ startNum: "9993406", operator: "Мегафон" },
{ startNum: "9993407", operator: "Мегафон" },
{ startNum: "9993408", operator: "Мегафон" },
{ startNum: "9993409", operator: "Мегафон" },
{ startNum: "999341", operator: "Мегафон" },
{ startNum: "9993420", operator: "Мегафон" },
{ startNum: "9993421", operator: "Мегафон" },
{ startNum: "9993422", operator: "Мегафон" },
{ startNum: "9993423", operator: "Мегафон" },
{ startNum: "9993424", operator: "Мегафон" },
{ startNum: "9993425", operator: "Мегафон" },
{ startNum: "9993426", operator: "Мегафон" },
{ startNum: "9993427", operator: "Мегафон" },
{ startNum: "9993428", operator: "Мегафон" },
{ startNum: "9993429", operator: "Мегафон" },
{ startNum: "999343", operator: "Мегафон" },
{ startNum: "999344", operator: "Мегафон" },
{ startNum: "999345", operator: "Мегафон" },
{ startNum: "999346", operator: "Мегафон" },
{ startNum: "999347", operator: "Мегафон" },
{ startNum: "999348", operator: "Мегафон" },
{ startNum: "999349", operator: "Мегафон" },
{ startNum: "999353", operator: "Мегафон" },
{ startNum: "999354", operator: "Мегафон" },
{ startNum: "999355", operator: "Мегафон" },
{ startNum: "999356", operator: "Мегафон" },
{ startNum: "999357", operator: "Мегафон" },
{ startNum: "9993500", operator: "Yota" },
{ startNum: "9993502", operator: "Yota" },
{ startNum: "9993598", operator: "Yota" },
{ startNum: "9993600", operator: "Yota" },
{ startNum: "9993602", operator: "Yota" },
{ startNum: "9993603", operator: "Мегафон" },
{ startNum: "9993604", operator: "Мегафон" },
{ startNum: "9993605", operator: "Мегафон" },
{ startNum: "9993609", operator: "Мегафон" },
{ startNum: "999361", operator: "Мегафон" },
{ startNum: "999362", operator: "Мегафон" },
{ startNum: "9993630", operator: "Мегафон" },
{ startNum: "9993631", operator: "Мегафон" },
{ startNum: "9993632", operator: "Мегафон" },
{ startNum: "9993633", operator: "Мегафон" },
{ startNum: "9993634", operator: "Мегафон" },
{ startNum: "9993635", operator: "Мегафон" },
{ startNum: "9993636", operator: "Мегафон" },
{ startNum: "9993637", operator: "Мегафон" },
{ startNum: "9993638", operator: "Мегафон" },
{ startNum: "9993639", operator: "Мегафон" },
{ startNum: "999364", operator: "Мегафон" },
{ startNum: "999365", operator: "Мегафон" },
{ startNum: "999366", operator: "Мегафон" },
{ startNum: "999367", operator: "Мегафон" },
{ startNum: "999368", operator: "Мегафон" },
{ startNum: "999369", operator: "Мегафон" },
{ startNum: "999370", operator: "Мегафон" },
{ startNum: "999371", operator: "Мегафон" },
{ startNum: "999372", operator: "Мегафон" },
{ startNum: "999373", operator: "Мегафон" },
{ startNum: "999374", operator: "Мегафон" },
{ startNum: "999375", operator: "Мегафон" },
{ startNum: "9993760", operator: "Мегафон" },
{ startNum: "9993761", operator: "Мегафон" },
{ startNum: "9993762", operator: "Мегафон" },
{ startNum: "9993763", operator: "Мегафон" },
{ startNum: "9993764", operator: "Мегафон" },
{ startNum: "9993765", operator: "Мегафон" },
{ startNum: "9993766", operator: "Мегафон" },
{ startNum: "9993767", operator: "Мегафон" },
{ startNum: "9993768", operator: "Мегафон" },
{ startNum: "9993769", operator: "Мегафон" },
{ startNum: "9993770", operator: "Мегафон" },
{ startNum: "9993771", operator: "Мегафон" },
{ startNum: "9993772", operator: "Мегафон" },
{ startNum: "9993773", operator: "Мегафон" },
{ startNum: "9993774", operator: "Мегафон" },
{ startNum: "9993775", operator: "Мегафон" },
{ startNum: "9993776", operator: "Мегафон" },
{ startNum: "9993777", operator: "Мегафон" },
{ startNum: "9993778", operator: "Мегафон" },
{ startNum: "9993779", operator: "Мегафон" },
{ startNum: "9993780", operator: "Мегафон" },
{ startNum: "9993781", operator: "Мегафон" },
{ startNum: "9993782", operator: "Мегафон" },
{ startNum: "9993783", operator: "Мегафон" },
{ startNum: "9993784", operator: "Мегафон" },
{ startNum: "9993785", operator: "Yota" },
{ startNum: "9993786", operator: "Yota" },
{ startNum: "9993787", operator: "Yota" },
{ startNum: "9993788", operator: "Yota" },
{ startNum: "9993789", operator: "Yota" },
{ startNum: "999379", operator: "Yota" },
{ startNum: "9993800", operator: "Yota" },
{ startNum: "9993801", operator: "Yota" },
{ startNum: "9993802", operator: "Yota" },
{ startNum: "9993803", operator: "Мегафон" },
{ startNum: "9993804", operator: "Мегафон" },
{ startNum: "9993805", operator: "Мегафон" },
{ startNum: "9993806", operator: "Мегафон" },
{ startNum: "9993807", operator: "Мегафон" },
{ startNum: "9993808", operator: "Мегафон" },
{ startNum: "9993809", operator: "Мегафон" },
{ startNum: "9993810", operator: "Мегафон" },
{ startNum: "9993811", operator: "Мегафон" },
{ startNum: "9993812", operator: "Мегафон" },
{ startNum: "9993813", operator: "Мегафон" },
{ startNum: "9993814", operator: "Мегафон" },
{ startNum: "9993815", operator: "Мегафон" },
{ startNum: "9993816", operator: "Мегафон" },
{ startNum: "9993817", operator: "Мегафон" },
{ startNum: "9993818", operator: "Мегафон" },
{ startNum: "9993819", operator: "Мегафон" },
{ startNum: "9993820", operator: "Мегафон" },
{ startNum: "9993821", operator: "Мегафон" },
{ startNum: "9993822", operator: "Мегафон" },
{ startNum: "9993823", operator: "Мегафон" },
{ startNum: "9993824", operator: "Мегафон" },
{ startNum: "9993825", operator: "Мегафон" },
{ startNum: "9993826", operator: "Мегафон" },
{ startNum: "9993827", operator: "Мегафон" },
{ startNum: "9993828", operator: "Мегафон" },
{ startNum: "9993829", operator: "Мегафон" },
{ startNum: "9993830", operator: "Мегафон" },
{ startNum: "9993831", operator: "Мегафон" },
{ startNum: "9993832", operator: "Мегафон" },
{ startNum: "9993833", operator: "Мегафон" },
{ startNum: "9993834", operator: "Мегафон" },
{ startNum: "9993835", operator: "Мегафон" },
{ startNum: "9993836", operator: "Мегафон" },
{ startNum: "9993837", operator: "Мегафон" },
{ startNum: "9993838", operator: "Мегафон" },
{ startNum: "9993839", operator: "Мегафон" },
{ startNum: "9993840", operator: "Мегафон" },
{ startNum: "9993841", operator: "Мегафон" },
{ startNum: "9993842", operator: "Мегафон" },
{ startNum: "9993843", operator: "Мегафон" },
{ startNum: "9993844", operator: "Мегафон" },
{ startNum: "9993845", operator: "Мегафон" },
{ startNum: "9993846", operator: "Мегафон" },
{ startNum: "9993847", operator: "Мегафон" },
{ startNum: "9993848", operator: "Мегафон" },
{ startNum: "9993849", operator: "Мегафон" },
{ startNum: "9993850", operator: "Мегафон" },
{ startNum: "9993851", operator: "Мегафон" },
{ startNum: "9993852", operator: "Мегафон" },
{ startNum: "9993853", operator: "Мегафон" },
{ startNum: "9993854", operator: "Мегафон" },
{ startNum: "9993855", operator: "Мегафон" },
{ startNum: "9993856", operator: "Мегафон" },
{ startNum: "9993857", operator: "Мегафон" },
{ startNum: "9993858", operator: "Мегафон" },
{ startNum: "9993859", operator: "Мегафон" },
{ startNum: "9993860", operator: "Мегафон" },
{ startNum: "9993861", operator: "Мегафон" },
{ startNum: "9993862", operator: "Мегафон" },
{ startNum: "9993863", operator: "Мегафон" },
{ startNum: "9993864", operator: "Мегафон" },
{ startNum: "9993865", operator: "Мегафон" },
{ startNum: "9993866", operator: "Мегафон" },
{ startNum: "9993867", operator: "Мегафон" },
{ startNum: "9993868", operator: "Мегафон" },
{ startNum: "9993869", operator: "Мегафон" },
{ startNum: "9993870", operator: "Мегафон" },
{ startNum: "9993871", operator: "Мегафон" },
{ startNum: "9993872", operator: "Мегафон" },
{ startNum: "9993873", operator: "Мегафон" },
{ startNum: "9993874", operator: "Мегафон" },
{ startNum: "9993875", operator: "Мегафон" },
{ startNum: "9993876", operator: "Мегафон" },
{ startNum: "9993877", operator: "Мегафон" },
{ startNum: "9993878", operator: "Мегафон" },
{ startNum: "9993879", operator: "Мегафон" },
{ startNum: "9993880", operator: "Мегафон" },
{ startNum: "9993881", operator: "Мегафон" },
{ startNum: "9993882", operator: "Мегафон" },
{ startNum: "9993883", operator: "Мегафон" },
{ startNum: "9993884", operator: "Мегафон" },
{ startNum: "9993885", operator: "Мегафон" },
{ startNum: "9993886", operator: "Мегафон" },
{ startNum: "9993887", operator: "Мегафон" },
{ startNum: "9993888", operator: "Мегафон" },
{ startNum: "9993889", operator: "Мегафон" },
{ startNum: "9993890", operator: "Мегафон" },
{ startNum: "9993891", operator: "Мегафон" },
{ startNum: "9993892", operator: "Мегафон" },
{ startNum: "9993893", operator: "Мегафон" },
{ startNum: "9993894", operator: "Мегафон" },
{ startNum: "9993895", operator: "Мегафон" },
{ startNum: "9993896", operator: "Мегафон" },
{ startNum: "9993897", operator: "Мегафон" },
{ startNum: "9993898", operator: "Мегафон" },
{ startNum: "9993899", operator: "Мегафон" },
{ startNum: "9993900", operator: "Мегафон" },
{ startNum: "9993901", operator: "Мегафон" },
{ startNum: "9993902", operator: "Мегафон" },
{ startNum: "9993903", operator: "Мегафон" },
{ startNum: "9993904", operator: "Мегафон" },
{ startNum: "9993905", operator: "Мегафон" },
{ startNum: "9993906", operator: "Мегафон" },
{ startNum: "9993907", operator: "Мегафон" },
{ startNum: "9993908", operator: "Мегафон" },
{ startNum: "9993909", operator: "Мегафон" },
{ startNum: "999391", operator: "Мегафон" },
{ startNum: "999392", operator: "Мегафон" },
{ startNum: "999393", operator: "Мегафон" },
{ startNum: "999394", operator: "Мегафон" },
{ startNum: "999395", operator: "Мегафон" },
{ startNum: "9993950", operator: "Мегафон" },
{ startNum: "9993951", operator: "Мегафон" },
{ startNum: "9993952", operator: "Мегафон" },
{ startNum: "9993953", operator: "Мегафон" },
{ startNum: "9993954", operator: "Мегафон" },
{ startNum: "9993955", operator: "Мегафон" },
{ startNum: "9993956", operator: "Мегафон" },
{ startNum: "9993957", operator: "Мегафон" },
{ startNum: "9993958", operator: "Мегафон" },
{ startNum: "9993959", operator: "Мегафон" },
{ startNum: "999396", operator: "Мегафон" },
{ startNum: "999397", operator: "Мегафон" },
{ startNum: "999398", operator: "Yota" },
{ startNum: "999399", operator: "Yota" },
{ startNum: "9994000", operator: "Yota" },
{ startNum: "9994001", operator: "Yota" },
{ startNum: "9994002", operator: "Yota" },
{ startNum: "9994003", operator: "Мегафон" },
{ startNum: "9994004", operator: "Мегафон" },
{ startNum: "9994005", operator: "Мегафон" },
{ startNum: "9994006", operator: "Мегафон" },
{ startNum: "9994007", operator: "Мегафон" },
{ startNum: "9994008", operator: "Мегафон" },
{ startNum: "9994009", operator: "Мегафон" },
{ startNum: "999401", operator: "Мегафон" },
{ startNum: "999402", operator: "Мегафон" },
{ startNum: "999403", operator: "Мегафон" },
{ startNum: "999404", operator: "Мегафон" },
{ startNum: "999405", operator: "Мегафон" },
{ startNum: "999406", operator: "Мегафон" },
{ startNum: "999407", operator: "Мегафон" },
{ startNum: "999408", operator: "Мегафон" },
{ startNum: "9994085", operator: "Мегафон" },
{ startNum: "9994086", operator: "Мегафон" },
{ startNum: "9994087", operator: "Мегафон" },
{ startNum: "9994088", operator: "Мегафон" },
{ startNum: "9994089", operator: "Мегафон" },
{ startNum: "999409", operator: "Мегафон" },
{ startNum: "999410", operator: "Yota" },
{ startNum: "999411", operator: "Мегафон" },
{ startNum: "999412", operator: "Мегафон" },
{ startNum: "999413", operator: "Мегафон" },
{ startNum: "999414", operator: "Мегафон" },
{ startNum: "999415", operator: "Мегафон" },
{ startNum: "999416", operator: "Мегафон" },
{ startNum: "999417", operator: "Мегафон" },
{ startNum: "999418", operator: "Мегафон" },
{ startNum: "9994185", operator: "Мегафон" },
{ startNum: "9994186", operator: "Мегафон" },
{ startNum: "9994187", operator: "Мегафон" },
{ startNum: "9994188", operator: "Мегафон" },
{ startNum: "9994189", operator: "Мегафон" },
{ startNum: "999419", operator: "Мегафон" },
{ startNum: "999420", operator: "Yota" },
{ startNum: "999421", operator: "Мегафон" },
{ startNum: "999422", operator: "Yota" },
{ startNum: "9994230", operator: "Yota" },
{ startNum: "9994231", operator: "Yota" },
{ startNum: "9994232", operator: "Yota" },
{ startNum: "9994233", operator: "Yota" },
{ startNum: "9994234", operator: "Yota" },
{ startNum: "9994235", operator: "Yota" },
{ startNum: "9994236", operator: "Yota" },
{ startNum: "9994237", operator: "Мегафон" },
{ startNum: "9994238", operator: "Мегафон" },
{ startNum: "9994239", operator: "Мегафон" },
{ startNum: "999424", operator: "Мегафон" },
{ startNum: "999425", operator: "Мегафон" },
{ startNum: "999426", operator: "Мегафон" },
{ startNum: "999427", operator: "Мегафон" },
{ startNum: "999428", operator: "Мегафон" },
{ startNum: "999429", operator: "Мегафон" },
{ startNum: "999430", operator: "Yota" },
{ startNum: "999431", operator: "Yota" },
{ startNum: "999432", operator: "Мегафон" },
{ startNum: "999433", operator: "Мегафон" },
{ startNum: "9994340", operator: "Мегафон" },
{ startNum: "9994341", operator: "Мегафон" },
{ startNum: "9994342", operator: "Мегафон" },
{ startNum: "9994343", operator: "Мегафон" },
{ startNum: "9994344", operator: "Мегафон" },
{ startNum: "9994345", operator: "Мегафон" },
{ startNum: "9994346", operator: "Мегафон" },
{ startNum: "9994347", operator: "Мегафон" },
{ startNum: "9994348", operator: "Мегафон" },
{ startNum: "9994349", operator: "Мегафон" },
{ startNum: "9994353", operator: "Мегафон" },
{ startNum: "9994354", operator: "Мегафон" },
{ startNum: "9994355", operator: "Мегафон" },
{ startNum: "9994356", operator: "Мегафон" },
{ startNum: "9994357", operator: "Мегафон" },
{ startNum: "9994358", operator: "Мегафон" },
{ startNum: "9994359", operator: "Мегафон" },
{ startNum: "9994360", operator: "Мегафон" },
{ startNum: "9994361", operator: "Мегафон" },
{ startNum: "9994362", operator: "Мегафон" },
{ startNum: "9994363", operator: "Мегафон" },
{ startNum: "9994364", operator: "Мегафон" },
{ startNum: "9994365", operator: "Мегафон" },
{ startNum: "9994366", operator: "Мегафон" },
{ startNum: "9994367", operator: "Мегафон" },
{ startNum: "9994368", operator: "Мегафон" },
{ startNum: "9994369", operator: "Мегафон" },
{ startNum: "999437", operator: "Мегафон" },
{ startNum: "999438", operator: "Мегафон" },
{ startNum: "999439", operator: "Мегафон" },
{ startNum: "999430", operator: "Yota" },
{ startNum: "999431", operator: "Yota" },
{ startNum: "9994350", operator: "Yota" },
{ startNum: "9994351", operator: "Yota" },
{ startNum: "9994352", operator: "Yota" },
{ startNum: "999440", operator: "Yota" },
{ startNum: "999441", operator: "Yota" },
{ startNum: "999442", operator: "Yota" },
{ startNum: "999443", operator: "Yota" },
{ startNum: "999445", operator: "Yota" },
{ startNum: "999446", operator: "Yota" },
{ startNum: "999447", operator: "Yota" },
{ startNum: "999448", operator: "Yota" },
{ startNum: "9994490", operator: "Yota" },
{ startNum: "9994491", operator: "Yota" },
{ startNum: "9994444", operator: "Интернод" },
{ startNum: "9994492", operator: "Мегафон" },
{ startNum: "9994493", operator: "Мегафон" },
{ startNum: "9994494", operator: "Мегафон" },
{ startNum: "9994495", operator: "Мегафон" },
{ startNum: "9994496", operator: "Мегафон" },
{ startNum: "9994497", operator: "Мегафон" },
{ startNum: "9994498", operator: "Мегафон" },
{ startNum: "9994499", operator: "Мегафон" },
{ startNum: "999450", operator: "Yota" },
{ startNum: "999451", operator: "Yota" },
{ startNum: "999452", operator: "Yota" },
{ startNum: "999453", operator: "Yota" },
{ startNum: "999454", operator: "Yota" },
{ startNum: "999455", operator: "Yota" },
{ startNum: "999456", operator: "Yota" },
{ startNum: "999457", operator: "Yota" },
{ startNum: "999458", operator: "Yota" },
{ startNum: "999459", operator: "Yota" },
{ startNum: "999460", operator: "Yota" },
{ startNum: "999462", operator: "Yota" },
{ startNum: "999463", operator: "Yota" },
{ startNum: "999464", operator: "Yota" },
{ startNum: "999465", operator: "Yota" },
{ startNum: "999466", operator: "Yota" },
{ startNum: "999467", operator: "Yota" },
{ startNum: "999468", operator: "Yota" },
{ startNum: "999469", operator: "Yota" },
{ startNum: "999470", operator: "Yota" },
{ startNum: "999471", operator: "Мегафон" },
{ startNum: "999472", operator: "Мегафон" },
{ startNum: "999473", operator: "Мегафон" },
{ startNum: "999474", operator: "Мегафон" },
{ startNum: "9994750", operator: "Yota" },
{ startNum: "9994751", operator: "Yota" },
{ startNum: "9994752", operator: "Yota" },
{ startNum: "9994753", operator: "Yota" },
{ startNum: "9994754", operator: "Yota" },
{ startNum: "9994755", operator: "Yota" },
{ startNum: "9994756", operator: "Yota" },
{ startNum: "9994757", operator: "Yota" },
{ startNum: "9994758", operator: "Yota" },
{ startNum: "9994759", operator: "Yota" },
{ startNum: "999476", operator: "Yota" },
{ startNum: "999477", operator: "Мегафон" },
{ startNum: "9994780", operator: "Мегафон" },
{ startNum: "9994781", operator: "Мегафон" },
{ startNum: "9994782", operator: "Мегафон" },
{ startNum: "9994783", operator: "Мегафон" },
{ startNum: "9994784", operator: "Мегафон" },
{ startNum: "9994785", operator: "Мегафон" },
{ startNum: "9994786", operator: "Мегафон" },
{ startNum: "9994787", operator: "Мегафон" },
{ startNum: "9994788", operator: "Мегафон" },
{ startNum: "9994789", operator: "Мегафон" },
{ startNum: "999479", operator: "Мегафон" },
{ startNum: "9994803", operator: "Мегафон" },
{ startNum: "9994804", operator: "Мегафон" },
{ startNum: "9994805", operator: "Мегафон" },
{ startNum: "9994806", operator: "Мегафон" },
{ startNum: "9994807", operator: "Мегафон" },
{ startNum: "9994808", operator: "Мегафон" },
{ startNum: "9994809", operator: "Мегафон" },
{ startNum: "999481", operator: "Мегафон" },
{ startNum: "999482", operator: "Мегафон" },
{ startNum: "999483", operator: "Мегафон" },
{ startNum: "9994840", operator: "Мегафон" },
{ startNum: "9994841", operator: "Мегафон" },
{ startNum: "9994842", operator: "Мегафон" },
{ startNum: "9994843", operator: "Мегафон" },
{ startNum: "9994844", operator: "Мегафон" },
{ startNum: "9994845", operator: "Мегафон" },
{ startNum: "9994846", operator: "Мегафон" },
{ startNum: "9994847", operator: "Мегафон" },
{ startNum: "9994800", operator: "Yota" },
{ startNum: "9994801", operator: "Yota" },
{ startNum: "9994802", operator: "Yota" },
{ startNum: "9994848", operator: "Yota" },
{ startNum: "9994849", operator: "Yota" },
{ startNum: "9994850", operator: "Yota" },
{ startNum: "9994851", operator: "Yota" },
{ startNum: "9994852", operator: "Yota" },
{ startNum: "9994853", operator: "Мегафон" },
{ startNum: "9994854", operator: "Мегафон" },
{ startNum: "9994855", operator: "Мегафон" },
{ startNum: "9994856", operator: "Мегафон" },
{ startNum: "9994857", operator: "Мегафон" },
{ startNum: "9994858", operator: "Мегафон" },
{ startNum: "9994859", operator: "Мегафон" },
{ startNum: "999486", operator: "Мегафон" },
{ startNum: "999487", operator: "Мегафон" },
{ startNum: "999488", operator: "Мегафон" },
{ startNum: "9994890", operator: "Мегафон" },
{ startNum: "9994891", operator: "Мегафон" },
{ startNum: "9994892", operator: "Мегафон" },
{ startNum: "9994893", operator: "Мегафон" },
{ startNum: "9994894", operator: "Мегафон" },
{ startNum: "9994895", operator: "Мегафон" },
{ startNum: "9994896", operator: "Мегафон" },
{ startNum: "9994897", operator: "Мегафон" },
{ startNum: "9994898", operator: "Мегафон" },
{ startNum: "9994899", operator: "Мегафон" },
{ startNum: "9994900", operator: "Yota" },
{ startNum: "9994901", operator: "Yota" },
{ startNum: "9994902", operator: "Yota" },
{ startNum: "9994903", operator: "Мегафон" },
{ startNum: "9994904", operator: "Мегафон" },
{ startNum: "9994905", operator: "Мегафон" },
{ startNum: "9994906", operator: "Мегафон" },
{ startNum: "9994907", operator: "Мегафон" },
{ startNum: "9994908", operator: "Мегафон" },
{ startNum: "9994909", operator: "Мегафон" },
{ startNum: "9994910", operator: "Мегафон" },
{ startNum: "9994911", operator: "Мегафон" },
{ startNum: "9994912", operator: "Мегафон" },
{ startNum: "9994913", operator: "Мегафон" },
{ startNum: "9994914", operator: "Мегафон" },
{ startNum: "9994915", operator: "Мегафон" },
{ startNum: "9994916", operator: "Мегафон" },
{ startNum: "9994917", operator: "Мегафон" },
{ startNum: "9994918", operator: "Мегафон" },
{ startNum: "9994919", operator: "Мегафон" },
{ startNum: "9994920", operator: "Мегафон" },
{ startNum: "9994921", operator: "Мегафон" },
{ startNum: "9994922", operator: "Мегафон" },
{ startNum: "9994923", operator: "Мегафон" },
{ startNum: "9994924", operator: "Мегафон" },
{ startNum: "9994925", operator: "Мегафон" },
{ startNum: "9994926", operator: "Мегафон" },
{ startNum: "9994927", operator: "Мегафон" },
{ startNum: "9994928", operator: "Мегафон" },
{ startNum: "9994929", operator: "Мегафон" },
{ startNum: "9994930", operator: "Мегафон" },
{ startNum: "9994931", operator: "Мегафон" },
{ startNum: "9994932", operator: "Мегафон" },
{ startNum: "9994933", operator: "Мегафон" },
{ startNum: "9994934", operator: "Мегафон" },
{ startNum: "9994935", operator: "Мегафон" },
{ startNum: "9994936", operator: "Мегафон" },
{ startNum: "9994937", operator: "Мегафон" },
{ startNum: "9994938", operator: "Мегафон" },
{ startNum: "9994939", operator: "Мегафон" },
{ startNum: "9994940", operator: "Мегафон" },
{ startNum: "9994941", operator: "Мегафон" },
{ startNum: "9994942", operator: "Мегафон" },
{ startNum: "9994943", operator: "Мегафон" },
{ startNum: "9994944", operator: "Мегафон" },
{ startNum: "9994945", operator: "Мегафон" },
{ startNum: "9994946", operator: "Мегафон" },
{ startNum: "9994947", operator: "Мегафон" },
{ startNum: "9994948", operator: "Мегафон" },
{ startNum: "9994949", operator: "Мегафон" },
{ startNum: "999495", operator: "Yota" },
{ startNum: "999496", operator: "Мегафон" },
{ startNum: "9994975", operator: "Мегафон" },
{ startNum: "9994976", operator: "Мегафон" },
{ startNum: "9994977", operator: "Мегафон" },
{ startNum: "9994978", operator: "Мегафон" },
{ startNum: "9994979", operator: "Мегафон" },
{ startNum: "999498", operator: "Мегафон" },
{ startNum: "9994990", operator: "Мегафон" },
{ startNum: "9994991", operator: "Мегафон" },
{ startNum: "9994992", operator: "Мегафон" },
{ startNum: "9994993", operator: "Мегафон" },
{ startNum: "9994994", operator: "Мегафон" },
{ startNum: "9994995", operator: "Yota" },
{ startNum: "9994996", operator: "Yota" },
{ startNum: "9994997", operator: "Yota" },
{ startNum: "9994998", operator: "Yota" },
{ startNum: "9994999", operator: "Yota" },
{ startNum: "9995000", operator: "Yota" },
{ startNum: "9995001", operator: "Yota" },
{ startNum: "9995002", operator: "Yota" },
{ startNum: "999503", operator: "Мегафон" },
{ startNum: "999504", operator: "Мегафон" },
{ startNum: "999505", operator: "Мегафон" },
{ startNum: "999506", operator: "Мегафон" },
{ startNum: "999507", operator: "Мегафон" },
{ startNum: "9995098", operator: "Yota" },
{ startNum: "9995099", operator: "Yota" },
{ startNum: "9995100", operator: "Yota" },
{ startNum: "9995101", operator: "Yota" },
{ startNum: "9995102", operator: "Yota" },
{ startNum: "9995103", operator: "Yota" },
{ startNum: "9995104", operator: "Yota" },
{ startNum: "9995105", operator: "Yota" },
{ startNum: "9995106", operator: "Yota" },
{ startNum: "9995107", operator: "Yota" },
{ startNum: "9995108", operator: "Yota" },
{ startNum: "9995109", operator: "Yota" },
{ startNum: "9995110", operator: "Yota" },
{ startNum: "9995111", operator: "Yota" },
{ startNum: "9995112", operator: "Yota" },
{ startNum: "9995113", operator: "Yota" },
{ startNum: "9995114", operator: "Yota" },
{ startNum: "9995115", operator: "Yota" },
{ startNum: "9995116", operator: "Yota" },
{ startNum: "9995117", operator: "Yota" },
{ startNum: "9995118", operator: "Yota" },
{ startNum: "9995119", operator: "Yota" },
{ startNum: "9995120", operator: "Yota" },
{ startNum: "9995121", operator: "Yota" },
{ startNum: "9995122", operator: "Yota" },
{ startNum: "9995123", operator: "Yota" },
{ startNum: "9995124", operator: "Yota" },
{ startNum: "9995125", operator: "Yota" },
{ startNum: "9995126", operator: "Yota" },
{ startNum: "9995127", operator: "Yota" },
{ startNum: "9995128", operator: "Yota" },
{ startNum: "9995129", operator: "Yota" },
{ startNum: "9995130", operator: "Yota" },
{ startNum: "9995131", operator: "Yota" },
{ startNum: "9995132", operator: "Yota" },
{ startNum: "9995133", operator: "Yota" },
{ startNum: "9995134", operator: "Yota" },
{ startNum: "9995135", operator: "Yota" },
{ startNum: "9995136", operator: "Yota" },
{ startNum: "9995137", operator: "Yota" },
{ startNum: "9995138", operator: "Yota" },
{ startNum: "9995139", operator: "Yota" },
{ startNum: "9995140", operator: "Yota" },
{ startNum: "9995141", operator: "Yota" },
{ startNum: "9995142", operator: "Yota" },
{ startNum: "9995143", operator: "Yota" },
{ startNum: "9995144", operator: "Yota" },
{ startNum: "9995145", operator: "Yota" },
{ startNum: "9995146", operator: "Yota" },
{ startNum: "9995147", operator: "Yota" },
{ startNum: "9995148", operator: "Yota" },
{ startNum: "9995149", operator: "Yota" },
{ startNum: "9995150", operator: "Yota" },
{ startNum: "9995151", operator: "Yota" },
{ startNum: "9995152", operator: "Yota" },
{ startNum: "9995153", operator: "Yota" },
{ startNum: "9995154", operator: "Yota" },
{ startNum: "9995155", operator: "Yota" },
{ startNum: "9995156", operator: "Yota" },
{ startNum: "9995157", operator: "Yota" },
{ startNum: "9995158", operator: "Yota" },
{ startNum: "9995159", operator: "Yota" },
{ startNum: "9995160", operator: "Yota" },
{ startNum: "9995161", operator: "Yota" },
{ startNum: "9995162", operator: "Yota" },
{ startNum: "9995163", operator: "Yota" },
{ startNum: "9995164", operator: "Yota" },
{ startNum: "9995165", operator: "Yota" },
{ startNum: "9995166", operator: "Yota" },
{ startNum: "9995167", operator: "Yota" },
{ startNum: "9995168", operator: "Yota" },
{ startNum: "9995169", operator: "Yota" },
{ startNum: "9995170", operator: "Yota" },
{ startNum: "9995171", operator: "Yota" },
{ startNum: "9995172", operator: "Yota" },
{ startNum: "9995173", operator: "Yota" },
{ startNum: "9995174", operator: "Yota" },
{ startNum: "9995175", operator: "Yota" },
{ startNum: "9995176", operator: "Yota" },
{ startNum: "9995177", operator: "Yota" },
{ startNum: "9995178", operator: "Yota" },
{ startNum: "9995179", operator: "Yota" },
{ startNum: "9995180", operator: "Yota" },
{ startNum: "9995181", operator: "Yota" },
{ startNum: "9995182", operator: "Yota" },
{ startNum: "9995183", operator: "Yota" },
{ startNum: "9995184", operator: "Yota" },
{ startNum: "9995185", operator: "Yota" },
{ startNum: "9995186", operator: "Yota" },
{ startNum: "9995187", operator: "Yota" },
{ startNum: "9995188", operator: "Yota" },
{ startNum: "9995189", operator: "Yota" },
{ startNum: "9995190", operator: "Yota" },
{ startNum: "9995191", operator: "Yota" },
{ startNum: "9995192", operator: "Yota" },
{ startNum: "9995193", operator: "Yota" },
{ startNum: "9995194", operator: "Yota" },
{ startNum: "9995195", operator: "Yota" },
{ startNum: "9995196", operator: "Yota" },
{ startNum: "9995197", operator: "Yota" },
{ startNum: "9995198", operator: "Yota" },
{ startNum: "9995199", operator: "Yota" },
{ startNum: "9995200", operator: "Yota" },
{ startNum: "9995201", operator: "Yota" },
{ startNum: "9995202", operator: "Yota" },
{ startNum: "9995220", operator: "Yota" },
{ startNum: "9995221", operator: "Yota" },
{ startNum: "9995222", operator: "Yota" },
{ startNum: "9995223", operator: "Yota" },
{ startNum: "9995234", operator: "Yota" },
{ startNum: "9995235", operator: "Yota" },
{ startNum: "9995236", operator: "Yota" },
{ startNum: "9995237", operator: "Yota" },
{ startNum: "9995238", operator: "Yota" },
{ startNum: "9995239", operator: "Yota" },
{ startNum: "9995240", operator: "Yota" },
{ startNum: "9995241", operator: "Yota" },
{ startNum: "9995242", operator: "Yota" },
{ startNum: "9995243", operator: "Yota" },
{ startNum: "9995244", operator: "Yota" },
{ startNum: "9995290", operator: "Yota" },
{ startNum: "9995291", operator: "Yota" },
{ startNum: "9995292", operator: "Yota" },
{ startNum: "9995293", operator: "Yota" },
{ startNum: "9995294", operator: "Yota" },
{ startNum: "9995295", operator: "Yota" },
{ startNum: "9995296", operator: "Yota" },
{ startNum: "9995297", operator: "Yota" },
{ startNum: "9995298", operator: "Yota" },
{ startNum: "9995299", operator: "Yota" },
{ startNum: "9995300", operator: "Yota" },
{ startNum: "9995301", operator: "Yota" },
{ startNum: "9995302", operator: "Yota" },
{ startNum: "9995303", operator: "Yota" },
{ startNum: "9995304", operator: "Yota" },
{ startNum: "9995305", operator: "Yota" },
{ startNum: "9995306", operator: "Yota" },
{ startNum: "9995307", operator: "Yota" },
{ startNum: "9995308", operator: "Yota" },
{ startNum: "9995309", operator: "Yota" },
{ startNum: "9995310", operator: "Yota" },
{ startNum: "9995311", operator: "Yota" },
{ startNum: "9995320", operator: "Yota" },
{ startNum: "9995321", operator: "Yota" },
{ startNum: "9995330", operator: "Yota" },
{ startNum: "9995331", operator: "Yota" },
{ startNum: "9995340", operator: "Yota" },
{ startNum: "9995341", operator: "Yota" },
{ startNum: "9995342", operator: "Yota" },
{ startNum: "9995343", operator: "Yota" },
{ startNum: "9995344", operator: "Yota" },
{ startNum: "9995380", operator: "Yota" },
{ startNum: "9995381", operator: "Yota" },
{ startNum: "9995382", operator: "Yota" },
{ startNum: "9995383", operator: "Yota" },
{ startNum: "9995384", operator: "Yota" },
{ startNum: "9995385", operator: "Yota" },
{ startNum: "9995386", operator: "Yota" },
{ startNum: "9995387", operator: "Yota" },
{ startNum: "9995388", operator: "Yota" },
{ startNum: "9995389", operator: "Yota" },
{ startNum: "9995390", operator: "Yota" },
{ startNum: "9995391", operator: "Yota" },
{ startNum: "9995392", operator: "Yota" },
{ startNum: "9995393", operator: "Yota" },
{ startNum: "9995394", operator: "Yota" },
{ startNum: "9995395", operator: "Yota" },
{ startNum: "9995396", operator: "Yota" },
{ startNum: "9995397", operator: "Yota" },
{ startNum: "9995398", operator: "Yota" },
{ startNum: "9995399", operator: "Yota" },
{ startNum: "9995400", operator: "Yota" },
{ startNum: "9995401", operator: "Yota" },
{ startNum: "9995402", operator: "Yota" },
{ startNum: "9995403", operator: "Мегафон" },
{ startNum: "9995404", operator: "Мегафон" },
{ startNum: "9995405", operator: "Мегафон" },
{ startNum: "9995406", operator: "Мегафон" },
{ startNum: "9995407", operator: "Мегафон" },
{ startNum: "9995408", operator: "Мегафон" },
{ startNum: "9995409", operator: "Мегафон" },
{ startNum: "9995410", operator: "Мегафон" },
{ startNum: "9995411", operator: "Мегафон" },
{ startNum: "9995412", operator: "Мегафон" },
{ startNum: "9995413", operator: "Мегафон" },
{ startNum: "9995414", operator: "Мегафон" },
{ startNum: "9995415", operator: "Мегафон" },
{ startNum: "9995416", operator: "Мегафон" },
{ startNum: "9995417", operator: "Мегафон" },
{ startNum: "9995418", operator: "Мегафон" },
{ startNum: "9995470", operator: "Мегафон" },
{ startNum: "9995471", operator: "Мегафон" },
{ startNum: "9995472", operator: "Мегафон" },
{ startNum: "9995473", operator: "Мегафон" },
{ startNum: "9995474", operator: "Мегафон" },
{ startNum: "9995475", operator: "Мегафон" },
{ startNum: "9995476", operator: "Мегафон" },
{ startNum: "9995407", operator: "Мегафон" },
{ startNum: "9995408", operator: "Мегафон" },
{ startNum: "9995409", operator: "Мегафон" },
{ startNum: "9995410", operator: "Мегафон" },
{ startNum: "999541", operator: "Мегафон" },
{ startNum: "999542", operator: "Мегафон" },
{ startNum: "999543", operator: "Мегафон" },
{ startNum: "999544", operator: "Мегафон" },
{ startNum: "999545", operator: "Мегафон" },
{ startNum: "999546", operator: "Мегафон" },
{ startNum: "999547", operator: "Мегафон" },
{ startNum: "9995471", operator: "Мегафон" },
{ startNum: "9995472", operator: "Мегафон" },
{ startNum: "9995473", operator: "Мегафон" },
{ startNum: "9995474", operator: "Мегафон" },
{ startNum: "9995475", operator: "Мегафон" },
{ startNum: "9995476", operator: "Мегафон" },
{ startNum: "9995477", operator: "Мегафон" },
{ startNum: "9995478", operator: "Мегафон" },
{ startNum: "9995479", operator: "Мегафон" },
{ startNum: "999548", operator: "Мегафон" },
{ startNum: "999549", operator: "Мегафон" },
{ startNum: "9995500", operator: "Yota" },
{ startNum: "9995501", operator: "Yota" },
{ startNum: "9995502", operator: "Yota" },
{ startNum: "9995503", operator: "Мегафон" },
{ startNum: "9995504", operator: "Мегафон" },
{ startNum: "9995505", operator: "Мегафон" },
{ startNum: "9995506", operator: "Мегафон" },
{ startNum: "9995507", operator: "Мегафон" },
{ startNum: "9995508", operator: "Мегафон" },
{ startNum: "9995509", operator: "Мегафон" },
{ startNum: "999551", operator: "Мегафон" },
{ startNum: "999552", operator: "Мегафон" },
{ startNum: "999553", operator: "Мегафон" },
{ startNum: "999554", operator: "Мегафон" },
{ startNum: "999555", operator: "Мегафон" },
{ startNum: "999556", operator: "Мегафон" },
{ startNum: "999557", operator: "Мегафон" },
{ startNum: "999558", operator: "Мегафон" },
{ startNum: "999559", operator: "Yota" },
{ startNum: "99956", operator: "Yota" },
{ startNum: "999570", operator: "Yota" },
{ startNum: "9995710", operator: "Yota" },
{ startNum: "9995711", operator: "Yota" },
{ startNum: "9995712", operator: "Yota" },
{ startNum: "9995713", operator: "Yota" },
{ startNum: "9995714", operator: "Yota" },
{ startNum: "9995715", operator: "Yota" },
{ startNum: "9995716", operator: "Yota" },
{ startNum: "9995717", operator: "Мегафон" },
{ startNum: "9995718", operator: "Мегафон" },
{ startNum: "9995719", operator: "Мегафон" },
{ startNum: "999572", operator: "Мегафон" },
{ startNum: "999573", operator: "Мегафон" },
{ startNum: "999574", operator: "Мегафон" },
{ startNum: "999575", operator: "Мегафон" },
{ startNum: "999576", operator: "Мегафон" },
{ startNum: "999577", operator: "Мегафон" },
{ startNum: "999578", operator: "Мегафон" },
{ startNum: "999579", operator: "Мегафон" },
{ startNum: "999580", operator: "Мегафон" },
{ startNum: "999581", operator: "Yota" },
{ startNum: "999582", operator: "Yota" },
{ startNum: "999583", operator: "Yota" },
{ startNum: "999584", operator: "Yota" },
{ startNum: "999585", operator: "Yota" },
{ startNum: "999586", operator: "Yota" },
{ startNum: "999587", operator: "Yota" },
{ startNum: "999588", operator: "Yota" },
{ startNum: "999589", operator: "Yota" },
{ startNum: "9995900", operator: "Yota" },
{ startNum: "9995901", operator: "Yota" },
{ startNum: "9995902", operator: "Yota" },
{ startNum: "999593", operator: "Мегафон" },
{ startNum: "999594", operator: "Мегафон" },
{ startNum: "999595", operator: "Мегафон" },
{ startNum: "999596", operator: "Мегафон" },
{ startNum: "999597", operator: "Мегафон" },
{ startNum: "999598", operator: "Мегафон" },
{ startNum: "999599", operator: "Мегафон" },
{ startNum: "999600", operator: "Yota" },
{ startNum: "9996010", operator: "Yota" },
{ startNum: "9996011", operator: "Yota" },
{ startNum: "9996012", operator: "Yota" },
{ startNum: "9996013", operator: "Yota" },
{ startNum: "9996014", operator: "Yota" },
{ startNum: "9996015", operator: "Yota" },
{ startNum: "9996016", operator: "Yota" },
{ startNum: "9996017", operator: "Yota" },
{ startNum: "9996018", operator: "Yota" },
{ startNum: "9996019", operator: "Yota" },
{ startNum: "999602", operator: "Yota" },
{ startNum: "999603", operator: "Yota" },
{ startNum: "999604", operator: "Yota" },
{ startNum: "9996050", operator: "Yota" },
{ startNum: "9996051", operator: "Yota" },
{ startNum: "9996052", operator: "Yota" },
{ startNum: "9996053", operator: "Yota" },
{ startNum: "9996054", operator: "Yota" },
{ startNum: "9996055", operator: "Yota" },
{ startNum: "9996056", operator: "Yota" },
{ startNum: "9996057", operator: "Yota" },
{ startNum: "9996058", operator: "Yota" },
{ startNum: "9996059", operator: "Yota" },
{ startNum: "999606", operator: "Yota" },
{ startNum: "999607", operator: "Yota" },
{ startNum: "999608", operator: "Yota" },
{ startNum: "9996090", operator: "Yota" },
{ startNum: "9996091", operator: "Yota" },
{ startNum: "9996092", operator: "Yota" },
{ startNum: "9996093", operator: "Yota" },
{ startNum: "9996094", operator: "Yota" },
{ startNum: "9996095", operator: "Yota" },
{ startNum: "9996096", operator: "Yota" },
{ startNum: "9996097", operator: "Yota" },
{ startNum: "9996098", operator: "Yota" },
{ startNum: "9996099", operator: "Yota" },
{ startNum: "9996100", operator: "Yota" },
{ startNum: "9996101", operator: "Yota" },
{ startNum: "9996102", operator: "Yota" },
{ startNum: "9996103", operator: "Yota" },
{ startNum: "9996104", operator: "Yota" },
{ startNum: "9996105", operator: "Yota" },
{ startNum: "9996106", operator: "Yota" },
{ startNum: "9996107", operator: "Yota" },
{ startNum: "9996108", operator: "Yota" },
{ startNum: "9996109", operator: "Yota" },
{ startNum: "999611", operator: "Yota" },
{ startNum: "9996120", operator: "Yota" },
{ startNum: "9996121", operator: "Yota" },
{ startNum: "9996122", operator: "Yota" },
{ startNum: "9996123", operator: "Yota" },
{ startNum: "9996124", operator: "Yota" },
{ startNum: "9996125", operator: "Yota" },
{ startNum: "9996126", operator: "Yota" },
{ startNum: "9996127", operator: "Yota" },
{ startNum: "9996128", operator: "Yota" },
{ startNum: "9996129", operator: "Yota" },
{ startNum: "9996130", operator: "Yota" },
{ startNum: "9996131", operator: "Yota" },
{ startNum: "9996132", operator: "Yota" },
{ startNum: "9996133", operator: "Yota" },
{ startNum: "9996134", operator: "Yota" },
{ startNum: "9996135", operator: "Yota" },
{ startNum: "9996136", operator: "Yota" },
{ startNum: "9996137", operator: "Yota" },
{ startNum: "9996138", operator: "Yota" },
{ startNum: "9996139", operator: "Yota" },
{ startNum: "999614", operator: "Yota" },
{ startNum: "999615", operator: "Yota" },
{ startNum: "9996138", operator: "Yota" },
{ startNum: "9996139", operator: "Yota" },
{ startNum: "999614", operator: "Yota" },
{ startNum: "999615", operator: "Yota" },
{ startNum: "999616", operator: "Yota" },
{ startNum: "999617", operator: "Yota" },
{ startNum: "9996180", operator: "Yota" },
{ startNum: "9996181", operator: "Yota" },
{ startNum: "9996182", operator: "Yota" },
{ startNum: "9996183", operator: "Yota" },
{ startNum: "9996184", operator: "Yota" },
{ startNum: "9996185", operator: "Yota" },
{ startNum: "9996186", operator: "Yota" },
{ startNum: "9996187", operator: "Yota" },
{ startNum: "9996188", operator: "Yota" },
{ startNum: "9996189", operator: "Yota" },
{ startNum: "999619", operator: "Yota" },
{ startNum: "9996200", operator: "Yota" },
{ startNum: "9996201", operator: "Yota" },
{ startNum: "9996202", operator: "Yota" },
{ startNum: "9996203", operator: "Yota" },
{ startNum: "9996204", operator: "Yota" },
{ startNum: "9996205", operator: "Yota" },
{ startNum: "9996206", operator: "Yota" },
{ startNum: "9996207", operator: "Yota" },
{ startNum: "9996208", operator: "Yota" },
{ startNum: "9996209", operator: "Yota" },
{ startNum: "9996210", operator: "Yota" },
{ startNum: "9996211", operator: "Yota" },
{ startNum: "9996212", operator: "Yota" },
{ startNum: "9996213", operator: "Yota" },
{ startNum: "9996214", operator: "Yota" },
{ startNum: "9996215", operator: "Yota" },
{ startNum: "9996216", operator: "Yota" },
{ startNum: "9996217", operator: "Yota" },
{ startNum: "9996218", operator: "Yota" },
{ startNum: "9996219", operator: "Yota" },
{ startNum: "9996220", operator: "Yota" },
{ startNum: "9996221", operator: "Yota" },
{ startNum: "9996222", operator: "Yota" },
{ startNum: "9996223", operator: "Yota" },
{ startNum: "9996224", operator: "Yota" },
{ startNum: "9996225", operator: "Yota" },
{ startNum: "9996226", operator: "Yota" },
{ startNum: "9996227", operator: "Yota" },
{ startNum: "9996228", operator: "Yota" },
{ startNum: "9996229", operator: "Yota" },
{ startNum: "999623", operator: "Yota" },
{ startNum: "999624", operator: "Yota" },
{ startNum: "999625", operator: "Yota" },
{ startNum: "999626", operator: "Yota" },
{ startNum: "999627", operator: "Yota" },
{ startNum: "999628", operator: "Yota" },
{ startNum: "999629", operator: "Yota" },
{ startNum: "99963", operator: "Yota" },
{ startNum: "999640", operator: "Yota" },
{ startNum: "999641", operator: "Yota" },
{ startNum: "999642", operator: "Yota" },
{ startNum: "999643", operator: "Yota" },
{ startNum: "999644", operator: "Yota" },
{ startNum: "999645", operator: "Yota" },
{ startNum: "999646", operator: "Yota" },
{ startNum: "9996470", operator: "Yota" },
{ startNum: "9996471", operator: "Yota" },
{ startNum: "9996472", operator: "Yota" },
{ startNum: "9996473", operator: "Yota" },
{ startNum: "9996474", operator: "Yota" },
{ startNum: "9996475", operator: "Yota" },
{ startNum: "9996476", operator: "Yota" },
{ startNum: "9996477", operator: "Yota" },
{ startNum: "9996478", operator: "Yota" },
{ startNum: "9996479", operator: "Yota" },
{ startNum: "999648", operator: "Yota" },
{ startNum: "9996490", operator: "Yota" },
{ startNum: "9996491", operator: "Yota" },
{ startNum: "9996492", operator: "Yota" },
{ startNum: "9996493", operator: "Yota" },
{ startNum: "9996494", operator: "Yota" },
{ startNum: "9996495", operator: "Yota" },
{ startNum: "9996496", operator: "Yota" },
{ startNum: "9996497", operator: "Yota" },
{ startNum: "9996498", operator: "Yota" },
{ startNum: "9996499", operator: "Yota" },
{ startNum: "999650", operator: "Yota" },
{ startNum: "999651", operator: "Yota" },
{ startNum: "999652", operator: "Yota" },
{ startNum: "999653", operator: "Yota" },
{ startNum: "999654", operator: "Yota" },
{ startNum: "999655", operator: "Yota" },
{ startNum: "999656", operator: "Yota" },
{ startNum: "999657", operator: "Yota" },
{ startNum: "999658", operator: "Мегафон" },
{ startNum: "999659", operator: "Мегафон" },
{ startNum: "9996600", operator: "Yota" },
{ startNum: "9996601", operator: "Yota" },
{ startNum: "9996602", operator: "Yota" },
{ startNum: "9996603", operator: "Yota" },
{ startNum: "9996604", operator: "Yota" },
{ startNum: "9996605", operator: "Мегафон" },
{ startNum: "9996606", operator: "Мегафон" },
{ startNum: "9996607", operator: "Мегафон" },
{ startNum: "9996608", operator: "Мегафон" },
{ startNum: "9996609", operator: "Мегафон" },
{ startNum: "999661", operator: "Мегафон" },
{ startNum: "999662", operator: "Мегафон" },
{ startNum: "999663", operator: "Мегафон" },
{ startNum: "999664", operator: "Мегафон" },
{ startNum: "999665", operator: "Мегафон" },
{ startNum: "999666", operator: "Yota" },
{ startNum: "999667", operator: "Yota" },
{ startNum: "999668", operator: "Yota" },
{ startNum: "999669", operator: "Yota" },
{ startNum: "9996700", operator: "Yota" },
{ startNum: "9996701", operator: "Yota" },
{ startNum: "9996702", operator: "Yota" },
{ startNum: "999673", operator: "Yota" },
{ startNum: "999674", operator: "Yota" },
{ startNum: "999675", operator: "Yota" },
{ startNum: "999676", operator: "Yota" },
{ startNum: "999677", operator: "Yota" },
{ startNum: "999678", operator: "Yota" },
{ startNum: "999679", operator: "Yota" },
{ startNum: "999680", operator: "Tele2" },
{ startNum: "9996815", operator: "Yota" },
{ startNum: "9996816", operator: "Yota" },
{ startNum: "9996817", operator: "Yota" },
{ startNum: "9996818", operator: "Yota" },
{ startNum: "9996819", operator: "Yota" },
{ startNum: "999682", operator: "Yota" },
{ startNum: "999683", operator: "Yota" },
{ startNum: "999684", operator: "Yota" },
{ startNum: "999685", operator: "Yota" },
{ startNum: "999686", operator: "Yota" },
{ startNum: "9996903", operator: "Мегафон" },
{ startNum: "9996904", operator: "Мегафон" },
{ startNum: "9996905", operator: "Мегафон" },
{ startNum: "9996906", operator: "Мегафон" },
{ startNum: "9996907", operator: "Мегафон" },
{ startNum: "9996908", operator: "Мегафон" },
{ startNum: "9996909", operator: "Мегафон" },
{ startNum: "999691", operator: "Yota" },
{ startNum: "999692", operator: "Yota" },
{ startNum: "999693", operator: "Yota" },
{ startNum: "999694", operator: "Yota" },
{ startNum: "999695", operator: "Yota" },
{ startNum: "999696", operator: "Yota" },
{ startNum: "999697", operator: "Yota" },
{ startNum: "999698", operator: "Yota" },
{ startNum: "999699", operator: "Yota" },
{ startNum: "999700", operator: "Yota" },
{ startNum: "999701", operator: "Мегафон" },
{ startNum: "999702", operator: "Мегафон" },
{ startNum: "999703", operator: "Мегафон" },
{ startNum: "999704", operator: "Мегафон" },
{ startNum: "999705", operator: "Yota" },
{ startNum: "999706", operator: "Мегафон" },
{ startNum: "999707", operator: "Мегафон" },
{ startNum: "999708", operator: "Мегафон" },
{ startNum: "999709", operator: "Мегафон" },
{ startNum: "999710", operator: "Yota" },
{ startNum: "999711", operator: "Yota" },
{ startNum: "999712", operator: "Yota" },
{ startNum: "999713", operator: "Yota" },
{ startNum: "999714", operator: "Yota" },
{ startNum: "999715", operator: "Yota" },
{ startNum: "999716", operator: "Yota" },
{ startNum: "999717", operator: "Yota" },
{ startNum: "999718", operator: "Yota" },
{ startNum: "999719", operator: "Yota" },
{ startNum: "999720", operator: "Yota" },
{ startNum: "999721", operator: "Yota" },
{ startNum: "999722", operator: "Yota" },
{ startNum: "9997230", operator: "Yota" },
{ startNum: "9997231", operator: "Yota" },
{ startNum: "9997232", operator: "Yota" },
{ startNum: "9997233", operator: "Yota" },
{ startNum: "9997234", operator: "Yota" },
{ startNum: "9997235", operator: "Yota" },
{ startNum: "9997236", operator: "Yota" },
{ startNum: "9997237", operator: "Yota" },
{ startNum: "9997238", operator: "Yota" },
{ startNum: "9997239", operator: "Yota" },
{ startNum: "999724", operator: "Yota" },
{ startNum: "9997250", operator: "Yota" },
{ startNum: "9997251", operator: "Yota" },
{ startNum: "9997252", operator: "Yota" },
{ startNum: "9997253", operator: "Yota" },
{ startNum: "9997254", operator: "Yota" },
{ startNum: "9997255", operator: "Yota" },
{ startNum: "9997256", operator: "Yota" },
{ startNum: "9997257", operator: "Yota" },
{ startNum: "9997258", operator: "Yota" },
{ startNum: "9997259", operator: "Yota" },
{ startNum: "999726", operator: "Yota" },
{ startNum: "999727", operator: "Yota" },
{ startNum: "999728", operator: "Мегафон" },
{ startNum: "999729", operator: "Мегафон" },
{ startNum: "999730", operator: "Yota" },
{ startNum: "999731", operator: "Мегафон" },
{ startNum: "999732", operator: "Yota" },
{ startNum: "999733", operator: "Yota" },
{ startNum: "9997340", operator: "Yota" },
{ startNum: "9997341", operator: "Yota" },
{ startNum: "9997342", operator: "Yota" },
{ startNum: "9997343", operator: "Yota" },
{ startNum: "9997344", operator: "Мегафон" },
{ startNum: "9997345", operator: "Мегафон" },
{ startNum: "9997346", operator: "Мегафон" },
{ startNum: "9997347", operator: "Мегафон" },
{ startNum: "9997348", operator: "Мегафон" },
{ startNum: "9997349", operator: "Мегафон" },
{ startNum: "999735", operator: "Yota" },
{ startNum: "999736", operator: "Мегафон" },
{ startNum: "999737", operator: "Мегафон" },
{ startNum: "999738", operator: "Мегафон" },
{ startNum: "999739", operator: "Мегафон" },
{ startNum: "9997400", operator: "Yota" },
{ startNum: "9997401", operator: "Yota" },
{ startNum: "9997402", operator: "Yota" },
{ startNum: "999745", operator: "Yota" },
{ startNum: "999750", operator: "Yota" },
{ startNum: "99975", operator: "Yota" },

{ startNum: "999755", operator: "Yota" },
{ startNum: "999750", operator: "Yota" },

{ startNum: "9997403", operator: "Мегафон" },
{ startNum: "9997404", operator: "Мегафон" },
{ startNum: "9997405", operator: "Мегафон" },
{ startNum: "9997406", operator: "Мегафон" },
{ startNum: "9997407", operator: "Мегафон" },
{ startNum: "9997408", operator: "Мегафон" },
{ startNum: "9997409", operator: "Мегафон" },
{ startNum: "9997410", operator: "Мегафон" },
{ startNum: "9997420", operator: "Мегафон" },
{ startNum: "9997430", operator: "Мегафон" },
{ startNum: "9997431", operator: "Мегафон" },
{ startNum: "9997432", operator: "Мегафон" },
{ startNum: "9997433", operator: "Мегафон" },
{ startNum: "9997434", operator: "Мегафон" },
{ startNum: "9997435", operator: "Мегафон" },
{ startNum: "9997436", operator: "Мегафон" },
{ startNum: "9997437", operator: "Мегафон" },
{ startNum: "9997438", operator: "Мегафон" },
{ startNum: "9997439", operator: "Мегафон" },
{ startNum: "9997440", operator: "Мегафон" },
{ startNum: "9997460", operator: "Мегафон" },
{ startNum: "9997470", operator: "Мегафон" },
{ startNum: "9997480", operator: "Мегафон" },
{ startNum: "9997481", operator: "Мегафон" },
{ startNum: "9997482", operator: "Мегафон" },
{ startNum: "9997483", operator: "Мегафон" },
{ startNum: "9997484", operator: "Мегафон" },
{ startNum: "9997485", operator: "Мегафон" },
{ startNum: "9997486", operator: "Мегафон" },
{ startNum: "9997487", operator: "Мегафон" },
{ startNum: "9997488", operator: "Мегафон" },
{ startNum: "9997489", operator: "Мегафон" },
{ startNum: "9997490", operator: "Мегафон" },
{ startNum: "9997510", operator: "Мегафон" },
{ startNum: "9997520", operator: "Мегафон" },
{ startNum: "9997530", operator: "Мегафон" },
{ startNum: "9997540", operator: "Мегафон" },
{ startNum: "9997560", operator: "Мегафон" },
{ startNum: "9997570", operator: "Мегафон" },
{ startNum: "9997580", operator: "Мегафон" },
{ startNum: "9997590", operator: "Мегафон" },
{ startNum: "9997603", operator: "Мегафон" },
{ startNum: "9997604", operator: "Мегафон" },
{ startNum: "9997605", operator: "Мегафон" },
{ startNum: "9997606", operator: "Мегафон" },
{ startNum: "9997607", operator: "Мегафон" },
{ startNum: "9997608", operator: "Мегафон" },
{ startNum: "9997609", operator: "Мегафон" },
{ startNum: "9997610", operator: "Мегафон" },
{ startNum: "9997620", operator: "Мегафон" },
{ startNum: "9997630", operator: "Мегафон" },
{ startNum: "9997640", operator: "Мегафон" },
{ startNum: "9997641", operator: "Мегафон" },
{ startNum: "9997642", operator: "Мегафон" },
{ startNum: "999745", operator: "Yota" },
{ startNum: "999750", operator: "Yota" },
{ startNum: "999755", operator: "Yota" },
{ startNum: "999760", operator: "Yota" },
{ startNum: "9997601", operator: "Yota" },
{ startNum: "9997602", operator: "Yota" },
{ startNum: "9997643", operator: "Yota" },
{ startNum: "9997644", operator: "Yota" },
{ startNum: "9997645", operator: "Yota" },
{ startNum: "9997646", operator: "Yota" },
{ startNum: "9997647", operator: "Yota" },
{ startNum: "9997648", operator: "Yota" },
{ startNum: "9997649", operator: "Yota" },
{ startNum: "9997650", operator: "Yota" },
{ startNum: "9997651", operator: "Yota" },
{ startNum: "9997652", operator: "Yota" },
{ startNum: "9997653", operator: "Мегафон" },
{ startNum: "9997654", operator: "Мегафон" },
{ startNum: "9997655", operator: "Мегафон" },
{ startNum: "9997656", operator: "Мегафон" },
{ startNum: "9997657", operator: "Мегафон" },
{ startNum: "9997658", operator: "Мегафон" },
{ startNum: "9997659", operator: "Мегафон" },
{ startNum: "999766", operator: "Мегафон" },
{ startNum: "999767", operator: "Мегафон" },
{ startNum: "9997680", operator: "Мегафон" },
{ startNum: "9997681", operator: "Мегафон" },
{ startNum: "9997682", operator: "Мегафон" },
{ startNum: "9997683", operator: "Мегафон" },
{ startNum: "9997684", operator: "Мегафон" },
{ startNum: "9997685", operator: "Мегафон" },
{ startNum: "9997686", operator: "Мегафон" },
{ startNum: "9997687", operator: "Мегафон" },
{ startNum: "9997688", operator: "Мегафон" },
{ startNum: "9997689", operator: "Мегафон" },
{ startNum: "9997690", operator: "Мегафон" },
{ startNum: "9997691", operator: "Мегафон" },
{ startNum: "9997692", operator: "Мегафон" },
{ startNum: "9997693", operator: "Мегафон" },
{ startNum: "9997694", operator: "Мегафон" },
{ startNum: "9997695", operator: "Мегафон" },
{ startNum: "9997696", operator: "Мегафон" },
{ startNum: "9997697", operator: "Мегафон" },
{ startNum: "9997698", operator: "Yota" },
{ startNum: "9997699", operator: "Yota" },
{ startNum: "999770", operator: "Yota" },
{ startNum: "999771", operator: "Yota" },
{ startNum: "999772", operator: "Yota" },
{ startNum: "999773", operator: "Yota" },
{ startNum: "999774", operator: "Мегафон" },
{ startNum: "999775", operator: "Yota" },
{ startNum: "999776", operator: "Мегафон" },
{ startNum: "999777", operator: "МАТРИКС" },
{ startNum: "999778", operator: "Мегафон" },
{ startNum: "9997790", operator: "МАТРИКС" },
{ startNum: "9997791", operator: "МАТРИКС" },
{ startNum: "9997792", operator: "МАТРИКС" },
{ startNum: "9997793", operator: "МАТРИКС" },
{ startNum: "9997794", operator: "МАТРИКС" },
{ startNum: "9997795", operator: "МАТРИКС" },
{ startNum: "9997796", operator: "МАТРИКС" },
{ startNum: "9997797", operator: "МАТРИКС" },
{ startNum: "9997798", operator: "МАТРИКС" },
{ startNum: "9997799", operator: "Tele2" },
{ startNum: "9997800", operator: "Yota" },
{ startNum: "9997801", operator: "Yota" },
{ startNum: "9997802", operator: "Yota" },
{ startNum: "9997803", operator: "Yota" },
{ startNum: "9997804", operator: "Yota" },
{ startNum: "9997805", operator: "Yota" },
{ startNum: "9997806", operator: "Yota" },
{ startNum: "9997807", operator: "Yota" },
{ startNum: "9997808", operator: "Yota" },
{ startNum: "9997809", operator: "Yota" },
{ startNum: "999781", operator: "Yota" },
{ startNum: "999782", operator: "Yota" },
{ startNum: "999783", operator: "Yota" },
{ startNum: "9997840", operator: "Yota" },
{ startNum: "9997841", operator: "Yota" },
{ startNum: "9997842", operator: "Yota" },
{ startNum: "9997843", operator: "Yota" },
{ startNum: "9997844", operator: "Yota" },
{ startNum: "9997845", operator: "Yota" },
{ startNum: "9997846", operator: "Yota" },
{ startNum: "9997847", operator: "Yota" },
{ startNum: "9997848", operator: "Yota" },
{ startNum: "9997849", operator: "Yota" },
{ startNum: "9997850", operator: "Yota" },
{ startNum: "9997851", operator: "Yota" },
{ startNum: "9997852", operator: "Yota" },
{ startNum: "9997855", operator: "Yota" },
{ startNum: "9997856", operator: "Yota" },
{ startNum: "9997857", operator: "Yota" },
{ startNum: "9997858", operator: "Yota" },
{ startNum: "9997859", operator: "Yota" },
{ startNum: "999786", operator: "Yota" },
{ startNum: "999787", operator: "Yota" },
{ startNum: "999788", operator: "Yota" },
{ startNum: "9997890", operator: "Yota" },
{ startNum: "9997891", operator: "Yota" },
{ startNum: "9997892", operator: "Yota" },
{ startNum: "9997893", operator: "Yota" },
{ startNum: "9997894", operator: "Yota" },
{ startNum: "9997895", operator: "Yota" },
{ startNum: "9997896", operator: "Yota" },
{ startNum: "9997897", operator: "Yota" },
{ startNum: "9997898", operator: "Yota" },
{ startNum: "9997899", operator: "Yota" },
{ startNum: "9997900", operator: "Yota" },
{ startNum: "9997901", operator: "Yota" },
{ startNum: "9997902", operator: "Yota" },
{ startNum: "9997903", operator: "Yota" },
{ startNum: "9997904", operator: "Yota" },
{ startNum: "9997905", operator: "Yota" },
{ startNum: "9997906", operator: "Yota" },
{ startNum: "9997907", operator: "Yota" },
{ startNum: "9997908", operator: "Yota" },
{ startNum: "9997909", operator: "Yota" },
{ startNum: "999791", operator: "Yota" },
{ startNum: "9997920", operator: "Yota" },
{ startNum: "9997921", operator: "Yota" },
{ startNum: "9997922", operator: "Yota" },
{ startNum: "9997923", operator: "Yota" },
{ startNum: "9997924", operator: "Yota" },
{ startNum: "9997925", operator: "Yota" },
{ startNum: "9997926", operator: "Yota" },
{ startNum: "9997927", operator: "Yota" },
{ startNum: "9997928", operator: "Yota" },
{ startNum: "9997929", operator: "Yota" },
{ startNum: "999793", operator: "Yota" },
{ startNum: "999794", operator: "Yota" },
{ startNum: "9997950", operator: "Yota" },
{ startNum: "9997951", operator: "Yota" },
{ startNum: "9997952", operator: "Yota" },
{ startNum: "9997953", operator: "Yota" },
{ startNum: "9997954", operator: "Yota" },
{ startNum: "9997955", operator: "Yota" },
{ startNum: "9997956", operator: "Yota" },
{ startNum: "9997957", operator: "Yota" },
{ startNum: "9997958", operator: "Yota" },
{ startNum: "9997959", operator: "Yota" },
{ startNum: "999796", operator: "Yota" },
{ startNum: "9997970", operator: "Yota" },
{ startNum: "9997971", operator: "Yota" },
{ startNum: "9997972", operator: "Yota" },
{ startNum: "9997973", operator: "Yota" },
{ startNum: "9997974", operator: "Yota" },
{ startNum: "9997975", operator: "Yota" },
{ startNum: "9997976", operator: "Yota" },
{ startNum: "9997977", operator: "Yota" },
{ startNum: "9997978", operator: "Yota" },
{ startNum: "9997979", operator: "Yota" },
{ startNum: "999798", operator: "Yota" },
{ startNum: "9997990", operator: "Yota" },
{ startNum: "9997991", operator: "Yota" },
{ startNum: "9997992", operator: "Yota" },
{ startNum: "9997993", operator: "Yota" },
{ startNum: "9997994", operator: "Yota" },
{ startNum: "9997995", operator: "Yota" },
{ startNum: "9997996", operator: "Yota" },
{ startNum: "9997997", operator: "Yota" },
{ startNum: "9997998", operator: "Yota" },
{ startNum: "9997999", operator: "Yota" },
{ startNum: "9997853", operator: "Yota" },
{ startNum: "9997854", operator: "Yota" },
{ startNum: "99980", operator: "Yota" },
{ startNum: "99981", operator: "Yota" },
{ startNum: "99982", operator: "Yota" },
{ startNum: "99983", operator: "Yota" },
{ startNum: "99984", operator: "Yota" },
{ startNum: "99985", operator: "Yota" },
{ startNum: "99986", operator: "Yota" },
{ startNum: "99987", operator: "Yota" },
{ startNum: "99988", operator: "Tele2" },
{ startNum: "99989", operator: "Tele2" },
{ startNum: "99990", operator: "Tele2" },
{ startNum: "99991", operator: "Tele2" },
{ startNum: "99992", operator: "Tele2" },
{ startNum: "999930", operator: "ВТБ Мобайл" },
{ startNum: "999940", operator: "ВТБ Мобайл" },
{ startNum: "999941", operator: "ВТБ Мобайл" },
{ startNum: "999942", operator: "ВТБ Мобайл" },
{ startNum: "999943", operator: "ВТБ Мобайл" },
{ startNum: "999944", operator: "ВТБ Мобайл" },
{ startNum: "999945", operator: "ВТБ Мобайл" },
{ startNum: "999946", operator: "ВТБ Мобайл" },
{ startNum: "999947", operator: "ВТБ Мобайл" },
{ startNum: "999948", operator: "Сим Телеком" },
{ startNum: "999949", operator: "Мегафон" },
{ startNum: "99995", operator: "Сим Телеком" },
{ startNum: "99996", operator: "Yota" },
{ startNum: "99997", operator: "Yota" },
{ startNum: "99998", operator: "Yota" },
{ startNum: "99999", operator: "Yota" },

  /***************************************  901  ********************************************************* */

{ startNum: "901000", operator: "Tele2" },
{ startNum: "901001", operator: "Tele2" },
{ startNum: "901002", operator: "Tele2" },
{ startNum: "901003", operator: "Tele2" },
{ startNum: "901004", operator: "Tele2" },
{ startNum: "901005", operator: "Tele2" },
{ startNum: "901006", operator: "Tele2" },
{ startNum: "901007", operator: "Tele2" },
{ startNum: "901008", operator: "Tele2" },
{ startNum: "901009", operator: "Tele2" },
{ startNum: "901010", operator: "Tele2" },
{ startNum: "901011", operator: "Tele2" },
{ startNum: "901012", operator: "Tele2" },
{ startNum: "9010130",operator: "Tele2" },
{ startNum: "9010131",operator: "Tele2" },
{ startNum: "9010132", operator: "Tele2" },
{ startNum: "9010133", operator: "Tele2" },
{ startNum: "9010134", operator: "Tele2" },
{ startNum: "9010135", operator: "Tele2" },
{ startNum: "9010136", operator: "Tele2" },
{ startNum: "90101370",operator: "Tele2" },
{ startNum: "90101371", operator: "МиАТел" },
{ startNum: "90101372", operator: "МиАТел" },
{ startNum: "90101373", operator: "МиАТел" },
{ startNum: "90101374", operator: "МиАТел" },
{ startNum: "90101375", operator: "МиАТел" },
{ startNum: "90101376", operator: "МиАТел" },
{ startNum: "90101377", operator: "МиАТел" },
{ startNum: "90101378", operator: "МиАТел" },
{ startNum: "90101379", operator: "МиАТел" },
{ startNum: "9010138", operator: "МТТ" },
{ startNum: "9010139", operator: "МТТ" },
{ startNum: "901014", operator: "Tele2" },
{ startNum: "901015", operator: "Tele2" },
{ startNum: "901016", operator: "Tele2" },
{ startNum: "901017", operator: "Tele2" },
{ startNum: "901018", operator: "Tele2" },
{ startNum: "901019", operator: "Tele2" },
{ startNum: "901020", operator: "Tele2" },
{ startNum: "90102986", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90102987", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90102988", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90102989", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90102990", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90102991", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90102992", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90102993", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90102994", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90102995", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90102996", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90102997", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90102998", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90103", operator: "Tele2" },
{ startNum: "90104", operator: "Tele2" },
{ startNum: "90105", operator: "Tele2" },
{ startNum: "90106", operator: "Плинтрон" },
{ startNum: "901070",operator: "Плинтрон" },
{ startNum: "901071",operator: "Плинтрон" },
{ startNum: "901072",operator: "Плинтрон" },
{ startNum: "901073",operator: "Плинтрон" },
{ startNum: "901074",operator: "Плинтрон" },
{ startNum: "9010750", operator: "Частный" },
{ startNum: "9010751", operator: "Частный" },
{ startNum: "9010752", operator: "Частный" },
{ startNum: "9010753", operator: "Частный" },
{ startNum: "9010754", operator: "Частный" },
{ startNum: "9010755", operator: "Частный" },
{ startNum: "9010756", operator: "Частный" },
{ startNum: "9010757", operator: "Частный" },
{ startNum: "9010758", operator: "Частный" },
{ startNum: "9010759", operator: "Частный" },
{ startNum: "9010760", operator: "Частный" },
{ startNum: "9010761", operator: "Частный" },
{ startNum: "9010762", operator: "Частный" },
{ startNum: "9010763", operator: "Частный" },
{ startNum: "9010764", operator: "Частный" },
{ startNum: "9010777", operator: "МТТ" },
{ startNum: "9010778", operator: "Интернод" },
{ startNum: "9010779", operator: "Интернод" },
{ startNum: "90108", operator: "Tele2" },
{ startNum: "901090", operator: "Сим Телеком" },
{ startNum: "901091", operator: "Сим Телеком" },
{ startNum: "901092", operator: "Tele2" },
{ startNum: "901093", operator: "Tele2" },
{ startNum: "901094", operator: "Tele2" },
{ startNum: "901095", operator: "Tele2" },
{ startNum: "901096", operator: "Tele2" },
{ startNum: "901097", operator: "Tele2" },
{ startNum: "901098", operator: "Tele2" },
{ startNum: "901099", operator: "Tele2" },
{ startNum: "901100", operator: "Tele2" },
{ startNum: "901101", operator: "Tele2" },
{ startNum: "901102", operator: "Tele2" },
{ startNum: "901103", operator: "Tele2" },
{ startNum: "901104", operator: "Tele2" },
{ startNum: "901105", operator: "Tele2" },
{ startNum: "901106", operator: "Tele2" },
{ startNum: "901107", operator: "Tele2" },
{ startNum: "901108", operator: "МТТ" },
{ startNum: "901109", operator: "Tele2" },
{ startNum: "901110", operator: "Ростелеком" },
{ startNum: "901111", operator: "Tele2" },
{ startNum: "901112", operator: "Tele2" },
{ startNum: "901113", operator: "Tele2" },
{ startNum: "901114", operator: "Tele2" },
{ startNum: "901115", operator: "Tele2" },
{ startNum: "901116", operator: "МТТ" },
{ startNum: "901117", operator: "Tele2" },
{ startNum: "901118", operator: "Tele2" },
{ startNum: "901119", operator: "Tele2" },
{ startNum: "901120", operator: "Tele2" },
{ startNum: "901121", operator: "Tele2" },
{ startNum: "901122", operator: "Tele2" },
{ startNum: "901123", operator: "Tele2" },
{ startNum: "901124", operator: "Tele2" },
{ startNum: "901125", operator: "Tele2" },
{ startNum: "901126", operator: "Tele2" },
{ startNum: "901127", operator: "Tele2" },
{ startNum: "901128", operator: "Tele2" },
{ startNum: "901129", operator: "МТТ" },
{ startNum: "901130", operator: "Tele2" },
{ startNum: "9011310", operator: "Tele2" },
{ startNum: "9011311", operator: "Tele2" },
{ startNum: "9011312", operator: "Tele2" },
{ startNum: "9011313", operator: "Tele2" },
{ startNum: "9011314", operator: "Tele2" },
{ startNum: "9011315", operator: "Tele2" },
{ startNum: "9011316", operator: "Tele2" },
{ startNum: "90113164", operator: "ЦифраГрупп" },
{ startNum: "90113165", operator: "ЦифраГрупп" },
{ startNum: "90113166", operator: "ЦифраГрупп" },
{ startNum: "90113167", operator: "ЦифраГрупп" },
{ startNum: "90113168", operator: "ЦифраГрупп" },
{ startNum: "90113169", operator: "ЦифраГрупп" },
{ startNum: "9011317", operator: "МТТ" },
{ startNum: "9011318", operator: "МТТ" },
{ startNum: "9011319", operator: "МиАТел" },
{ startNum: "901132", operator: "Интернод" },
{ startNum: "901133", operator: "МТТ" },
{ startNum: "901134", operator: "Tele2" },
{ startNum: "901135", operator: "Tele2" },
{ startNum: "901136", operator: "Tele2" },
{ startNum: "901137", operator: "Tele2" },
{ startNum: "901138", operator: "Tele2" },
{ startNum: "901139", operator: "МТТ" },
{ startNum: "901140", operator: "Ростелеком" },
{ startNum: "901141", operator: "Ростелеком" },
{ startNum: "901142", operator: "МТТ" },
{ startNum: "901143", operator: "МТТ" },
{ startNum: "901144", operator: "Tele2" },
{ startNum: "901145", operator: "Tele2" },
{ startNum: "901146", operator: "МТТ" },
{ startNum: "9011470", operator: "Tele2" },
{ startNum: "9011471", operator: "Tele2" },
{ startNum: "9011472", operator: "Tele2" },
{ startNum: "9011473", operator: "Tele2" },
{ startNum: "9011474", operator: "Tele2" },
{ startNum: "9011475", operator: "Интернод" },
{ startNum: "9011476", operator: "Интернод" },
{ startNum: "9011477", operator: "Интернод" },
{ startNum: "9011478", operator: "Интернод" },
{ startNum: "9011479", operator: "Интернод" },
{ startNum: "901148", operator: "МТТ" },
{ startNum: "901149", operator: "Tele2" },
{ startNum: "901150", operator: "Tele2" },
{ startNum: "901151", operator: "Сбербанк-телеком" },
{ startNum: "901152", operator: "МТС" },
{ startNum: "901153", operator: "МТТ" },
{ startNum: "901154", operator: "Tele2" },
{ startNum: "901155", operator: "МТТ" },
{ startNum: "901156", operator: "Tele2" },
{ startNum: "901157", operator: "Tele2" },
{ startNum: "901158", operator: "Tele2" },
{ startNum: "901159", operator: "МТТ" },
{ startNum: "90116", operator: "Tele2" },
{ startNum: "90117", operator: "Tele2" },
{ startNum: "90118", operator: "Tele2" },
{ startNum: "90119", operator: "Tele2" },
{ startNum: "901200", operator: "Tele2" },
{ startNum: "901201", operator: "Tele2" },
{ startNum: "901202", operator: "МТТ" },
{ startNum: "901203", operator: "Ростелеком" },
{ startNum: "901204", operator: "Ростелеком" },
{ startNum: "901205", operator: "Tele2" },
{ startNum: "901206", operator: "Tele2" },
{ startNum: "901207", operator: "Tele2" },
{ startNum: "901208", operator: "Tele2" },
{ startNum: "901209", operator: "Tele2" },
{ startNum: "901210", operator: "Tele2" },
{ startNum: "901211", operator: "МТТ" },
{ startNum: "901212", operator: "Tele2" },
{ startNum: "901213", operator: "Tele2" },
{ startNum: "901214", operator: "Tele2" },
{ startNum: "901215", operator: "Tele2" },
{ startNum: "901216", operator: "Tele2" },
{ startNum: "901217", operator: "Tele2" },
{ startNum: "901218", operator: "Tele2" },
{ startNum: "901219", operator: "Tele2" },
{ startNum: "901220", operator: "Tele2" },
{ startNum: "901221", operator: "МТТ" },
{ startNum: "901222", operator: "МТТ" },
{ startNum: "901223", operator: "Tele2" },
{ startNum: "901224", operator: "Tele2" },
{ startNum: "901225", operator: "Tele2" },
{ startNum: "901226", operator: "Tele2" },
{ startNum: "901227", operator: "Tele2" },
{ startNum: "901228", operator: "Tele2" },
{ startNum: "901229", operator: "МТТ" },
{ startNum: "901230", operator: "Tele2" },
{ startNum: "901231", operator: "Tele2" },
{ startNum: "901232", operator: "Tele2" },
{ startNum: "901233", operator: "МТТ" },
{ startNum: "901234", operator: "Лайкамобайл" },
{ startNum: "901235", operator: "Tele2" },
{ startNum: "901235", operator: "Tele2" },
{ startNum: "901236", operator: "Tele2" },
{ startNum: "901237", operator: "Tele2" },
{ startNum: "901238", operator: "Tele2" },
{ startNum: "901239", operator: "Tele2" },
{ startNum: "901240", operator: "Tele2" },
{ startNum: "901241", operator: "Tele2" },
{ startNum: "901242", operator: "Tele2" },
{ startNum: "901243", operator: "Tele2" },
{ startNum: "901244", operator: "МТТ" },
{ startNum: "901245", operator: "МТТ" },
{ startNum: "901246", operator: "МТТ" },
{ startNum: "901247", operator: "МТТ" },
{ startNum: "901248", operator: "МТТ" },
{ startNum: "901249", operator: "МТТ" },
{ startNum: "901250", operator: "Tele2" },
{ startNum: "901251", operator: "Tele2" },
{ startNum: "901252", operator: "Tele2" },
{ startNum: "901253", operator: "Tele2" },
{ startNum: "901254", operator: "МТТ" },
{ startNum: "901255", operator: "МТТ" },
{ startNum: "901256", operator: "МТТ" },
{ startNum: "901257", operator: "МТТ" },
{ startNum: "901258", operator: "МТТ" },
{ startNum: "901259", operator: "МТТ" },
{ startNum: "901260", operator: "МТC" },
{ startNum: "901261", operator: "МТC" },
{ startNum: "901264", operator: "Tele2" },
{ startNum: "901262", operator: "Tele2" },
{ startNum: "901263", operator: "Tele2" },
{ startNum: "901265", operator: "Tele2" },
{ startNum: "901266", operator: "Tele2" },
{ startNum: "901267", operator: "Tele2" },
{ startNum: "901268", operator: "Tele2" },
{ startNum: "901269", operator: "Tele2" },
{ startNum: "90127", operator: "Tele2" },
{ startNum: "90128", operator: "Tele2" },
{ startNum: "90129", operator: "Tele2" },
{ startNum: "90130", operator: "Tele2" },
{ startNum: "90131", operator: "Tele2" },
{ startNum: "90132", operator: "Tele2" },
{ startNum: "90133", operator: "Tele2" },
{ startNum: "90134", operator: "Tele2" },
{ startNum: "90135", operator: "Tele2" },
{ startNum: "90136", operator: "Tele2" },
{ startNum: "90137", operator: "Tele2" },
{ startNum: "90138", operator: "Tele2" },
{ startNum: "90139", operator: "Tele2" },
{ startNum: "90140", operator: "Tele2" },
{ startNum: "901410", operator: "Tele2" },
{ startNum: "901411", operator: "МТТ" },
{ startNum: "901412", operator: "Tele2" },
{ startNum: "901413", operator: "Tele2" },
{ startNum: "901414", operator: "Tele2" },
{ startNum: "901415", operator: "МТС" },
{ startNum: "901416", operator: "МТС" },
{ startNum: "901417", operator: "Интернод" },
{ startNum: "901418", operator: "МТС" },
{ startNum: "901419", operator: "Tele2" },
{ startNum: "90142", operator: "Tele2" },
{ startNum: "90143", operator: "Tele2" },
{ startNum: "901440", operator: "МТС" },
{ startNum: "901441", operator: "МТС" },
{ startNum: "901442", operator: "МТС" },
{ startNum: "901443", operator: "МТТ" },
{ startNum: "901444", operator: "Tele2" },
{ startNum: "901445", operator: "Tele2" },
{ startNum: "901446", operator: "Tele2" },
{ startNum: "901447", operator: "МТТ" },
{ startNum: "901448", operator: "МТТ" },
{ startNum: "901449", operator: "Tele2" },
{ startNum: "90145", operator: "Tele2" },
{ startNum: "901460", operator: "Tele2" },
{ startNum: "901461", operator: "Tele2" },
{ startNum: "901462", operator: "Tele2" },
{ startNum: "901463", operator: "Tele2" },
{ startNum: "901464", operator: "Tele2" },
{ startNum: "901465", operator: "Tele2" },
{ startNum: "901466", operator: "Tele2" },
{ startNum: "901467", operator: "МТТ" },
{ startNum: "901468", operator: "Интернод" },
{ startNum: "901469", operator: "Интернод" },
{ startNum: "901470", operator: "Tele2" },
{ startNum: "901471", operator: "Tele2" },
{ startNum: "901472", operator: "Tele2" },
{ startNum: "901475", operator: "МТС" },
{ startNum: "901476", operator: "МТС" },
{ startNum: "901477", operator: "МТТ" },
{ startNum: "901478", operator: "МТС" },
{ startNum: "901479", operator: "Tele2" },
{ startNum: "901480", operator: "Tele2" },
{ startNum: "901481", operator: "Tele2" },
{ startNum: "901482", operator: "Tele2" },
{ startNum: "901483", operator: "Tele2" },
{ startNum: "901484", operator: "Tele2" },
{ startNum: "901485", operator: "Tele2" },
{ startNum: "901486", operator: "Tele2" },
{ startNum: "901487", operator: "Tele2" },
{ startNum: "901488", operator: "Tele2" },
{ startNum: "901489", operator: "Tele2" },
{ startNum: "901490", operator: "Tele2" },
{ startNum: "901491", operator: "Tele2" },
{ startNum: "901492", operator: "Tele2" },
{ startNum: "901493", operator: "Tele2" },
{ startNum: "901494", operator: "Tele2" },
{ startNum: "901495", operator: "Tele2" },
{ startNum: "901496", operator: "Tele2" },
{ startNum: "901497", operator: "Tele2" },
{ startNum: "901498", operator: "Tele2" },
{ startNum: "901499", operator: "Tele2" },
{ startNum: "9015", operator: "Tele2" },
{ startNum: "901600", operator: "Tele2" },
{ startNum: "901601", operator: "Tele2" },
{ startNum: "901602", operator: "Tele2" },
{ startNum: "901603", operator: "Tele2" },
{ startNum: "901604", operator: "Tele2" },
{ startNum: "901605", operator: "Tele2" },
{ startNum: "901606", operator: "Tele2" },
{ startNum: "901607", operator: "Tele2" },
{ startNum: "901608", operator: "Tele2" },
{ startNum: "901609", operator: "Tele2" },
{ startNum: "901610", operator: "Tele2" },
{ startNum: "901611", operator: "Tele2" },
{ startNum: "901612", operator: "Tele2" },
{ startNum: "901613", operator: "Tele2" },
{ startNum: "901614", operator: "Tele2" },
{ startNum: "901615", operator: "Tele2" },
{ startNum: "901616", operator: "Tele2" },
{ startNum: "901617", operator: "Tele2" },
{ startNum: "901618", operator: "Tele2" },
{ startNum: "901619", operator: "Tele2" },
{ startNum: "901620", operator: "Tele2" },
{ startNum: "901621", operator: "Tele2" },
{ startNum: "901622", operator: "МТТ" },
{ startNum: "901623", operator: "Tele2" },
{ startNum: "901624", operator: "Tele2" },
{ startNum: "901625", operator: "Tele2" },
{ startNum: "901626", operator: "Tele2" },
{ startNum: "901627", operator: "Tele2" },
{ startNum: "901628", operator: "Tele2" },
{ startNum: "901629", operator: "Tele2" },
{ startNum: "901630", operator: "Tele2" },
{ startNum: "901631", operator: "Tele2" },
{ startNum: "901632", operator: "Tele2" },
{ startNum: "901633", operator: "Tele2" },
{ startNum: "9016340", operator: "Tele2" },
{ startNum: "9016341", operator: "Tele2" },
{ startNum: "9016342", operator: "Tele2" },
{ startNum: "9016343", operator: "Tele2" },
{ startNum: "9016344", operator: "Tele2" },
{ startNum: "9016345", operator: "МиАТел" },
{ startNum: "9016346", operator: "МиАТел" },
{ startNum: "9016347", operator: "МиАТел" },
{ startNum: "9016348", operator: "МиАТел" },
{ startNum: "9016349", operator: "МиАТел" },
{ startNum: "901635", operator: "Tele2" },
{ startNum: "901636", operator: "Перенос" },
{ startNum: "901637", operator: "Перенос" },
{ startNum: "901638", operator: "Перенос" },
{ startNum: "901639", operator: "Перенос" },
{ startNum: "901630", operator: "Перенос" },
{ startNum: "90164", operator: "Tele2" },
{ startNum: "90165", operator: "Tele2" },
{ startNum: "90166", operator: "Tele2" },
{ startNum: "90167", operator: "Tele2" },
{ startNum: "90168", operator: "Tele2" },
{ startNum: "90169", operator: "Tele2" },
{ startNum: "9017", operator: "Tele2" },
{ startNum: "901800", operator: "Tele2" },
{ startNum: "901801", operator: "Tele2" },
{ startNum: "901802", operator: "Tele2" },
{ startNum: "901803", operator: "Tele2" },
{ startNum: "901804", operator: "Tele2" },
{ startNum: "901805", operator: "Tele2" },
{ startNum: "901806", operator: "Tele2" },
{ startNum: "901807", operator: "Tele2" },
{ startNum: "901808", operator: "МТТ" },
{ startNum: "901809", operator: "Ростелеком" },
{ startNum: "90181", operator: "МТС" },
{ startNum: "901820", operator: "Tele2" },
{ startNum: "901821", operator: "Tele2" },
{ startNum: "901822", operator: "Tele2" },
{ startNum: "90183", operator: "Лайкамобайл" },
{ startNum: "901840", operator: "Трастел" },
{ startNum: "901841", operator: "Трастел" },
{ startNum: "901842", operator: "Трастел" },
{ startNum: "901843", operator: "Перенос" },
{ startNum: "901844", operator: "Перенос" },
{ startNum: "901845", operator: "Перенос" },
{ startNum: "901846", operator: "Перенос" },
{ startNum: "901847", operator: "Tele2" },
{ startNum: "90185", operator: "Tele2" },
{ startNum: "90186", operator: "Tele2" },
{ startNum: "90187", operator: "Tele2" },
{ startNum: "90188", operator: "Tele2" },
{ startNum: "901890", operator: "Tele2" },
{ startNum: "901891", operator: "Tele2" },
{ startNum: "901892", operator: "Tele2" },
{ startNum: "901893", operator: "Tele2" },
{ startNum: "901894", operator: "Tele2" },
{ startNum: "901895", operator: "Tele2" },
{ startNum: "901896", operator: "Tele2" },
{ startNum: "901897", operator: "Tele2" },
{ startNum: "901898", operator: "Tele2" },
{ startNum: "901899", operator: "МТТ" },
{ startNum: "90190", operator: "Tele2" },
{ startNum: "901910", operator: "Ростелеком" },
{ startNum: "901911", operator: "Теле2" },
{ startNum: "901912", operator: "Лайкамобайл" },
{ startNum: "901913", operator: "Лайкамобайл" },
{ startNum: "901914", operator: "Лайкамобайл" },
{ startNum: "901915", operator: "Лайкамобайл" },
{ startNum: "901916", operator: "Лайкамобайл" },
{ startNum: "901917", operator: "Лайкамобайл" },
{ startNum: "901918", operator: "Лайкамобайл" },
{ startNum: "901919", operator: "Лайкамобайл" },
{ startNum: "901920", operator: "Лайкамобайл" },
{ startNum: "901921", operator: "Лайкамобайл" },
{ startNum: "901922", operator: "МТТ" },
{ startNum: "901923", operator: "Tele2" },
{ startNum: "901924", operator: "Лайкамобайл" },
{ startNum: "901925", operator: "Лайкамобайл" },
{ startNum: "901926", operator: "Лайкамобайл" },
{ startNum: "901927", operator: "Лайкамобайл" },
{ startNum: "901928", operator: "Лайкамобайл" },
{ startNum: "901929", operator: "Tele2" },
{ startNum: "901930", operator: "Tele2" },
{ startNum: "9019310", operator: "Газпром телеком" },
{ startNum: "9019311", operator: "Газпром телеком" },
{ startNum: "9019312", operator: "Газпром телеком" },
{ startNum: "9019313", operator: "Газпром телеком" },
{ startNum: "9019314", operator: "МТТ" },
{ startNum: "9019315", operator: "МТТ" },
{ startNum: "9019316", operator: "МТТ" },
{ startNum: "9019317", operator: "МТТ" },
{ startNum: "9019318", operator: "Газпром телеком/МТТ" },
{ startNum: "9019319", operator: "МиАТел" },
{ startNum: "901932", operator: "Tele2" },
{ startNum: "901933", operator: "Tele2" },
{ startNum: "901934", operator: "Tele2" },
{ startNum: "901935", operator: "Tele2" },
{ startNum: "901936", operator: "Tele2" },
{ startNum: "901937", operator: "Tele2" },
{ startNum: "901938", operator: "Tele2" },
{ startNum: "9019390", operator: "МТТ" },
{ startNum: "9019391", operator: "МТТ" },
{ startNum: "9019392", operator: "МТТ" },
{ startNum: "9019393", operator: "МТТ" },
{ startNum: "9019394", operator: "МТТ" },
{ startNum: "9019395", operator: "МТТ" },
{ startNum: "9019396", operator: "МТТ" },
{ startNum: "9019397", operator: "МТТ" },
{ startNum: "9019398", operator: "МТТ" },
{ startNum: "9019399", operator: "МТТ" },
{ startNum: "901940", operator: "Tele2" },
{ startNum: "901941", operator: "Tele2" },
{ startNum: "901942", operator: "Tele2" },
{ startNum: "901943", operator: "Tele2" },
{ startNum: "901944", operator: "Tele2" },
{ startNum: "901945", operator: "МТТ" },
{ startNum: "901946", operator: "МТТ" },
{ startNum: "901947", operator: "Tele2" },
{ startNum: "901949", operator: "Tele2" },
{ startNum: "901950", operator: "Tele2" },
{ startNum: "901952", operator: "МТС" },
{ startNum: "901954", operator: "Tele2" },
{ startNum: "901955", operator: "Tele2" },
{ startNum: "901956", operator: "Tele2" },
{ startNum: "901957", operator: "Tele2" },
{ startNum: "901958", operator: "Tele2" },
{ startNum: "901959", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "90196", operator: "Tele2" },
{ startNum: "90197", operator: "Tele2" },
{ startNum: "90198", operator: "Tele2" },
{ startNum: "90199", operator: "Tele2" },

/***************************************  902  ********************************************************* */

{ startNum: "90200", operator: "Tele2" },
{ startNum: "902010", operator: "Tele2" },
{ startNum: "902011", operator: "Tele2" },
{ startNum: "902012", operator: "Tele2" },
{ startNum: "902013", operator: "Tele2" },
{ startNum: "902014", operator: "Tele2" },
{ startNum: "902015", operator: "Tele2" },
{ startNum: "902016", operator: "Tele2" },
{ startNum: "902017", operator: "Tele2" },
{ startNum: "902018", operator: "Tele2" },
{ startNum: "902019", operator: "Tele2" },
{ startNum: "902020", operator: "Ростелеком" },
{ startNum: "9020210", operator: "Ростелеком" },
{ startNum: "9020211", operator: "Ростелеком" },
{ startNum: "9020212", operator: "Ростелеком" },
{ startNum: "9020213", operator: "Ростелеком" },
{ startNum: "9020214", operator: "Ростелеком" },
{ startNum: "9020215", operator: "Ростелеком" },
{ startNum: "9020216", operator: "Ростелеком" },
{ startNum: "9020217", operator: "Ростелеком" },
{ startNum: "9020218", operator: "Ростелеком" },
{ startNum: "9020219", operator: "Ростелеком" },
{ startNum: "902022", operator: "Ростелеком" },
{ startNum: "902023", operator: "Ростелеком" },
{ startNum: "902024", operator: "Ростелеком" },
{ startNum: "902025", operator: "Ростелеком" },
{ startNum: "902026", operator: "Ростелеком" },
{ startNum: "902027", operator: "Ростелеком" },
{ startNum: "902028", operator: "Ростелеком" },
{ startNum: "902029", operator: "Ростелеком" },
{ startNum: "902030", operator: "Ростелеком" },
{ startNum: "9020310", operator: "Ростелеком" },
{ startNum: "9020311", operator: "Ростелеком" },
{ startNum: "9020312", operator: "Ростелеком" },
{ startNum: "9020313", operator: "Ростелеком" },
{ startNum: "9020314", operator: "Ростелеком" },
{ startNum: "9020315", operator: "Ростелеком" },
{ startNum: "9020316", operator: "Ростелеком" },
{ startNum: "9020317", operator: "Ростелеком" },
{ startNum: "9020318", operator: "Ростелеком" },
{ startNum: "9020319", operator: "Ростелеком" },
{ startNum: "9020320", operator: "Ростелеком" },
{ startNum: "9020321", operator: "Ростелеком" },
{ startNum: "9020322", operator: "Ростелеком" },
{ startNum: "9020323", operator: "Ростелеком" },
{ startNum: "9020324", operator: "Ростелеком" },
{ startNum: "9020325", operator: "Ростелеком" },
{ startNum: "9020326", operator: "Ростелеком" },
{ startNum: "9020327", operator: "Ростелеком" },
{ startNum: "9020328", operator: "Ростелеком" },
{ startNum: "9020329", operator: "Ростелеком" },
{ startNum: "902033", operator: "Ростелеком" },
{ startNum: "9020340", operator: "Ростелеком" },
{ startNum: "9020341", operator: "Ростелеком" },
{ startNum: "9020342", operator: "Ростелеком" },
{ startNum: "9020343", operator: "Ростелеком" },
{ startNum: "9020344", operator: "Ростелеком" },
{ startNum: "9020345", operator: "Ростелеком" },
{ startNum: "9020346", operator: "Ростелеком" },
{ startNum: "9020347", operator: "Ростелеком" },
{ startNum: "9020348", operator: "Ростелеком" },
{ startNum: "9020349", operator: "Ростелеком" },
{ startNum: "9020350", operator: "Ростелеком" },
{ startNum: "9020351", operator: "Ростелеком" },
{ startNum: "9020352", operator: "Ростелеком" },
{ startNum: "9020353", operator: "Ростелеком" },
{ startNum: "9020354", operator: "Ростелеком" },
{ startNum: "9020355", operator: "Ростелеком" },
{ startNum: "9020356", operator: "Ростелеком" },
{ startNum: "9020357", operator: "Ростелеком" },
{ startNum: "9020358", operator: "Ростелеком" },
{ startNum: "9020359", operator: "Ростелеком" },
{ startNum: "9020360", operator: "Ростелеком" },
{ startNum: "9020361", operator: "Ростелеком" },
{ startNum: "9020362", operator: "Ростелеком" },
{ startNum: "9020363", operator: "Ростелеком" },
{ startNum: "9020364", operator: "Ростелеком" },
{ startNum: "9020365", operator: "Ростелеком" },
{ startNum: "9020366", operator: "Ростелеком" },
{ startNum: "9020367", operator: "Ростелеком" },
{ startNum: "9020368", operator: "Ростелеком" },
{ startNum: "9020369", operator: "Ростелеком" },
{ startNum: "902037", operator: "Ростелеком" },
{ startNum: "9020380", operator: "Ростелеком" },
{ startNum: "9020381", operator: "Ростелеком" },
{ startNum: "9020382", operator: "Ростелеком" },
{ startNum: "9020383", operator: "Ростелеком" },
{ startNum: "9020384", operator: "Ростелеком" },
{ startNum: "9020385", operator: "Ростелеком" },
{ startNum: "9020386", operator: "Ростелеком" },
{ startNum: "9020387", operator: "Ростелеком" },
{ startNum: "9020388", operator: "Ростелеком" },
{ startNum: "9020389", operator: "Ростелеком" },
{ startNum: "902039", operator: "Ростелеком" },
{ startNum: "90204", operator: "Tele2" },
{ startNum: "90205", operator: "Билайн" },
{ startNum: "90206", operator: "Билайн" },
{ startNum: "90207", operator: "Билайн" },
{ startNum: "90208", operator: "МТС" },
{ startNum: "90209", operator: "Tele2" },
{ startNum: "90210", operator: "Tele2" },
{ startNum: "90211", operator: "МЕГАФОН" },
{ startNum: "902120", operator: "Tele2" },
{ startNum: "902121", operator: "Tele2" },
{ startNum: "902122", operator: "Tele2" },
{ startNum: "902123", operator: "Tele2" },
{ startNum: "902124", operator: "Tele2" },
{ startNum: "902125", operator: "Tele2" },
{ startNum: "902126", operator: "Tele2" },
{ startNum: "902127", operator: "Tele2" },
{ startNum: "902128", operator: "Tele2" },
{ startNum: "902129", operator: "Tele2" },
{ startNum: "90213", operator: "Tele2" },
{ startNum: "902140", operator: "Tele2" },
{ startNum: "902141", operator: "Tele2" },
{ startNum: "902142", operator: "Tele2" },
{ startNum: "902143", operator: "Tele2" },
{ startNum: "902144", operator: "Tele2" },
{ startNum: "902145", operator: "Tele2" },
{ startNum: "902146", operator: "Tele2" },
{ startNum: "902147", operator: "Tele2" },
{ startNum: "902148", operator: "Tele2" },
{ startNum: "902149", operator: "Tele2" },
{ startNum: "902150", operator: "Tele2" },
{ startNum: "902151", operator: "Tele2" },
{ startNum: "902152", operator: "Tele2" },
{ startNum: "902153", operator: "Tele2" },
{ startNum: "902154", operator: "Tele2" },
{ startNum: "902155", operator: "Tele2" },
{ startNum: "902156", operator: "Tele2" },
{ startNum: "902157", operator: "Tele2" },
{ startNum: "902158", operator: "Tele2" },
{ startNum: "902159", operator: "Tele2" },
{ startNum: "90216", operator: "Tele2" },
{ startNum: "90217", operator: "Tele2" },
{ startNum: "902180", operator: "Tele2" },
{ startNum: "902181", operator: "Tele2" },
{ startNum: "902182", operator: "Tele2" },
{ startNum: "902183", operator: "Tele2" },
{ startNum: "902184", operator: "Tele2" },
{ startNum: "902185", operator: "Tele2" },
{ startNum: "902186", operator: "Tele2" },
{ startNum: "902187", operator: "Tele2" },
{ startNum: "902188", operator: "Tele2" },
{ startNum: "902189", operator: "Tele2" },
{ startNum: "90219", operator: "Tele2" },
{ startNum: "902200", operator: "МТС" },
{ startNum: "902201", operator: "Tele2" },
{ startNum: "902202", operator: "Tele2" },
{ startNum: "902203", operator: "МТС" },
{ startNum: "902204", operator: "МТС" },
{ startNum: "902205", operator: "МТС" },
{ startNum: "902206", operator: "МТС" },
{ startNum: "902207", operator: "МТС" },
{ startNum: "902208", operator: "МТС" },
{ startNum: "902209", operator: "МТС" },
{ startNum: "90221", operator: "Tele2" },
{ startNum: "90222", operator: "Мегафон" },
{ startNum: "902230", operator: "Tele2" },
{ startNum: "902231", operator: "Tele2" },
{ startNum: "902232", operator: "Tele2" },
{ startNum: "902233", operator: "Tele2" },
{ startNum: "902234", operator: "Tele2" },
{ startNum: "902235", operator: "Tele2" },
{ startNum: "902236", operator: "Tele2" },
{ startNum: "902237", operator: "Tele2" },
{ startNum: "902238", operator: "Tele2" },
{ startNum: "902239", operator: "Tele2" },
{ startNum: "902240", operator: "МЕГАФОН" },
{ startNum: "902241", operator: "МТС" },
{ startNum: "902242", operator: "МТС" },
{ startNum: "902243", operator: "МТС" },
{ startNum: "902244", operator: "Tele2" },
{ startNum: "902245", operator: "Tele2" },
{ startNum: "902246", operator: "Tele2" },
{ startNum: "902247", operator: "Tele2" },
{ startNum: "902248", operator: "Tele2" },
{ startNum: "902249", operator: "МЕГАФОН" },
{ startNum: "902250", operator: "Tele2" },
{ startNum: "902251", operator: "Tele2" },
{ startNum: "902252", operator: "Tele2" },
{ startNum: "902253", operator: "Tele2" },
{ startNum: "902254", operator: "Tele2" },
{ startNum: "902255", operator: "Tele2" },
{ startNum: "902256", operator: "Tele2" },
{ startNum: "902257", operator: "Tele2" },
{ startNum: "902258", operator: "Tele2" },
{ startNum: "902259", operator: "Tele2" },
{ startNum: "90226", operator: "Tele2" },
{ startNum: "90227", operator: "Tele2" },
{ startNum: "902280", operator: "Tele2" },
{ startNum: "902281", operator: "Tele2" },
{ startNum: "902282", operator: "Tele2" },
{ startNum: "902283", operator: "Tele2" },
{ startNum: "902284", operator: "Tele2" },
{ startNum: "902285", operator: "Tele2" },
{ startNum: "902286", operator: "Tele2" },
{ startNum: "902287", operator: "Tele2" },
{ startNum: "902288", operator: "Tele2" },
{ startNum: "902289", operator: "Tele2" },
{ startNum: "90229", operator: "МЕГАФОН" },
{ startNum: "90230", operator: "Tele2" },
{ startNum: "902310", operator: "Tele2" },
{ startNum: "902311", operator: "Tele2" },
{ startNum: "902312", operator: "Tele2" },
{ startNum: "902313", operator: "Tele2" },
{ startNum: "902314", operator: "Tele2" },
{ startNum: "902315", operator: "МТС" },
{ startNum: "902316", operator: "МТС" },
{ startNum: "902317", operator: "МТС" },
{ startNum: "902318", operator: "МТС" },
{ startNum: "902319", operator: "МТС" },
{ startNum: "902320", operator: "МЕГАФОН" },
{ startNum: "902321", operator: "МЕГАФОН" },
{ startNum: "902322", operator: "МЕГАФОН" },
{ startNum: "902323", operator: "МЕГАФОН" },
{ startNum: "902324", operator: "МЕГАФОН" },
{ startNum: "902325", operator: "Tele2" },
{ startNum: "902326", operator: "Tele2" },
{ startNum: "902327", operator: "МЕГАФОН" },
{ startNum: "902328", operator: "МЕГАФОН" },
{ startNum: "902329", operator: "Tele2" },
{ startNum: "902330", operator: "Мегафон" },
{ startNum: "902331", operator: "Мегафон" },
{ startNum: "902332", operator: "Мегафон" },
{ startNum: "902333", operator: "Мегафон" },
{ startNum: "902334", operator: "Мегафон" },
{ startNum: "902335", operator: "МЕГАФОН" },
{ startNum: "902336", operator: "МЕГАФОН" },
{ startNum: "902337", operator: "МЕГАФОН" },
{ startNum: "902338", operator: "МЕГАФОН" },
{ startNum: "902339", operator: "МЕГАФОН" },
{ startNum: "90234", operator: "МТС" },
{ startNum: "902350", operator: "МЕГАФОН" },
{ startNum: "902351", operator: "МЕГАФОН" },
{ startNum: "902352", operator: "МТС" },
{ startNum: "902353", operator: "МТС" },
{ startNum: "902354", operator: "МТС" },
{ startNum: "902355", operator: "Tele2" },
{ startNum: "902356", operator: "Tele2" },
{ startNum: "902357", operator: "Tele2" },
{ startNum: "902358", operator: "Tele2" },
{ startNum: "902359", operator: "МЕГАФОН" },
{ startNum: "902360", operator: "Tele2" },
{ startNum: "902361", operator: "Tele2" },
{ startNum: "902362", operator: "Tele2" },
{ startNum: "902363", operator: "Tele2" },
{ startNum: "902364", operator: "Tele2" },
{ startNum: "902365", operator: "Tele2" },
{ startNum: "902366", operator: "Tele2" },
{ startNum: "902367", operator: "Tele2" },
{ startNum: "902368", operator: "Tele2" },
{ startNum: "902369", operator: "Tele2" },
{ startNum: "90237", operator: "МЕГАФОН" },
{ startNum: "90238", operator: "Tele2" },
{ startNum: "90239", operator: "Tele2" },
{ startNum: "902400", operator: "Tele2" },
{ startNum: "902401", operator: "МТС" },
{ startNum: "902402", operator: "МТС" },
{ startNum: "902403", operator: "МТС" },
{ startNum: "902404", operator: "Tele2" },
{ startNum: "902405", operator: "Tele2" },
{ startNum: "902406", operator: "Tele2" },
{ startNum: "902407", operator: "Tele2" },
{ startNum: "902408", operator: "Tele2" },
{ startNum: "902409", operator: "Tele2" },
{ startNum: "902410", operator: "Tele2" },
{ startNum: "90242", operator: "Tele2" },
{ startNum: "90243", operator: "Tele2" },
{ startNum: "90244", operator: "Tele2" },
{ startNum: "90245", operator: "Tele2" },
{ startNum: "902460", operator: "МТТ" },
{ startNum: "902461", operator: "Tele2" },
{ startNum: "902462", operator: "Tele2" },
{ startNum: "902463", operator: "Tele2" },
{ startNum: "902464", operator: "Tele2" },
{ startNum: "902465", operator: "Tele2" },
{ startNum: "902466", operator: "Tele2" },
{ startNum: "902467", operator: "Tele2" },
{ startNum: "902468", operator: "Tele2" },
{ startNum: "902469", operator: "МТТ" },
{ startNum: "90247", operator: "Tele2" },
{ startNum: "90248", operator: "Tele2" },
{ startNum: "90249", operator: "МОТИВ" },
{ startNum: "902500", operator: "Tele2" },
{ startNum: "902501", operator: "Tele2" },
{ startNum: "902502", operator: "Tele2" },
{ startNum: "902503", operator: "Tele2" },
{ startNum: "902504", operator: "Tele2" },
{ startNum: "902505", operator: "Tele2" },
{ startNum: "902506", operator: "Tele2" },
{ startNum: "902507", operator: "Tele2" },
{ startNum: "902508", operator: "Tele2" },
{ startNum: "902509", operator: "Tele2" },
{ startNum: "902510", operator: "Tele2" },
{ startNum: "902511", operator: "Tele2" },
{ startNum: "902512", operator: "Tele2" },
{ startNum: "902513", operator: "Tele2" },
{ startNum: "902514", operator: "Tele2" },
{ startNum: "902515", operator: "Tele2" },
{ startNum: "902516", operator: "Tele2" },
{ startNum: "902517", operator: "Tele2" },
{ startNum: "902518", operator: "Tele2" },
{ startNum: "902519", operator: "Tele2" },
{ startNum: "90252", operator: "Билайн" },
{ startNum: "90253", operator: "Tele2" },
{ startNum: "90254", operator: "Tele2" },
{ startNum: "902550", operator: "Tele2" },
{ startNum: "902551", operator: "Tele2" },
{ startNum: "902552", operator: "Tele2" },
{ startNum: "902553", operator: "Билайн" },
{ startNum: "902554", operator: "Билайн" },
{ startNum: "902555", operator: "Билайн" },
{ startNum: "902556", operator: "Билайн" },
{ startNum: "902557", operator: "Билайн" },
{ startNum: "902558", operator: "Tele2" },
{ startNum: "902559", operator: "Билайн" },
{ startNum: "902560", operator: "Tele2" },
{ startNum: "902561", operator: "Tele2" },
{ startNum: "902562", operator: "Tele2" },
{ startNum: "902563", operator: "Tele2" },
{ startNum: "902564", operator: "Tele2" },
{ startNum: "902565", operator: "Tele2" },
{ startNum: "902566", operator: "Tele2" },
{ startNum: "902567", operator: "Tele2" },
{ startNum: "902568", operator: "Tele2" },
{ startNum: "902569", operator: "Tele2" },
{ startNum: "902570", operator: "Tele2" },
{ startNum: "902571", operator: "Tele2" },
{ startNum: "902572", operator: "Tele2" },
{ startNum: "902573", operator: "Tele2" },
{ startNum: "902574", operator: "Tele2" },
{ startNum: "902575", operator: "Tele2" },
{ startNum: "902576", operator: "Tele2" },
{ startNum: "902577", operator: "Tele2" },
{ startNum: "902578", operator: "Tele2" },
{ startNum: "902579", operator: "Tele2" },
{ startNum: "902580", operator: "Tele2" },
{ startNum: "902581", operator: "Tele2" },
{ startNum: "902582", operator: "Tele2" },
{ startNum: "902583", operator: "Tele2" },
{ startNum: "902584", operator: "Tele2" },
{ startNum: "902585", operator: "Tele2" },
{ startNum: "902586", operator: "Tele2" },
{ startNum: "902587", operator: "Tele2" },
{ startNum: "902588", operator: "Tele2" },
{ startNum: "902589", operator: "Tele2" },
{ startNum: "90259", operator: "МОТИВ" },
{ startNum: "90260", operator: "Tele2" },
{ startNum: "90261", operator: "Tele2" },
{ startNum: "90262", operator: "Tele2" },
{ startNum: "902621", operator: "Tele2" },
{ startNum: "902622", operator: "Tele2" },
{ startNum: "902623", operator: "Tele2" },
{ startNum: "902624", operator: "Tele2" },
{ startNum: "902625", operator: "Tele2" },
{ startNum: "902626", operator: "Tele2" },
{ startNum: "902627", operator: "Tele2" },
{ startNum: "902628", operator: "Tele2" },
{ startNum: "902629", operator: "Tele2" },
{ startNum: "90263", operator: "Tele2" },
{ startNum: "90264", operator: "Tele2" },
{ startNum: "90265", operator: "Tele2" },
{ startNum: "902660", operator: "МЕГАФОН" },
{ startNum: "902661", operator: "МЕГАФОН" },
{ startNum: "902662", operator: "МЕГАФОН" },
{ startNum: "902663", operator: "МЕГАФОН" },
{ startNum: "902664", operator: "Tele2" },
{ startNum: "902665", operator: "МЕГАФОН" },
{ startNum: "902666", operator: "Tele2" },
{ startNum: "902667", operator: "Tele2" },
{ startNum: "902668", operator: "Tele2" },
{ startNum: "902669", operator: "Tele2" },
{ startNum: "902670", operator: "Tele2" },
{ startNum: "902671", operator: "Tele2" },
{ startNum: "902672", operator: "Tele2" },
{ startNum: "902673", operator: "Tele2" },
{ startNum: "902674", operator: "Tele2" },
{ startNum: "902675", operator: "Tele2" },
{ startNum: "902676", operator: "Tele2" },
{ startNum: "902677", operator: "Tele2" },
{ startNum: "902678", operator: "Tele2" },
{ startNum: "902679", operator: "Tele2" },
{ startNum: "90268", operator: "Tele2" },
{ startNum: "902690", operator: "Tele2" },
{ startNum: "902691", operator: "Tele2" },
{ startNum: "902692", operator: "Tele2" },
{ startNum: "902693", operator: "Tele2" },
{ startNum: "902694", operator: "Tele2" },
{ startNum: "902695", operator: "Tele2" },
{ startNum: "902696", operator: "Tele2" },
{ startNum: "902697", operator: "Tele2" },
{ startNum: "902698", operator: "Tele2" },
{ startNum: "902699", operator: "Tele2" },
{ startNum: "90270", operator: "Tele2" },
{ startNum: "902710", operator: "Билайн" },
{ startNum: "9027110", operator: "ТМТ" },
{ startNum: "9027111", operator: "ТМТ" },
{ startNum: "9027112", operator: "ТМТ" },
{ startNum: "9027113", operator: "ТМТ" },
{ startNum: "9027114", operator: "ТМТ" },
{ startNum: "90271150", operator: "ТМТ" },
{ startNum: "90271151", operator: "ТМТ" },
{ startNum: "90271152", operator: "МиАТел" },
{ startNum: "90271153", operator: "ТМТ" },
{ startNum: "902712", operator: "МТС" },
{ startNum: "902713", operator: "МТС" },
{ startNum: "902714", operator: "МЕГАФОН" },
{ startNum: "902715", operator: "ТМТ" },
{ startNum: "902716", operator: "МЕГАФОН" },
{ startNum: "902717", operator: "Билайн" },
{ startNum: "902718", operator: "ТМТ" },
{ startNum: "902719", operator: "ТМТ" },
{ startNum: "90272", operator: "Tele2" },
{ startNum: "902730", operator: "Tele2" },
{ startNum: "902731", operator: "Tele2" },
{ startNum: "902732", operator: "Tele2" },
{ startNum: "902733", operator: "Tele2" },
{ startNum: "902734", operator: "Tele2" },
{ startNum: "902735", operator: "Tele2" },
{ startNum: "902736", operator: "Tele2" },
{ startNum: "902737", operator: "Tele2" },
{ startNum: "902738", operator: "Tele2" },
{ startNum: "902739", operator: "Tele2" },
{ startNum: "902740", operator: "Tele2" },
{ startNum: "902741", operator: "Tele2" },
{ startNum: "902742", operator: "Tele2" },
{ startNum: "902743", operator: "Tele2" },
{ startNum: "902744", operator: "Tele2" },
{ startNum: "902745", operator: "Tele2" },
{ startNum: "902746", operator: "МТС" },
{ startNum: "902747", operator: "МТС" },
{ startNum: "902748", operator: "МЕГАФОН" },
{ startNum: "902749", operator: "МЕГАФОН" },
{ startNum: "902750", operator: "Tele2" },
{ startNum: "902751", operator: "Tele2" },
{ startNum: "902752", operator: "Tele2" },
{ startNum: "902753", operator: "Tele2" },
{ startNum: "902754", operator: "Tele2" },
{ startNum: "902755", operator: "Tele2" },
{ startNum: "902756", operator: "Tele2" },
{ startNum: "902757", operator: "Tele2" },
{ startNum: "902758", operator: "Tele2" },
{ startNum: "902759", operator: "Tele2" },
{ startNum: "90276", operator: "Tele2" },
{ startNum: "90277", operator: "Tele2" },
{ startNum: "90278", operator: "Tele2" },
{ startNum: "90279", operator: "Tele2" },
{ startNum: "90280", operator: "Tele2" },
{ startNum: "902810", operator: "Tele2" },
{ startNum: "902811", operator: "Tele2" },
{ startNum: "902812", operator: "Tele2" },
{ startNum: "902813", operator: "Tele2" },
{ startNum: "902814", operator: "Tele2" },
{ startNum: "902815", operator: "Tele2" },
{ startNum: "902816", operator: "Tele2" },
{ startNum: "902817", operator: "Tele2" },
{ startNum: "902818", operator: "Tele2" },
{ startNum: "902819", operator: "Tele2" },
{ startNum: "902820", operator: "Tele2" },
{ startNum: "902821", operator: "Tele2" },
{ startNum: "902822", operator: "Tele2" },
{ startNum: "902823", operator: "Tele2" },
{ startNum: "902824", operator: "Tele2" },
{ startNum: "902825", operator: "Tele2" },
{ startNum: "902826", operator: "Tele2" },
{ startNum: "902827", operator: "Tele2" },
{ startNum: "902828", operator: "Tele2" },
{ startNum: "902829", operator: "Tele2" },
{ startNum: "90283", operator: "Tele2" },
{ startNum: "90284", operator: "Tele2" },
{ startNum: "902850", operator: "Tele2" },
{ startNum: "902851", operator: "Tele2" },
{ startNum: "902852", operator: "Tele2" },
{ startNum: "902853", operator: "Tele2" },
{ startNum: "902854", operator: "Tele2" },
{ startNum: "902855", operator: "Tele2" },
{ startNum: "902856", operator: "Tele2" },
{ startNum: "902857", operator: "Tele2" },
{ startNum: "902858", operator: "Tele2" },
{ startNum: "902859", operator: "Tele2" },
{ startNum: "90286", operator: "Tele2" },
{ startNum: "90287", operator: "МОТИВ" },
{ startNum: "90288", operator: "Tele2" },
{ startNum: "90289", operator: "Tele2" },
{ startNum: "90290", operator: "Tele2" },
{ startNum: "90291", operator: "Tele2" },
{ startNum: "90292", operator: "Tele2" },
{ startNum: "902930", operator: "Tele2" },
{ startNum: "902931", operator: "Tele2" },
{ startNum: "902932", operator: "Tele2" },
{ startNum: "902933", operator: "Tele2" },
{ startNum: "902934", operator: "Tele2" },
{ startNum: "902935", operator: "Tele2" },
{ startNum: "902936", operator: "Tele2" },
{ startNum: "902937", operator: "Tele2" },
{ startNum: "902938", operator: "Tele2" },
{ startNum: "902939", operator: "Tele2" },
{ startNum: "90294", operator: "Tele2" },
{ startNum: "902950", operator: "Tele2" },
{ startNum: "902951", operator: "Tele2" },
{ startNum: "902952", operator: "Tele2" },
{ startNum: "902953", operator: "МЕГАФОН" },
{ startNum: "902954", operator: "МЕГАФОН" },
{ startNum: "902955", operator: "МЕГАФОН" },
{ startNum: "902956", operator: "Tele2" },
{ startNum: "902957", operator: "Tele2" },
{ startNum: "902958", operator: "Tele2" },
{ startNum: "902959", operator: "Tele2" },
{ startNum: "90296", operator: "Tele2" },
{ startNum: "90297", operator: "Tele2" },
{ startNum: "902980", operator: "Tele2" },
{ startNum: "902981", operator: "Tele2" },
{ startNum: "902982", operator: "Tele2" },
{ startNum: "902983", operator: "Tele2" },
{ startNum: "902984", operator: "Tele2" },
{ startNum: "902985", operator: "Tele2" },
{ startNum: "902986", operator: "Tele2" },
{ startNum: "902987", operator: "Tele2" },
{ startNum: "902988", operator: "Tele2" },
{ startNum: "902989", operator: "Tele2" },
{ startNum: "902990", operator: "Tele2" },
{ startNum: "902991", operator: "Tele2" },
{ startNum: "902992", operator: "Tele2" },
{ startNum: "902993", operator: "МЕГАФОН" },
{ startNum: "902994", operator: "МЕГАФОН" },
{ startNum: "902995", operator: "МЕГАФОН" },
{ startNum: "902996", operator: "Tele2" },
{ startNum: "902997", operator: "Tele2" },
{ startNum: "902998", operator: "Tele2" },
{ startNum: "902999", operator: "Tele2" },
/***************************************  903  ********************************************************* */

{ startNum: "9030", operator: "Beeline" },
{ startNum: "9031", operator: "Beeline" },
{ startNum: "9032", operator: "Beeline" },
{ startNum: "9033", operator: "Beeline" },
{ startNum: "9034", operator: "Beeline" },
{ startNum: "9035", operator: "Beeline" },
{ startNum: "9036", operator: "Beeline" },
{ startNum: "9037", operator: "Beeline" },
{ startNum: "9038", operator: "Beeline" },
{ startNum: "9039", operator: "Beeline" },
/***************************************  904  ********************************************************* */

{ startNum: "9040", operator: "Tele2" },
{ startNum: "9041", operator: "Tele2" },
{ startNum: "9042", operator: "Tele2" },
{ startNum: "90430", operator: "Tele2" },
{ startNum: "90432", operator: "Tele2" },
{ startNum: "90433", operator: "Tele2" },
{ startNum: "90434", operator: "Tele2" },
{ startNum: "90435", operator: "Tele2" },
{ startNum: "90436", operator: "Tele2" },
{ startNum: "90437", operator: "Tele2" },
{ startNum: "90438", operator: "МОТИВ" },
{ startNum: "90439", operator: "Tele2" },
{ startNum: "9044", operator: "Tele2" },
{ startNum: "90450", operator: "Tele2" },
{ startNum: "90451", operator: "Tele2" },
{ startNum: "90452", operator: "Tele2" },
{ startNum: "90453", operator: "Tele2" },
{ startNum: "90454", operator: "МОТИВ" },
{ startNum: "90455", operator: "Tele2" },
{ startNum: "90456", operator: "МТТ" },
{ startNum: "90457", operator: "Tele2" },
{ startNum: "90458", operator: "Tele2" },
{ startNum: "90459", operator: "Tele2" },
{ startNum: "9046", operator: "Tele2" },
{ startNum: "904700", operator: "Beeline" },
{ startNum: "904701", operator: "Beeline" },
{ startNum: "904702", operator: "Beeline" },
{ startNum: "904703", operator: "Beeline" },
{ startNum: "904704", operator: "Beeline" },
{ startNum: "904705", operator: "Beeline" },
{ startNum: "904706", operator: "Beeline" },
{ startNum: "904707", operator: "Beeline" },
{ startNum: "904708", operator: "Мегафон" },
{ startNum: "904709", operator: "Мегафон" },
{ startNum: "904710", operator: "Мегафон" },
{ startNum: "904711", operator: "ТМТ" },
{ startNum: "904712", operator: "ТМТ" },
{ startNum: "904713", operator: "ТМТ" },
{ startNum: "904714", operator: "ТМТ" },
{ startNum: "904715", operator: "ТМТ" },
{ startNum: "904716", operator: "ТМТ" },
{ startNum: "904717", operator: "ТМТ" },
{ startNum: "904718", operator: "ТМТ" },
{ startNum: "904719", operator: "ТМТ" },
{ startNum: "90472", operator: "Мегафон" },
{ startNum: "904730", operator: "Мегафон" },
{ startNum: "904731", operator: "Мегафон" },
{ startNum: "904732", operator: "Мегафон" },
{ startNum: "904733", operator: "Мегафон" },
{ startNum: "904734", operator: "Мегафон" },
{ startNum: "904735", operator: "МТС" },
{ startNum: "904736", operator: "МТС" },
{ startNum: "904737", operator: "МТС" },
{ startNum: "904738", operator: "МТС" },
{ startNum: "904739", operator: "МТС" },
{ startNum: "90474", operator: "Мегафон" },
{ startNum: "90475", operator: "Tele2" },
{ startNum: "90476", operator: "Tele2" },
{ startNum: "90477", operator: "Tele2" },
{ startNum: "90478", operator: "Tele2" },
{ startNum: "90479", operator: "Tele2" },
{ startNum: "9048", operator: "Tele2" },
{ startNum: "90490", operator: "Tele2" },
{ startNum: "90491", operator: "Tele2" },
{ startNum: "90492", operator: "Tele2" },
{ startNum: "90493", operator: "Tele2" },
{ startNum: "90494", operator: "Tele2" },
{ startNum: "904950", operator: "МТТ" },
{ startNum: "904951", operator: "МТТ" },
{ startNum: "904955", operator: "МТТ" },
{ startNum: "904956", operator: "МТТ" },
{ startNum: "904957", operator: "МТТ" },
{ startNum: "904958", operator: "МТТ" },
{ startNum: "904959", operator: "МТТ" },
{ startNum: "90496", operator: "Tele2" },
{ startNum: "90497", operator: "Tele2" },
{ startNum: "90498", operator: "МОТИВ" },
{ startNum: "90499", operator: "Tele2" },
/***************************************  905  ********************************************************* */


{ startNum: "9050", operator: "Beeline" },
{ startNum: "9051", operator: "Beeline" },
{ startNum: "9052", operator: "Beeline" },
{ startNum: "9053", operator: "Beeline" },
{ startNum: "9054", operator: "Beeline" },
{ startNum: "9055", operator: "Beeline" },
{ startNum: "9056", operator: "Beeline" },
{ startNum: "9057", operator: "Beeline" },
{ startNum: "9058", operator: "Beeline" },
{ startNum: "9059", operator: "Beeline" },
/***************************************  906  ********************************************************* */


{ startNum: "9060", operator: "Beeline" },
{ startNum: "9061", operator: "Beeline" },
{ startNum: "9062", operator: "Beeline" },
{ startNum: "9063", operator: "Beeline" },
{ startNum: "9064", operator: "Beeline" },
{ startNum: "9065", operator: "Beeline" },
{ startNum: "9066", operator: "Beeline" },
{ startNum: "9067", operator: "Beeline" },
{ startNum: "9068", operator: "Beeline" },
{ startNum: "9069", operator: "Beeline" },
/***************************************  909  ********************************************************* */

{ startNum: "9090", operator: "Beeline" },
{ startNum: "9091", operator: "Beeline" },
{ startNum: "9092", operator: "Beeline" },
{ startNum: "9093", operator: "Beeline" },
{ startNum: "9094", operator: "Beeline" },
{ startNum: "9095", operator: "Beeline" },
{ startNum: "9096", operator: "Beeline" },
{ startNum: "9097", operator: "Beeline" },
{ startNum: "9098", operator: "Beeline" },
{ startNum: "9099", operator: "Beeline" },
/***************************************  910  ********************************************************* */

{ startNum: "910000", operator: "МТС" },
{ startNum: "91001", operator: "МТС" },
{ startNum: "91002", operator: "МТС" },
{ startNum: "91003", operator: "МТС" },
{ startNum: "910041", operator: "МТС" },
{ startNum: "910042", operator: "МТС" },
{ startNum: "910043", operator: "МТС" },
{ startNum: "910044", operator: "МТС" },
{ startNum: "910045", operator: "МТС" },
{ startNum: "910046", operator: "МТС" },
{ startNum: "910047", operator: "МТС" },
{ startNum: "910048", operator: "МТС" },
{ startNum: "9100490", operator: "МТС" },
{ startNum: "9100491", operator: "МТС" },
{ startNum: "9100492", operator: "МТС" },
{ startNum: "9100493", operator: "МТС" },
{ startNum: "9100494", operator: "МТС" },
{ startNum: "9100495", operator: "МТС" },
{ startNum: "9100496", operator: "ООО ИК СИБИНТЕК	" },
{ startNum: "91004970", operator: "ООО ИК СИБИНТЕК	" },
{ startNum: "91004971", operator: "ООО ИК СИБИНТЕК	" },
{ startNum: "91004972", operator: "ООО ИК СИБИНТЕК	" },
{ startNum: "91004973", operator: "ООО ИК СИБИНТЕК	" },
{ startNum: "91004974", operator: "ООО ИК СИБИНТЕК	" },
{ startNum: "91004975", operator: "МТС" },
{ startNum: "91004976", operator: "МТС" },
{ startNum: "91004977", operator: "МТС" },
{ startNum: "91004978", operator: "МТС" },
{ startNum: "91004979", operator: "МТС" },
{ startNum: "9100498", operator: "МТС" },
{ startNum: "9100499", operator: "МТС" },
{ startNum: "91005", operator: "МТС" },
{ startNum: "91006", operator: "МТС" },
{ startNum: "91007", operator: "МТС" },
{ startNum: "91008", operator: "МТС" },
{ startNum: "91009", operator: "МТС" },
{ startNum: "91000", operator: "МТС" },
{ startNum: "9101", operator: "МТС" },
{ startNum: "9102", operator: "МТС" },
{ startNum: "9103", operator: "МТС" },
{ startNum: "9104", operator: "МТС" },
{ startNum: "9105", operator: "МТС" },
{ startNum: "9106", operator: "МТС" },
{ startNum: "9107", operator: "МТС" },
{ startNum: "9108", operator: "МТС" },
{ startNum: "9109", operator: "МТС" },
/***************************************  911  ********************************************************* */

{ startNum: "9110", operator: "МТС" },
{ startNum: "9111", operator: "МТС" },
{ startNum: "9112", operator: "МТС" },
{ startNum: "9113", operator: "МТС" },
{ startNum: "9114", operator: "МТС" },
{ startNum: "9115", operator: "МТС" },
{ startNum: "9116", operator: "МТС" },
{ startNum: "9117", operator: "МТС" },
{ startNum: "9118", operator: "МТС" },
{ startNum: "9119", operator: "МТС" },
/***************************************  912  ********************************************************* */

{ startNum: "912000", operator: "МТС" },
{ startNum: "912001", operator: "Ростелеком" },
{ startNum: "912002", operator: "Ростелеком" },
{ startNum: "912003", operator: "МТС" },
{ startNum: "912004", operator: "МТС" },
{ startNum: "912005", operator: "МТС" },
{ startNum: "912006", operator: "МТС" },
{ startNum: "912007", operator: "МТС" },
{ startNum: "912008", operator: "МТС" },
{ startNum: "912009", operator: "МТС" },
{ startNum: "91201", operator: "МТС" },
{ startNum: "91202", operator: "МТС" },
{ startNum: "91203", operator: "МТС" },
{ startNum: "91204", operator: "МТС" },
{ startNum: "91205", operator: "МТС" },
{ startNum: "91206", operator: "МТС" },
{ startNum: "912070", operator: "МТС" },
{ startNum: "912071", operator: "МТС" },
{ startNum: "912072", operator: "МТС" },
{ startNum: "912073", operator: "МТС" },
{ startNum: "912074", operator: "МТС" },
{ startNum: "912075", operator: "МТС" },
{ startNum: "9120760", operator: "МТС" },
{ startNum: "9120761", operator: "МТС" },
{ startNum: "9120762", operator: "МТС" },
{ startNum: "9120763", operator: "МТС" },
{ startNum: "9120764", operator: "МТС" },
{ startNum: "9120765", operator: "МТС" },
{ startNum: "9120766", operator: "МТС" },
{ startNum: "9120767", operator: "МиАТел" },
{ startNum: "9120768", operator: "МиАТел" },
{ startNum: "9120769", operator: "МиАТел" },
{ startNum: "912077", operator: "МТС" },
{ startNum: "912078", operator: "МТС" },
{ startNum: "912079", operator: "МТС" },
{ startNum: "91208", operator: "МТС" },
{ startNum: "912090", operator: "МТТ" },
{ startNum: "912091", operator: "МТТ" },
{ startNum: "912092", operator: "МТТ" },
{ startNum: "912093", operator: "МТС" },
{ startNum: "912094", operator: "МТС" },
{ startNum: "912095", operator: "МТС" },
{ startNum: "912096", operator: "МТТ" },
{ startNum: "912097", operator: "МТТ" },
{ startNum: "912098", operator: "МТТ" },
{ startNum: "912099", operator: "МТТ" },
{ startNum: "9121", operator: "МТС" },
{ startNum: "9122", operator: "МТС" },
{ startNum: "9123", operator: "МТС" },
{ startNum: "9124", operator: "МТС" },
{ startNum: "9125", operator: "МТС" },
{ startNum: "9126", operator: "МТС" },
{ startNum: "9127", operator: "МТС" },
{ startNum: "9128", operator: "МТС" },
{ startNum: "9129", operator: "МТС" },
/***************************************  913 ********************************************************* */

{ startNum: "9130", operator: "МТС" },
{ startNum: "9131", operator: "МТС" },
{ startNum: "9132", operator: "МТС" },
{ startNum: "9133", operator: "МТС" },
{ startNum: "9134", operator: "МТС" },
{ startNum: "9135", operator: "МТС" },
{ startNum: "9136", operator: "МТС" },
{ startNum: "9137", operator: "МТС" },
{ startNum: "9138", operator: "МТС" },
{ startNum: "9139", operator: "МТС" },
/***************************************  914 ********************************************************* */

{ startNum: "9140", operator: "МТС" },
{ startNum: "9141", operator: "МТС" },
{ startNum: "9142", operator: "МТС" },
{ startNum: "9143", operator: "МТС" },
{ startNum: "9144", operator: "МТС" },
{ startNum: "91450", operator: "МТС" },
{ startNum: "91451", operator: "МТС" },
{ startNum: "91452", operator: "МТС" },
{ startNum: "914530", operator: "МТС" },
{ startNum: "914531", operator: "МТС" },
{ startNum: "914532", operator: "МТС" },
{ startNum: "914533", operator: "МТС" },
{ startNum: "914534", operator: "МТС" },
{ startNum: "914535", operator: "МТС" },
{ startNum: "914536", operator: "МТС" },
{ startNum: "914537", operator: "МТС" },
{ startNum: "914538", operator: "МТС" },
{ startNum: "9145390", operator: "МТС" },
{ startNum: "9145391", operator: "МТС" },
{ startNum: "9145392", operator: "МТС" },
{ startNum: "9145393", operator: "МТТ" },
{ startNum: "9145393", operator: "МТТ" },
{ startNum: "9145394", operator: "МТТ" },
{ startNum: "9145395", operator: "МТТ" },
{ startNum: "9145396", operator: "МТТ" },
{ startNum: "9145397", operator: "МТТ" },
{ startNum: "9145398", operator: "МТТ" },
{ startNum: "9145399", operator: "МТТ" },
{ startNum: "91454", operator: "МТС" },
{ startNum: "91455", operator: "МТС" },
{ startNum: "91456", operator: "МТС" },
{ startNum: "91457", operator: "МТС" },
{ startNum: "91458", operator: "МТС" },
{ startNum: "91459", operator: "МТС" },
{ startNum: "9146", operator: "МТС" },
{ startNum: "9147", operator: "МТС" },
{ startNum: "9148", operator: "МТС" },
{ startNum: "9149", operator: "МТС" },
/***************************************  915 ********************************************************* */

{ startNum: "9150", operator: "МТС" },
{ startNum: "9151", operator: "МТС" },
{ startNum: "9152", operator: "МТС" },
{ startNum: "9153", operator: "МТС" },
{ startNum: "9154", operator: "МТС" },
{ startNum: "9155", operator: "МТС" },
{ startNum: "9156", operator: "МТС" },
{ startNum: "9157", operator: "МТС" },
{ startNum: "9158", operator: "МТС" },
{ startNum: "9159", operator: "МТС" },
/***************************************  916 ********************************************************* */

{ startNum: "9160", operator: "МТС" },
{ startNum: "9161", operator: "МТС" },
{ startNum: "9162", operator: "МТС" },
{ startNum: "9163", operator: "МТС" },
{ startNum: "9164", operator: "МТС" },
{ startNum: "9165", operator: "МТС" },
{ startNum: "9166", operator: "МТС" },
{ startNum: "9167", operator: "МТС" },
{ startNum: "9168", operator: "МТС" },
{ startNum: "9169", operator: "МТС" },
/***************************************  917 ********************************************************* */

{ startNum: "9170", operator: "МТС" },
{ startNum: "9171", operator: "МТС" },
{ startNum: "9172", operator: "МТС" },
{ startNum: "9173", operator: "МТС" },
{ startNum: "9174", operator: "МТС" },
{ startNum: "9175", operator: "МТС" },
{ startNum: "9176", operator: "МТС" },
{ startNum: "9177", operator: "МТС" },
{ startNum: "9178", operator: "МТС" },
{ startNum: "9179", operator: "МТС" },

/***************************************  918 ********************************************************* */

{ startNum: "9180", operator: "МТС" },
{ startNum: "9181", operator: "МТС" },
{ startNum: "9182", operator: "МТС" },
{ startNum: "9183", operator: "МТС" },
{ startNum: "9184", operator: "МТС" },
{ startNum: "9185", operator: "МТС" },
{ startNum: "9186", operator: "МТС" },
{ startNum: "9187", operator: "МТС" },
{ startNum: "9188", operator: "МТС" },
{ startNum: "9189", operator: "МТС" },
/***************************************  919 ********************************************************* */

{ startNum: "9190", operator: "МТС" },
{ startNum: "91910", operator: "МТС" },
{ startNum: "91911", operator: "МТС" },
{ startNum: "91912", operator: "МТС" },
{ startNum: "919130", operator: "МТС" },
{ startNum: "919131", operator: "МТС" },
{ startNum: "919132", operator: "МТС" },
{ startNum: "919133", operator: "МТС" },
{ startNum: "919134", operator: "МТС" },
{ startNum: "919135", operator: "МТС" },
{ startNum: "919136", operator: "МТС" },
{ startNum: "919137", operator: "МТТ" },
{ startNum: "919138", operator: "МТС" },
{ startNum: "919139", operator: "МТС" },
{ startNum: "91914", operator: "МТС" },
{ startNum: "91915", operator: "МТС" },
{ startNum: "91916", operator: "МТС" },
{ startNum: "91917", operator: "МТС" },
{ startNum: "91918", operator: "МТС" },
{ startNum: "91919", operator: "МТС" },
{ startNum: "9192", operator: "МТС" },
{ startNum: "9193", operator: "МТС" },
{ startNum: "9194", operator: "МТС" },
{ startNum: "9195", operator: "МТС" },
{ startNum: "9196", operator: "МТС" },
{ startNum: "9197", operator: "МТС" },
{ startNum: "9198", operator: "МТС" },
{ startNum: "9199", operator: "МТС" },
/***************************************  920  ********************************************************* */

{ startNum: "92000", operator: "Мегафон" },
{ startNum: "92001", operator: "Мегафон" },
{ startNum: "92002", operator: "Мегафон" },
{ startNum: "92003", operator: "Мегафон" },
{ startNum: "92004", operator: "Мегафон" },
{ startNum: "92005", operator: "Мегафон" },
{ startNum: "92006", operator: "Мегафон" },
{ startNum: "92007", operator: "Мегафон" },
{ startNum: "92008", operator: "Мегафон" },
{ startNum: "92009", operator: "Мегафон" },
{ startNum: "920100", operator: "Мегафон" },
{ startNum: "920101", operator: "Мегафон" },
{ startNum: "920102", operator: "Мегафон" },
{ startNum: "920103", operator: "Мегафон" },
{ startNum: "920104", operator: "Мегафон" },
{ startNum: "920105", operator: "Мегафон" },
{ startNum: "920106", operator: "Мегафон" },
{ startNum: "920107", operator: "Мегафон" },
{ startNum: "920108", operator: "Мегафон" },
{ startNum: "920109", operator: "Мегафон" },
{ startNum: "920110", operator: "Мегафон" },
{ startNum: "920111", operator: "Мегафон" },
{ startNum: "920112", operator: "Мегафон" },
{ startNum: "920113", operator: "Мегафон" },
{ startNum: "920114", operator: "Мегафон" },
{ startNum: "920115", operator: "Мегафон" },
{ startNum: "920116", operator: "Мегафон" },
{ startNum: "920117", operator: "Мегафон" },
{ startNum: "920118", operator: "Мегафон" },
{ startNum: "920119", operator: "Мегафон" },
{ startNum: "92020", operator: "Мегафон" },
{ startNum: "92021", operator: "Мегафон" },
{ startNum: "92022", operator: "Мегафон" },
{ startNum: "92023", operator: "Мегафон" },
{ startNum: "92024", operator: "Мегафон" },
{ startNum: "92025", operator: "Мегафон" },
{ startNum: "92026", operator: "Мегафон" },
{ startNum: "92027", operator: "Мегафон" },
{ startNum: "92028", operator: "Мегафон" },
{ startNum: "92029", operator: "Мегафон" },
{ startNum: "92030", operator: "Мегафон" },
{ startNum: "92031", operator: "Мегафон" },
{ startNum: "92032", operator: "Мегафон" },
{ startNum: "92033", operator: "Мегафон" },
{ startNum: "92034", operator: "Мегафон" },
{ startNum: "92035", operator: "Мегафон" },
{ startNum: "92036", operator: "Мегафон" },
{ startNum: "92037", operator: "Мегафон" },
{ startNum: "92038", operator: "Мегафон" },
{ startNum: "92039", operator: "Мегафон" },
{ startNum: "92040", operator: "Мегафон" },
{ startNum: "92041", operator: "Мегафон" },
{ startNum: "92042", operator: "Мегафон" },
{ startNum: "92043", operator: "Мегафон" },
{ startNum: "92044", operator: "Мегафон" },
{ startNum: "92045", operator: "Мегафон" },
{ startNum: "92046", operator: "Мегафон" },
{ startNum: "92047", operator: "Мегафон" },
{ startNum: "92048", operator: "Мегафон" },
{ startNum: "92049", operator: "Мегафон" },
{ startNum: "92050", operator: "Мегафон" },
{ startNum: "92051", operator: "Мегафон" },
{ startNum: "92052", operator: "Мегафон" },
{ startNum: "92053", operator: "Мегафон" },
{ startNum: "92054", operator: "Мегафон" },
{ startNum: "92055", operator: "Мегафон" },
{ startNum: "92056", operator: "Мегафон" },
{ startNum: "92057", operator: "Мегафон" },
{ startNum: "92058", operator: "Мегафон" },
{ startNum: "92059", operator: "Мегафон" },
{ startNum: "92060", operator: "Мегафон" },
{ startNum: "92061", operator: "Мегафон" },
{ startNum: "92062", operator: "Мегафон" },
{ startNum: "92063", operator: "Мегафон" },
{ startNum: "92064", operator: "Мегафон" },
{ startNum: "92065", operator: "Мегафон" },
{ startNum: "92066", operator: "Мегафон" },
{ startNum: "92067", operator: "Мегафон" },
{ startNum: "92068", operator: "Мегафон" },
{ startNum: "92069", operator: "Мегафон" },
{ startNum: "92070", operator: "Мегафон" },
{ startNum: "92071", operator: "Мегафон" },
{ startNum: "92072", operator: "Мегафон" },
{ startNum: "92073", operator: "Мегафон" },
{ startNum: "92074", operator: "Мегафон" },
{ startNum: "92075", operator: "Мегафон" },
{ startNum: "92076", operator: "Мегафон" },
{ startNum: "92077", operator: "Мегафон" },
{ startNum: "92078", operator: "Мегафон" },
{ startNum: "92079", operator: "Мегафон" },
{ startNum: "92080", operator: "Мегафон" },
{ startNum: "92081", operator: "Мегафон" },
{ startNum: "92082", operator: "Мегафон" },
{ startNum: "92083", operator: "Мегафон" },
{ startNum: "92084", operator: "Мегафон" },
{ startNum: "92085", operator: "Мегафон" },
{ startNum: "92086", operator: "Мегафон" },
{ startNum: "92087", operator: "Мегафон" },
{ startNum: "92088", operator: "Мегафон" },
{ startNum: "92089", operator: "Мегафон" },
{ startNum: "92090", operator: "Мегафон" },
{ startNum: "92091", operator: "Мегафон" },
{ startNum: "92092", operator: "Мегафон" },
{ startNum: "92093", operator: "Мегафон" },
{ startNum: "92094", operator: "Мегафон" },
{ startNum: "92095", operator: "Мегафон" },
{ startNum: "92096", operator: "Мегафон" },
{ startNum: "92097", operator: "Мегафон" },
{ startNum: "92098", operator: "Мегафон" },
{ startNum: "92099", operator: "Мегафон" },

/***************************************  921  ********************************************************* */

{ startNum: "921000", operator: "Мегафон" },
{ startNum: "921001", operator: "Мегафон" },
{ startNum: "921002", operator: "Мегафон" },
{ startNum: "921003", operator: "Мегафон" },
{ startNum: "921004", operator: "Мегафон" },
{ startNum: "921005", operator: "Мегафон" },
{ startNum: "921006", operator: "Мегафон" },
{ startNum: "921007", operator: "Мегафон" },
{ startNum: "921008", operator: "Мегафон" },
{ startNum: "921009", operator: "Мегафон" },
{ startNum: "92101", operator: "Мегафон" },
{ startNum: "92102", operator: "Мегафон" },
{ startNum: "92103", operator: "Мегафон" },
{ startNum: "92104", operator: "Мегафон" },
{ startNum: "92105", operator: "Мегафон" },
{ startNum: "92106", operator: "Мегафон" },
{ startNum: "92107", operator: "Мегафон" },
{ startNum: "92108", operator: "Мегафон" },
{ startNum: "92109", operator: "Мегафон" },
{ startNum: "92110", operator: "Мегафон" },
{ startNum: "921110", operator: "Мегафон" },
{ startNum: "921111", operator: "Мегафон" },
{ startNum: "921112", operator: "Мегафон" },
{ startNum: "921113", operator: "Мегафон" },
{ startNum: "921114", operator: "Мегафон" },
{ startNum: "921115", operator: "Мегафон" },
{ startNum: "921116", operator: "Мегафон" },
{ startNum: "921117", operator: "Мегафон" },
{ startNum: "921118", operator: "Мегафон" },
{ startNum: "921119", operator: "Мегафон" },
{ startNum: "92112", operator: "Мегафон" },
{ startNum: "92113", operator: "Мегафон" },
{ startNum: "92114", operator: "Мегафон" },
{ startNum: "92115", operator: "Мегафон" },
{ startNum: "92116", operator: "Мегафон" },
{ startNum: "92117", operator: "Мегафон" },
{ startNum: "92118", operator: "Мегафон" },
{ startNum: "92118", operator: "Мегафон" },
{ startNum: "92119", operator: "Мегафон" },
{ startNum: "92120", operator: "Мегафон" },
{ startNum: "9213", operator: "Мегафон" },
{ startNum: "92121", operator: "Мегафон" },
{ startNum: "92122", operator: "Мегафон" },
{ startNum: "92123", operator: "Мегафон" },
{ startNum: "92124", operator: "Мегафон" },
{ startNum: "92125", operator: "Мегафон" },
{ startNum: "92126", operator: "Мегафон" },
{ startNum: "92127", operator: "Мегафон" },
{ startNum: "92128", operator: "Мегафон" },
{ startNum: "92129", operator: "Мегафон" },
{ startNum: "92140", operator: "Мегафон" },
{ startNum: "92141", operator: "Мегафон" },
{ startNum: "92142", operator: "Мегафон" },
{ startNum: "92143", operator: "Мегафон" },
{ startNum: "92144", operator: "Мегафон" },
{ startNum: "92145", operator: "Мегафон" },
{ startNum: "92146", operator: "Мегафон" },
{ startNum: "92147", operator: "Мегафон" },
{ startNum: "92148", operator: "Мегафон" },
{ startNum: "92149", operator: "Мегафон" },
{ startNum: "92150", operator: "Мегафон" },
{ startNum: "92151", operator: "Мегафон" },
{ startNum: "92152", operator: "Мегафон" },
{ startNum: "92153", operator: "Мегафон" },
{ startNum: "92154", operator: "Мегафон" },
{ startNum: "92155", operator: "Мегафон" },
{ startNum: "92156", operator: "Мегафон" },
{ startNum: "92157", operator: "Мегафон" },
{ startNum: "92158", operator: "Мегафон" },
{ startNum: "92159", operator: "Мегафон" },
{ startNum: "921600", operator: "Мегафон" },
{ startNum: "921601", operator: "Мегафон" },
{ startNum: "921602", operator: "Мегафон" },
{ startNum: "921603", operator: "Мегафон" },
{ startNum: "921604", operator: "Мегафон" },
{ startNum: "921605", operator: "Мегафон" },
{ startNum: "921606", operator: "Мегафон" },
{ startNum: "921607", operator: "Мегафон" },
{ startNum: "921608", operator: "Мегафон" },
{ startNum: "921609", operator: "Мегафон" },
{ startNum: "92161", operator: "Мегафон" },
{ startNum: "92162", operator: "Мегафон" },
{ startNum: "92163", operator: "Мегафон" },
{ startNum: "92164", operator: "Мегафон" },
{ startNum: "92165", operator: "Мегафон" },
{ startNum: "92166", operator: "Мегафон" },
{ startNum: "92167", operator: "Мегафон" },
{ startNum: "92168", operator: "Мегафон" },
{ startNum: "92169", operator: "Мегафон" },
{ startNum: "921700", operator: "Мегафон" },
{ startNum: "921701", operator: "Мегафон" },
{ startNum: "921702", operator: "Мегафон" },
{ startNum: "921703", operator: "Мегафон" },
{ startNum: "921704", operator: "Мегафон" },
{ startNum: "921705", operator: "Мегафон" },
{ startNum: "921706", operator: "Мегафон" },
{ startNum: "921707", operator: "Мегафон" },
{ startNum: "921708", operator: "Мегафон" },
{ startNum: "921709", operator: "Мегафон" },
{ startNum: "921710", operator: "Мегафон" },
{ startNum: "921711", operator: "Мегафон" },
{ startNum: "921712", operator: "Мегафон" },
{ startNum: "921713", operator: "Мегафон" },
{ startNum: "921714", operator: "Мегафон" },
{ startNum: "921715", operator: "Мегафон" },
{ startNum: "921716", operator: "Мегафон" },
{ startNum: "921717", operator: "Мегафон" },
{ startNum: "921718", operator: "Мегафон" },
{ startNum: "921719", operator: "Мегафон" },
{ startNum: "921720", operator: "Мегафон" },
{ startNum: "921721", operator: "Мегафон" },
{ startNum: "921722", operator: "Мегафон" },
{ startNum: "921723", operator: "Мегафон" },
{ startNum: "921724", operator: "Мегафон" },
{ startNum: "921725", operator: "Мегафон" },
{ startNum: "921726", operator: "Мегафон" },
{ startNum: "921727", operator: "Мегафон" },
{ startNum: "921728", operator: "Мегафон" },
{ startNum: "921729", operator: "Мегафон" },
{ startNum: "921730", operator: "Мегафон" },
{ startNum: "921731", operator: "Мегафон" },
{ startNum: "921732", operator: "Мегафон" },
{ startNum: "921733", operator: "Мегафон" },
{ startNum: "921734", operator: "Мегафон" },
{ startNum: "921735", operator: "Мегафон" },
{ startNum: "921736", operator: "Мегафон" },
{ startNum: "921737", operator: "Мегафон" },
{ startNum: "921738", operator: "Мегафон" },
{ startNum: "921739", operator: "Мегафон" },
{ startNum: "92174", operator: "Мегафон" },
{ startNum: "92175", operator: "Мегафон" },
{ startNum: "92176", operator: "Мегафон" },
{ startNum: "92177", operator: "Мегафон" },
{ startNum: "92178", operator: "Мегафон" },
{ startNum: "92179", operator: "Мегафон" },
{ startNum: "921800", operator: "Мегафон" },
{ startNum: "921801", operator: "Мегафон" },
{ startNum: "921802", operator: "Мегафон" },
{ startNum: "921803", operator: "Мегафон" },
{ startNum: "921804", operator: "Мегафон" },
{ startNum: "921805", operator: "Мегафон" },
{ startNum: "921806", operator: "Мегафон" },
{ startNum: "921807", operator: "Мегафон" },
{ startNum: "921808", operator: "Мегафон" },
{ startNum: "921809", operator: "Мегафон" },
{ startNum: "92181", operator: "Мегафон" },
{ startNum: "92182", operator: "Мегафон" },
{ startNum: "92183", operator: "Мегафон" },
{ startNum: "921840", operator: "Мегафон" },
{ startNum: "921841", operator: "Мегафон" },
{ startNum: "921842", operator: "Мегафон" },
{ startNum: "921843", operator: "Мегафон" },
{ startNum: "921844", operator: "Мегафон" },
{ startNum: "921845", operator: "Мегафон" },
{ startNum: "921846", operator: "Мегафон" },
{ startNum: "921847", operator: "Мегафон" },
{ startNum: "921848", operator: "Мегафон" },
{ startNum: "921849", operator: "Мегафон" },
{ startNum: "921850", operator: "Мегафон" },
{ startNum: "921851", operator: "Мегафон" },
{ startNum: "921852", operator: "Мегафон" },
{ startNum: "921853", operator: "Мегафон" },
{ startNum: "921854", operator: "Мегафон" },
{ startNum: "921855", operator: "Мегафон" },
{ startNum: "921856", operator: "Мегафон" },
{ startNum: "921857", operator: "Мегафон" },
{ startNum: "921858", operator: "Мегафон" },
{ startNum: "921859", operator: "Мегафон" },
{ startNum: "92186", operator: "Мегафон" },
{ startNum: "92187", operator: "Мегафон" },
{ startNum: "92188", operator: "Мегафон" },
{ startNum: "92189", operator: "Мегафон" },
{ startNum: "9219", operator: "Мегафон" },

/***************************************  922  ********************************************************* */

{ startNum: "92200", operator: "Мегафон" },
{ startNum: "92201", operator: "Мегафон" },
{ startNum: "92202", operator: "Мегафон" },
{ startNum: "92203", operator: "Мегафон" },
{ startNum: "92204", operator: "Мегафон" },
{ startNum: "92205", operator: "Мегафон" },
{ startNum: "92206", operator: "Мегафон" },
{ startNum: "92207", operator: "Мегафон" },
{ startNum: "92208", operator: "Мегафон" },
{ startNum: "92209", operator: "Мегафон" },
{ startNum: "9221", operator: "Мегафон" },
{ startNum: "92220", operator: "Мегафон" },
{ startNum: "92221", operator: "Мегафон" },
{ startNum: "92222", operator: "Мегафон" },
{ startNum: "92223", operator: "Мегафон" },
{ startNum: "922240", operator: "Мегафон" },
{ startNum: "922241", operator: "Мегафон" },
{ startNum: "922242", operator: "Мегафон" },
{ startNum: "922243", operator: "Мегафон" },
{ startNum: "922244", operator: "Мегафон" },
{ startNum: "922245", operator: "Мегафон" },
{ startNum: "922246", operator: "Мегафон" },
{ startNum: "922247", operator: "Мегафон" },
{ startNum: "922248", operator: "Мегафон" },
{ startNum: "92224", operator: "Мегафон" },
{ startNum: "92225", operator: "Мегафон" },
{ startNum: "92226", operator: "Мегафон" },
{ startNum: "92227", operator: "Мегафон" },
{ startNum: "92228", operator: "Мегафон" },
{ startNum: "92229", operator: "Мегафон" },
{ startNum: "92230", operator: "Мегафон" },
{ startNum: "92231", operator: "Мегафон" },
{ startNum: "92232", operator: "Мегафон" },
{ startNum: "92233", operator: "Мегафон" },
{ startNum: "92234", operator: "Мегафон" },
{ startNum: "92235", operator: "Мегафон" },
{ startNum: "92236", operator: "Мегафон" },
{ startNum: "92237", operator: "Мегафон" },
{ startNum: "92238", operator: "Мегафон" },
{ startNum: "922390", operator: "Мегафон" },
{ startNum: "922391", operator: "Мегафон" },
{ startNum: "922392", operator: "Мегафон" },
{ startNum: "922393", operator: "Мегафон" },
{ startNum: "922394", operator: "Мегафон" },
{ startNum: "922395", operator: "Мегафон" },
{ startNum: "922396", operator: "Мегафон" },
{ startNum: "922397", operator: "Мегафон" },
{ startNum: "922398", operator: "Мегафон" },
{ startNum: "922399", operator: "Мегафон" },
{ startNum: "92240", operator: "Мегафон" },
{ startNum: "92241", operator: "Мегафон" },
{ startNum: "92242", operator: "Мегафон" },
{ startNum: "92243", operator: "Мегафон" },
{ startNum: "92244", operator: "Мегафон" },
{ startNum: "92245", operator: "Мегафон" },
{ startNum: "92246", operator: "Мегафон" },
{ startNum: "92247", operator: "Мегафон" },
{ startNum: "92248", operator: "Мегафон" },
{ startNum: "922490", operator: "Мегафон" },
{ startNum: "922491", operator: "Мегафон" },
{ startNum: "922492", operator: "Мегафон" },
{ startNum: "922493", operator: "Мегафон" },
{ startNum: "922494", operator: "Мегафон" },
{ startNum: "922495", operator: "Мегафон" },
{ startNum: "922496", operator: "Мегафон" },
{ startNum: "922497", operator: "Мегафон" },
{ startNum: "922498", operator: "Мегафон" },
{ startNum: "922499", operator: "Мегафон" },
{ startNum: "92250", operator: "Мегафон" },
{ startNum: "92251", operator: "Мегафон" },
{ startNum: "92252", operator: "Мегафон" },
{ startNum: "92253", operator: "Мегафон" },
{ startNum: "92254", operator: "Мегафон" },
{ startNum: "92255", operator: "Мегафон" },
{ startNum: "92256", operator: "Мегафон" },
{ startNum: "92257", operator: "Мегафон" },
{ startNum: "92258", operator: "Мегафон" },
{ startNum: "92259", operator: "Мегафон" },
{ startNum: "92260", operator: "Мегафон" },
{ startNum: "92261", operator: "Мегафон" },
{ startNum: "92262", operator: "Мегафон" },
{ startNum: "92263", operator: "Мегафон" },
{ startNum: "92264", operator: "Мегафон" },
{ startNum: "92265", operator: "Мегафон" },
{ startNum: "92266", operator: "Мегафон" },
{ startNum: "92267", operator: "Мегафон" },
{ startNum: "92268", operator: "Мегафон" },
{ startNum: "922690", operator: "Мегафон" },
{ startNum: "922691", operator: "Мегафон" },
{ startNum: "922692", operator: "Мегафон" },
{ startNum: "922693", operator: "Мегафон" },
{ startNum: "922694", operator: "Мегафон" },
{ startNum: "922695", operator: "Мегафон" },
{ startNum: "922696", operator: "Мегафон" },
{ startNum: "922697", operator: "Мегафон" },
{ startNum: "922698", operator: "Мегафон" },
{ startNum: "922699", operator: "Мегафон" },
{ startNum: "92270", operator: "Мегафон" },
{ startNum: "92271", operator: "Мегафон" },
{ startNum: "92272", operator: "Мегафон" },
{ startNum: "92273", operator: "Мегафон" },
{ startNum: "92274", operator: "Мегафон" },
{ startNum: "92275", operator: "Мегафон" },
{ startNum: "92276", operator: "Мегафон" },
{ startNum: "92277", operator: "Мегафон" },
{ startNum: "92278", operator: "Мегафон" },
{ startNum: "92279", operator: "Мегафон" },
{ startNum: "9228", operator: "Мегафон" },
{ startNum: "92290", operator: "Мегафон" },
{ startNum: "92291", operator: "Мегафон" },
{ startNum: "92292", operator: "Мегафон" },
{ startNum: "92293", operator: "Мегафон" },
{ startNum: "92294", operator: "Мегафон" },
{ startNum: "922950", operator: "Мегафон" },
{ startNum: "922951", operator: "Мегафон" },
{ startNum: "922952", operator: "Мегафон" },
{ startNum: "922953", operator: "Мегафон" },
{ startNum: "922954", operator: "Мегафон" },
{ startNum: "922955", operator: "Мегафон" },
{ startNum: "922956", operator: "Мегафон" },
{ startNum: "922957", operator: "Мегафон" },
{ startNum: "922958", operator: "Мегафон" },
{ startNum: "922959", operator: "Мегафон" },
{ startNum: "92296", operator:"Мегафон" },
{ startNum: "922970", operator: "Мегафон" },
{ startNum: "922971", operator: "Мегафон" },
{ startNum: "922972", operator: "Мегафон" },
{ startNum: "922973", operator: "Мегафон" },
{ startNum: "922974", operator: "Мегафон" },
{ startNum: "922975", operator: "Мегафон" },
{ startNum: "922976", operator: "Мегафон" },
{ startNum: "922977", operator: "Мегафон" },
{ startNum: "922978", operator: "Мегафон" },
{ startNum: "922979", operator: "Мегафон" },
{ startNum: "922980", operator: "Мегафон" },
{ startNum: "9229810", operator: "Мегафон" },
{ startNum: "9229811", operator: "Мегафон" },
{ startNum: "9229812", operator: "Мегафон" },
{ startNum: "9229813", operator: "Мегафон" },
{ startNum: "9229814", operator: "Мегафон" },
{ startNum: "9229815", operator: "Мегафон" },
{ startNum: "9229816", operator: "Мегафон" },
{ startNum: "9229817", operator: "Мегафон" },
{ startNum: "9229818", operator: "Мегафон" },
{ startNum: "9229819", operator: "Мегафон" },
{ startNum: "922982", operator: "Мегафон" },
{ startNum: "922983", operator: "Мегафон" },
{ startNum: "922984", operator: "Мегафон" },
{ startNum: "922985", operator: "Мегафон" },
{ startNum: "922986", operator: "Мегафон" },
{ startNum: "922987", operator: "Мегафон" },
{ startNum: "922988", operator: "Мегафон" },
{ startNum: "922989", operator: "Мегафон" },
{ startNum: "92299", operator:"Мегафон" },
/***************************************  923  ********************************************************* */

{ startNum: "9230", operator: "МТС" },
{ startNum: "9231", operator: "МТС" },
{ startNum: "9232", operator: "МТС" },
{ startNum: "9233", operator: "МТС" },
{ startNum: "9234", operator: "МТС" },
{ startNum: "9235", operator: "МТС" },
{ startNum: "9236", operator: "МТС" },
{ startNum: "9237", operator: "МТС" },
{ startNum: "92380", operator: "МТС" },
{ startNum: "92381", operator: "МТС" },
{ startNum: "923820", operator: "МТС" },
{ startNum: "923821", operator: "МТС" },
{ startNum: "923822", operator: "МТС" },
{ startNum: "923823", operator: "МТС" },
{ startNum: "923824", operator: "МТС" },
{ startNum: "923825", operator: "МТС" },
{ startNum: "923826", operator: "МТС" },
{ startNum: "923827", operator: "МТС" },
{ startNum: "9238280", operator: "Мегафон" },
{ startNum: "9238281", operator: "Мегафон" },
{ startNum: "9238282", operator: "Цифра групп" },
{ startNum: "9238283", operator: "Цифра групп" },
{ startNum: "9238284", operator: "Цифра групп" },
{ startNum: "9238285", operator: "МТТ" },
{ startNum: "9238286", operator: "МТТ" },
{ startNum: "9238287", operator: "МТТ" },
{ startNum: "9238288", operator: "МТТ" },
{ startNum: "9238289", operator: "МТТ" },
{ startNum: "923829", operator: "МТТ" },
{ startNum: "92383", operator: "МТС" },
{ startNum: "92384", operator: "МТС" },
{ startNum: "92385", operator: "МТС" },
{ startNum: "92386", operator: "МТС" },
{ startNum: "92387", operator: "МТС" },
{ startNum: "92388", operator: "МТС" },
{ startNum: "92389", operator: "МТТ" },
{ startNum: "92390", operator: "МТТ" },
{ startNum: "92391", operator: "МТТ" },
{ startNum: "92392", operator: "МТТ" },
{ startNum: "92393", operator: "МТТ" },
{ startNum: "92394", operator: "МТТ" },
{ startNum: "92395", operator: "МТТ" },
{ startNum: "92396", operator: "МТТ" },
{ startNum: "92397", operator: "МТТ" },
{ startNum: "92398", operator: "МТТ" },
{ startNum: "92399", operator: "Спринт" },
/***************************************  924  ********************************************************* */

{ startNum: "91800", operator: "Мегафон" },
{ startNum: "91801", operator: "Мегафон" },
{ startNum: "918020", operator: "Мегафон" },
{ startNum: "918021", operator: "Мегафон" },
{ startNum: "918022", operator: "Мегафон" },
{ startNum: "918023", operator: "Мегафон" },
{ startNum: "918024", operator: "Мегафон" },
{ startNum: "918025", operator: "Мегафон" },
{ startNum: "918026", operator: "Мегафон" },
{ startNum: "9180270", operator: "Мегафон" },
{ startNum: "9180271", operator: "Мегафон" },
{ startNum: "9180272", operator: "Мегафон" },
{ startNum: "9180273", operator: "Мегафон" },
{ startNum: "9180274", operator: "Мегафон" },
{ startNum: "9180275", operator: "МТТ" },
{ startNum: "9180276", operator: "МТТ" },
{ startNum: "9180277", operator: "МТТ" },
{ startNum: "9180278", operator: "МТТ" },
{ startNum: "9180279", operator: "МТТ" },
{ startNum: "918028", operator: "МТТ" },
{ startNum: "918029", operator: "МТТ" },
{ startNum: "91803", operator: "Мегафон" },
{ startNum: "91804", operator: "Мегафон" },
{ startNum: "91805", operator: "Мегафон" },
{ startNum: "91806", operator: "Мегафон" },
{ startNum: "91807", operator: "Мегафон" },
{ startNum: "91808", operator: "Мегафон" },
{ startNum: "91809", operator: "Мегафон" },
{ startNum: "9181", operator: "Мегафон" },
{ startNum: "9182", operator: "Мегафон" },
{ startNum: "9183", operator: "Мегафон" },
{ startNum: "9184", operator: "Мегафон" },
{ startNum: "9185", operator: "Мегафон" },
{ startNum: "9186", operator: "Мегафон" },
{ startNum: "9187", operator: "Мегафон" },
{ startNum: "9188", operator: "Мегафон" },
{ startNum: "918900", operator: "Мегафон" },
{ startNum: "918901", operator: "Мегафон" },
{ startNum: "9189020", operator: "Мегафон" },
{ startNum: "9189021", operator: "Мегафон" },
{ startNum: "9189022", operator: "Мегафон" },
{ startNum: "9189023", operator: "Мегафон" },
{ startNum: "9189024", operator: "Мегафон" },
{ startNum: "9189025", operator: "Мегафон" },
{ startNum: "9189026", operator: "Мегафон" },
{ startNum: "9189027", operator: "Мегафон" },
{ startNum: "9189028", operator: "Мегафон" },
{ startNum: "9189029", operator: "МиАТел/Цифра групп" },
{ startNum: "918903", operator: "Мегафон" },
{ startNum: "918904", operator: "Мегафон" },
{ startNum: "918905", operator: "Мегафон" },
{ startNum: "918906", operator: "Мегафон" },
{ startNum: "918907", operator: "Мегафон" },
{ startNum: "918908", operator: "МТТ" },
{ startNum: "918909", operator: "МТТ" },
{ startNum: "918910", operator: "МТТ" },
{ startNum: "918911", operator: "МТТ" },
{ startNum: "918912", operator: "МТТ" },
{ startNum: "918913", operator: "МТТ" },
{ startNum: "918914", operator: "МТТ" },
{ startNum: "918915", operator: "Мегафон" },
{ startNum: "918916", operator: "Мегафон" },
{ startNum: "918917", operator: "Мегафон" },
{ startNum: "918918", operator: "Мегафон" },
{ startNum: "918919", operator: "Мегафон" },
{ startNum: "91892", operator: "Мегафон" },
{ startNum: "91893", operator: "Мегафон" },
{ startNum: "91894", operator: "Мегафон" },
{ startNum: "918950", operator: "Мегафон" },
{ startNum: "918951", operator: "МТТ" },
{ startNum: "918952", operator: "МТТ" },
{ startNum: "918953", operator: "МТТ" },
{ startNum: "918954", operator: "МТТ" },
{ startNum: "918955", operator: "Мегафон" },
{ startNum: "918956", operator: "Мегафон" },
{ startNum: "918957", operator: "Мегафон" },
{ startNum: "918958", operator: "Мегафон" },
{ startNum: "918959", operator: "Мегафон" },
{ startNum: "91896", operator: "Мегафон" },
{ startNum: "91897", operator: "Мегафон" },
{ startNum: "91898", operator: "Мегафон" },
{ startNum: "91899", operator: "Мегафон" },

/***************************************  925  ********************************************************* */

{ startNum: "9250", operator: "Мегафон" },
{ startNum: "9251", operator: "Мегафон" },
{ startNum: "9252", operator: "Мегафон" },
{ startNum: "9253", operator: "Мегафон" },
{ startNum: "9254", operator: "Мегафон" },
{ startNum: "9255", operator: "Мегафон" },
{ startNum: "9256", operator: "Мегафон" },
{ startNum: "9257", operator: "Мегафон" },
{ startNum: "9258", operator: "Мегафон" },
{ startNum: "9259", operator: "Мегафон" },
/***************************************  926  ********************************************************* */

{ startNum: "9260", operator: "Мегафон" },
{ startNum: "9261", operator: "Мегафон" },
{ startNum: "9262", operator: "Мегафон" },
{ startNum: "9263", operator: "Мегафон" },
{ startNum: "9264", operator: "Мегафон" },
{ startNum: "9265", operator: "Мегафон" },
{ startNum: "9266", operator: "Мегафон" },
{ startNum: "9267", operator: "Мегафон" },
{ startNum: "9268", operator: "Мегафон" },
{ startNum: "9269", operator: "Мегафон" },

/***************************************  927  ********************************************************* */

{ startNum: "9270", operator: "Мегафон" },
{ startNum: "9271", operator: "Мегафон" },
{ startNum: "9272", operator: "Мегафон" },
{ startNum: "9273", operator: "Мегафон" },
{ startNum: "9274", operator: "Мегафон" },
{ startNum: "9275", operator: "Мегафон" },
{ startNum: "9276", operator: "Мегафон" },
{ startNum: "9277", operator: "Мегафон" },
{ startNum: "9278", operator: "Мегафон" },
{ startNum: "9279", operator: "Мегафон" },

/***************************************  928  ********************************************************* */

{ startNum: "9280", operator: "Мегафон" },
{ startNum: "9281", operator: "Мегафон" },
{ startNum: "9282", operator: "Мегафон" },
{ startNum: "9283", operator: "Мегафон" },
{ startNum: "9284", operator: "Мегафон" },
{ startNum: "9285", operator: "Мегафон" },
{ startNum: "9286", operator: "Мегафон" },
{ startNum: "9287", operator: "Мегафон" },
{ startNum: "9288", operator: "Мегафон" },
{ startNum: "9289", operator: "Мегафон" },
/***************************************  929 ********************************************************* */

{ startNum: "9290", operator: "Мегафон" },
{ startNum: "9291", operator: "Мегафон" },
{ startNum: "9292", operator: "Мегафон" },
{ startNum: "9293", operator: "Мегафон" },
{ startNum: "9294", operator: "Мегафон" },
{ startNum: "9295", operator: "Мегафон" },
{ startNum: "9296", operator: "Мегафон" },
{ startNum: "9297", operator: "Мегафон" },
{ startNum: "9298", operator: "Мегафон" },
{ startNum: "9299", operator: "Мегафон" },
/***************************************  936 ********************************************************* */

{ startNum: "9360", operator: "Мегафон" },
{ startNum: "936100", operator: "Мегафон" },
{ startNum: "936101", operator: "Мегафон" },
{ startNum: "936102", operator: "Мегафон" },
{ startNum: "936103", operator: "Мегафон" },
{ startNum: "936104", operator: "Мегафон" },
{ startNum: "936105", operator: "Мегафон" },
{ startNum: "936106", operator: "МТТ" },
{ startNum: "936107", operator: "МТТ" },
{ startNum: "936108", operator: "МТТ" },
{ startNum: "936109", operator: "МТТ" },
{ startNum: "93611", operator: "Мегафон" },
{ startNum: "93612", operator: "Мегафон" },
{ startNum: "93613", operator: "Мегафон" },
{ startNum: "93614", operator: "Мегафон" },
{ startNum: "93615", operator: "Мегафон" },
{ startNum: "93616", operator: "Мегафон" },
{ startNum: "93617", operator: "Мегафон" },
{ startNum: "93618", operator: "Мегафон" },
{ startNum: "93619", operator: "Мегафон" },
{ startNum: "9362", operator: "Мегафон" },
{ startNum: "93630", operator: "Мегафон" },
{ startNum: "936310", operator: "Мегафон" },
{ startNum: "936311", operator: "Мегафон" },
{ startNum: "936312", operator: "Мегафон" },
{ startNum: "936313", operator: "Мегафон" },
{ startNum: "936314", operator: "Мегафон" },
{ startNum: "936315", operator: "Мегафон" },
{ startNum: "936316", operator: "Мегафон" },
{ startNum: "936317", operator: "Мегафон" },
{ startNum: "936318", operator: "МТТ" },
{ startNum: "936319", operator: "Мегафон" },
{ startNum: "93632", operator: "Мегафон" },
{ startNum: "93633", operator: "Мегафон" },
{ startNum: "93634", operator: "Мегафон" },
{ startNum: "93635", operator: "Мегафон" },
{ startNum: "93636", operator: "Мегафон" },
{ startNum: "93637", operator: "Мегафон" },
{ startNum: "93638", operator: "Мегафон" },
{ startNum: "93639", operator: "Мегафон" },
{ startNum: "9364", operator: "Мегафон" },
{ startNum: "93650", operator: "Мегафон" },
{ startNum: "93651", operator: "Мегафон" },
{ startNum: "93652", operator: "Мегафон" },
{ startNum: "93653", operator: "Мегафон" },
{ startNum: "93654", operator: "Мегафон" },
{ startNum: "936550", operator: "Мегафон" },
{ startNum: "936551", operator: "Мегафон" },
{ startNum: "936552", operator: "Мегафон" },
{ startNum: "936553", operator: "Мегафон" },
{ startNum: "936554", operator: "Мегафон" },
{ startNum: "936555", operator: "Мегафон" },
{ startNum: "936556", operator: "МТТ" },
{ startNum: "936557", operator: "Мегафон" },
{ startNum: "936558", operator: "Мегафон" },
{ startNum: "936559", operator: "Мегафон" },
{ startNum: "93656", operator: "Мегафон" },
{ startNum: "93657", operator: "Мегафон" },
{ startNum: "93658", operator: "Мегафон" },
{ startNum: "93659", operator: "Мегафон" },
{ startNum: "93660", operator: "Мегафон" },
{ startNum: "93661", operator: "Мегафон" },
{ startNum: "93662", operator: "Мегафон" },
{ startNum: "93663", operator: "Мегафон" },
{ startNum: "93664", operator: "Мегафон" },
{ startNum: "93665", operator: "Мегафон" },
{ startNum: "93666", operator: "Мегафон" },
{ startNum: "936670", operator: "Мегафон" },
{ startNum: "936671", operator: "Мегафон" },
{ startNum: "936672", operator: "Мегафон" },
{ startNum: "936673", operator: "Мегафон" },
{ startNum: "936674", operator: "Мегафон" },
{ startNum: "936675", operator: "Мегафон" },
{ startNum: "936676", operator: "Мегафон" },
{ startNum: "936677", operator: "МТТ" },
{ startNum: "936678", operator: "Мегафон" },
{ startNum: "936679", operator: "Мегафон" },
{ startNum: "93668", operator: "Мегафон" },
{ startNum: "93669", operator: "Мегафон" },
{ startNum: "93670", operator: "Мегафон" },
{ startNum: "93671", operator: "Мегафон" },
{ startNum: "93672", operator: "Мегафон" },
{ startNum: "93673", operator: "Мегафон" },
{ startNum: "93674", operator: "Мегафон" },
{ startNum: "93675", operator: "Мегафон" },
{ startNum: "93676", operator: "Мегафон" },
{ startNum: "936770", operator: "Мегафон" },
{ startNum: "936771", operator: "Мегафон" },
{ startNum: "936772", operator: "Мегафон" },
{ startNum: "936773", operator: "Мегафон" },
{ startNum: "936774", operator: "Мегафон" },
{ startNum: "936775", operator: "Мегафон" },
{ startNum: "936776", operator: "Мегафон" },
{ startNum: "936777", operator: "МТТ" },
{ startNum: "936778", operator: "Мегафон" },
{ startNum: "936779", operator: "Мегафон" },
{ startNum: "93678", operator: "Мегафон" },
{ startNum: "93679", operator: "Мегафон" },
{ startNum: "9368", operator: "Мегафон" },
{ startNum: "9369", operator: "Мегафон" },
/***************************************  937  ********************************************************* */

{ startNum: "9370", operator: "Мегафон" },
{ startNum: "9371", operator: "Мегафон" },
{ startNum: "9372", operator: "Мегафон" },
{ startNum: "9373", operator: "Мегафон" },
{ startNum: "9374", operator: "Мегафон" },
{ startNum: "9375", operator: "Мегафон" },
{ startNum: "9376", operator: "Мегафон" },
{ startNum: "9377", operator: "Мегафон" },
{ startNum: "9378", operator: "Мегафон" },
{ startNum: "9379", operator: "Мегафон" },

/***************************************  941  ********************************************************* */

{ startNum: "9410", operator: "АО ГЛОНАСС" },
{ startNum: "9411", operator: "Перенос/Частный" },
{ startNum: "9412", operator: "Перенос/Частный" },
{ startNum: "9413", operator: "Перенос/Частный" },
{ startNum: "9414", operator: "Перенос/Частный" },
{ startNum: "9415", operator: "Перенос/Частный" },
{ startNum: "9416", operator: "Перенос/Частный" },
{ startNum: "9417", operator: "Перенос/Частный" },
{ startNum: "9418", operator: "АО ГЛОНАСС" },
{ startNum: "9419", operator: "АО ГЛОНАСС" },
/***************************************  942  ********************************************************* */

{ startNum: "9420", operator: "АО ГЛОНАСС" },
{ startNum: "9421", operator: "АО ГЛОНАСС" },
{ startNum: "9422", operator: "АО ГЛОНАСС" },
{ startNum: "9423", operator: "Перенос/Частный" },
{ startNum: "9424", operator: "Перенос/Частный" },
{ startNum: "9425", operator: "Перенос/Частный" },
{ startNum: "9426", operator: "Перенос/Частный" },
{ startNum: "9427", operator: "Перенос/Частный" },
{ startNum: "9428", operator: "Перенос/Частный" },
{ startNum: "9429", operator: "Перенос/Частный" },
/***************************************  949  ********************************************************* */

{ startNum: "9490", operator: "ГУПДНР" },
{ startNum: "9491", operator: "Перенос/Частный" },
{ startNum: "9492", operator: "Перенос/Частный" },
{ startNum: "9493", operator: "ГУПДНР" },
{ startNum: "9494", operator: "ГУПДНР" },
{ startNum: "9495", operator: "ГУПДНР" },
{ startNum: "9496", operator: "ГУПДНР" },
{ startNum: "94970", operator: "ГУПДНР" },
{ startNum: "94971", operator: "ГУПДНР" },
{ startNum: "94972", operator: "ГУПДНР" },
{ startNum: "94973", operator: "ГУПДНР" },
{ startNum: "94974", operator: "ГУПДНР" },
{ startNum: "94975", operator: "ГУПДНР" },
{ startNum: "94976", operator: "Перенос/Частный" },
{ startNum: "94977", operator: "Перенос/Частный" },
{ startNum: "94978", operator: "Перенос/Частный" },
{ startNum: "94979", operator: "Перенос/Частный" },
{ startNum: "94980", operator: "К-телеком" },
{ startNum: "94981", operator: "К-телеком" },
{ startNum: "94982", operator: "К-телеком" },
{ startNum: "94983", operator: "К-телеком" },
{ startNum: "94984", operator: "К-телеком" },
{ startNum: "94985", operator: "Перенос/Частный" },
{ startNum: "94986", operator: "Перенос/Частный" },
{ startNum: "94987", operator: "Перенос/Частный" },
{ startNum: "94988", operator: "Перенос/Частный" },
{ startNum: "94989", operator: "Перенос/Частный" },
{ startNum: "9499", operator: "Перенос/Частный" },
/***************************************  951  ********************************************************* */

{ startNum: "95100", operator: "Beeline" },
{ startNum: "95101", operator: "Beeline" },
{ startNum: "95102", operator: "Beeline" },
{ startNum: "95103", operator: "Tele2" },
{ startNum: "95104", operator: "МТТ" },
{ startNum: "95105", operator: "Beeline" },
{ startNum: "95106", operator: "Beeline" },
{ startNum: "95107", operator: "Beeline" },
{ startNum: "95108", operator: "Beeline" },
{ startNum: "95109", operator: "Beeline" },
{ startNum: "9511", operator: "Tele2" },
{ startNum: "95120", operator: "Tele2" },
{ startNum: "95121", operator: "Tele2" },
{ startNum: "95122", operator: "Tele2" },
{ startNum: "95123", operator: "Tele2" },
{ startNum: "95124", operator: "Tele2" },
{ startNum: "95125", operator: "Tele2" },
{ startNum: "95126", operator: "Tele2" },
{ startNum: "95127", operator: "Tele2" },
{ startNum: "95128", operator: "Мегафон" },
{ startNum: "95129", operator: "Tele2" },
{ startNum: "9513", operator: "Tele2" },
{ startNum: "9514", operator: "Tele2" },
{ startNum: "9515", operator: "Tele2" },
{ startNum: "9516", operator: "Tele2" },
{ startNum: "9517", operator: "Tele2" },
{ startNum: "9518", operator: "Tele2" },
{ startNum: "9519", operator: "Tele2" },
/***************************************  952  ********************************************************* */

{ startNum: "9520", operator: "Tele2" },
{ startNum: "95210", operator: "Tele2" },
{ startNum: "95211", operator: "Tele2" },
{ startNum: "95212", operator: "Tele2" },
{ startNum: "95213", operator: "МОТИВ" },
{ startNum: "95214", operator: "МОТИВ" },
{ startNum: "95215", operator: "Tele2" },
{ startNum: "95216", operator: "Tele2" },
{ startNum: "95217", operator: "Tele2" },
{ startNum: "95218", operator: "Tele2" },
{ startNum: "95219", operator: "Tele2" },
{ startNum: "9522", operator: "Tele2" },
{ startNum: "9523", operator: "Tele2" },
{ startNum: "9524", operator: "Tele2" },
{ startNum: "9525", operator: "Tele2" },
{ startNum: "9526", operator: "Tele2" },
{ startNum: "95270", operator: "Tele2" },
{ startNum: "95271", operator: "Tele2" },
{ startNum: "95272", operator: "Tele2" },
{ startNum: "95273", operator: "Tele2" },
{ startNum: "95274", operator: "Tele2" },
{ startNum: "952750", operator: "Tele2" },
{ startNum: "952751", operator: "Tele2" },
{ startNum: "952752", operator: "Tele2" },
{ startNum: "9527530", operator: "МиАТел/Цифра групп" },
{ startNum: "9527531", operator: "МиАТел/Цифра групп" },
{ startNum: "9527532", operator: "Tele2" },
{ startNum: "9527533", operator: "Tele2" },
{ startNum: "9527534", operator: "Tele2" },
{ startNum: "9527535", operator: "Tele2" },
{ startNum: "9527536", operator: "Tele2" },
{ startNum: "9527537", operator: "Tele2" },
{ startNum: "9527538", operator: "Tele2" },
{ startNum: "9527539", operator: "Tele2" },
{ startNum: "952754", operator: "Tele2" },
{ startNum: "952755", operator: "Tele2" },
{ startNum: "952756", operator: "Tele2" },
{ startNum: "952757", operator: "Tele2" },
{ startNum: "952758", operator: "Tele2" },
{ startNum: "952759", operator: "Tele2" },
{ startNum: "95276", operator: "Tele2" },
{ startNum: "95277", operator: "Tele2" },
{ startNum: "95278", operator: "Tele2" },
{ startNum: "95279", operator: "Tele2" },
{ startNum: "9528", operator: "Tele2" },
{ startNum: "9529", operator: "Tele2" },
/***************************************  954  ********************************************************* */

{ startNum: "9540", operator: "Перенос/Частный" },
{ startNum: "954100", operator: "Перенос/Частный" },
{ startNum: "954101", operator: "GTNT" },
{ startNum: "954102", operator: "Иридиум" },
{ startNum: "954103", operator: "Иридиум" },
{ startNum: "954104", operator: "Мегафон" },
{ startNum: "954105", operator: "Морсвязьспутник" },
{ startNum: "954106", operator: "Иридиум" },
{ startNum: "954107", operator: "Иридиум" },
{ startNum: "954108", operator: "Иридиум" },
{ startNum: "954109", operator: "Иридиум" },
{ startNum: "95411", operator: "GTNT/Иридиум" },
{ startNum: "95412", operator: "GTNT" },
{ startNum: "95413", operator: "Иридиум" },
{ startNum: "95414", operator: "Мегафон" },
{ startNum: "95415", operator: "Мегафон" },
{ startNum: "95416", operator: "Мегафон" },
{ startNum: "95417", operator: "Мегафон" },
{ startNum: "95418", operator: "Мегафон" },
{ startNum: "95419", operator: "Мегафон" },
{ startNum: "9542", operator: "Перенос/Частный" },
{ startNum: "9543", operator: "Перенос/Частный" },
{ startNum: "9544", operator: "Перенос/Частный" },
{ startNum: "9545", operator: "Перенос/Частный" },
{ startNum: "9546", operator: "Перенос/Частный" },
{ startNum: "9547", operator: "Перенос/Частный" },
{ startNum: "9548", operator: "Перенос/Частный" },
{ startNum: "9549", operator: "Перенос/Частный" },
/***************************************  955  ********************************************************* */

{ startNum: "955000", operator: "ТРН-телеком" },
{ startNum: "955001", operator: "Перенос/Частный" },
{ startNum: "955002", operator: "Перенос/Частный" },
{ startNum: "955003", operator: "Перенос/Частный" },
{ startNum: "955004", operator: "Перенос/Частный" },
{ startNum: "955005", operator: "Перенос/Частный" },
{ startNum: "955006", operator: "Перенос/Частный" },
{ startNum: "955007", operator: "Перенос/Частный" },
{ startNum: "955008", operator: "Перенос/Частный" },
{ startNum: "955009", operator: "Перенос/Частный" },
{ startNum: "95501", operator: "Перенос/Частный" },
{ startNum: "95502", operator: "Перенос/Частный" },
{ startNum: "95503", operator: "Перенос/Частный" },
{ startNum: "95504", operator: "Перенос/Частный" },
{ startNum: "95505", operator: "Перенос/Частный" },
{ startNum: "95506", operator: "Перенос/Частный" },
{ startNum: "95507", operator: "Перенос/Частный" },
{ startNum: "95508", operator: "Перенос/Частный" },
{ startNum: "95509", operator: "Перенос/Частный" },
{ startNum: "95510", operator: "Юнисел" },
{ startNum: "95511", operator: "Перенос/Частный" },
{ startNum: "95512", operator: "Перенос/Частный" },
{ startNum: "95513", operator: "Перенос/Частный" },
{ startNum: "95514", operator: "Перенос/Частный" },
{ startNum: "95515", operator: "Перенос/Частный" },
{ startNum: "95516", operator: "Перенос/Частный" },
{ startNum: "95517", operator: "Перенос/Частный" },
{ startNum: "95518", operator: "Перенос/Частный" },
{ startNum: "95519", operator: "Перенос/Частный" },
{ startNum: "9552", operator: "Перенос/Частный" },
{ startNum: "9553", operator: "Белитон" },
{ startNum: "9554", operator: "КватроПлюс" },
{ startNum: "9555", operator: "ТРН-телеком	" },
{ startNum: "9556", operator: "Перенос/Частный" },
{ startNum: "9557", operator: "Лардекс" },
{ startNum: "9558", operator: "Перенос/Частный" },
{ startNum: "9559", operator: "БИТ-ЦЕНТР/ТРН-телеком" },
/***************************************  956  ********************************************************* */

{ startNum: "9560000", operator: "ТЕЛЕМАТИКА" },
{ startNum: "9560001", operator: "ТЕЛЕМАТИКА" },
{ startNum: "9560002", operator: "ТЕЛЕМАТИКА" },
{ startNum: "9560003", operator: "ТЕЛЕМАТИКА" },
{ startNum: "9560004", operator: "Перенос/Частный" },
{ startNum: "9560005", operator: "Перенос/Частный" },
{ startNum: "9560006", operator: "Перенос/Частный" },
{ startNum: "9560007", operator: "Перенос/Частный" },
{ startNum: "9560008", operator: "Сургутнефтегаз" },
{ startNum: "9560009", operator: "Перенос/Частный" },
{ startNum: "9561", operator: "Перенос/Частный" },
{ startNum: "9562", operator: "Перенос/Частный" },
{ startNum: "9563", operator: "Перенос/Частный" },
{ startNum: "9564", operator: "Перенос/Частный" },
{ startNum: "9565", operator: "Перенос/Частный" },
{ startNum: "9566", operator: "Перенос/Частный" },
{ startNum: "9567", operator: "Перенос/Частный" },
{ startNum: "9568", operator: "Перенос/Частный" },
{ startNum: "9569", operator: "Перенос/Частный" },
/***************************************  959  ********************************************************* */

{ startNum: "959002", operator: "Миранда/МКС" },
{ startNum: "959003", operator: "Миранда/МКС" },
{ startNum: "959004", operator: "Миранда/МКС" },
{ startNum: "959005", operator: "Миранда/МКС" },
{ startNum: "959006", operator: "Миранда/МКС" },
{ startNum: "959007", operator: "Перенос/Частный" },
{ startNum: "959008", operator: "Перенос/Частный" },
{ startNum: "959009", operator: "Перенос/Частный" },
{ startNum: "9590", operator: "Перенос/МКС/Миранда" },
{ startNum: "9591", operator: "МКС" },
{ startNum: "9592", operator: "МКС" },
{ startNum: "9593", operator: "Мегафон" },
{ startNum: "9594", operator: "Мегафон" },
{ startNum: "9595", operator: "МКС" },
{ startNum: "9596", operator: "Мегафон" },
{ startNum: "9597", operator: "Мегафон" },
{ startNum: "95980", operator: "К-телеком" },
{ startNum: "95981", operator: "К-телеком" },
{ startNum: "95982", operator: "К-телеком" },
{ startNum: "95983", operator: "К-телеком" },
{ startNum: "95984", operator: "К-телеком" },
{ startNum: "95985", operator: "Перенос/Частный" },
{ startNum: "95986", operator: "Перенос/Частный" },
{ startNum: "95987", operator: "Перенос/Частный" },
{ startNum: "95988", operator: "Перенос/Частный" },
{ startNum: "95989", operator: "Перенос/Частный" },
{ startNum: "95990", operator: "Перенос/Частный" },
{ startNum: "95991", operator: "Перенос/Частный" },
{ startNum: "95992", operator: "Перенос/Частный" },
{ startNum: "95993", operator: "Перенос/Частный" },
{ startNum: "95994", operator: "Перенос/Частный" },
{ startNum: "95995", operator: "Перенос/Частный" },
{ startNum: "95996", operator: "Перенос/Частный" },
{ startNum: "95997", operator: "Перенос/Частный" },
{ startNum: "95998", operator: "Перенос/Частный" },
{ startNum: "95999", operator: "МКС" },
/***************************************  960  ********************************************************* */

{ startNum: "9600", operator: "Beeline" },
{ startNum: "9601", operator: "Beeline" },
{ startNum: "9602", operator: "Beeline" },
{ startNum: "9603", operator: "Beeline" },
{ startNum: "9604", operator: "Beeline" },
{ startNum: "9605", operator: "Beeline" },
{ startNum: "9606", operator: "Beeline" },
{ startNum: "9607", operator: "Beeline" },
{ startNum: "9608", operator: "Beeline" },
{ startNum: "9609", operator: "Beeline" },
/***************************************  961  ********************************************************* */

{ startNum: "9600", operator: "Beeline" },
{ startNum: "9601", operator: "Beeline" },
{ startNum: "9602", operator: "Beeline" },
{ startNum: "9603", operator: "Beeline" },
{ startNum: "9604", operator: "Beeline" },
{ startNum: "9605", operator: "Beeline" },
{ startNum: "9606", operator: "Beeline" },
{ startNum: "9607", operator: "Beeline" },
{ startNum: "96080", operator: "Beeline" },
{ startNum: "96081", operator: "Beeline" },
{ startNum: "96082", operator: "Beeline" },
{ startNum: "960830", operator: "Beeline" },
{ startNum: "960831", operator: "Beeline" },
{ startNum: "960832", operator: "Beeline" },
{ startNum: "960833", operator: "Beeline" },
{ startNum: "960834", operator: "Beeline" },
{ startNum: "960835", operator: "Beeline" },
{ startNum: "960836", operator: "Beeline" },
{ startNum: "960837", operator: "Beeline" },
{ startNum: "960838", operator: "Beeline" },
{ startNum: "960839", operator: "МТТ" },
{ startNum: "96084", operator: "Beeline" },
{ startNum: "96085", operator: "Beeline" },
{ startNum: "96086", operator: "Beeline" },
{ startNum: "96087", operator: "Beeline" },
{ startNum: "96088", operator: "Beeline" },
{ startNum: "96089", operator: "Beeline" },
{ startNum: "9609", operator: "Beeline" },
/***************************************  962  ********************************************************* */

{ startNum: "9600", operator: "Beeline" },
{ startNum: "9601", operator: "Beeline" },
{ startNum: "9602", operator: "Beeline" },
{ startNum: "9603", operator: "Beeline" },
{ startNum: "96040", operator: "Beeline" },
{ startNum: "96041", operator: "Beeline" },
{ startNum: "96042", operator: "Beeline" },
{ startNum: "96043", operator: "Beeline" },
{ startNum: "96044", operator: "Beeline" },
{ startNum: "96045", operator: "Beeline" },
{ startNum: "960460", operator: "Beeline" },
{ startNum: "960461", operator: "МТТ" },
{ startNum: "960462", operator: "МТТ" },
{ startNum: "960463", operator: "МТТ" },
{ startNum: "960464", operator: "МТТ" },
{ startNum: "960465", operator: "МТТ" },
{ startNum: "960466", operator: "МТТ" },
{ startNum: "960467", operator: "МТТ" },
{ startNum: "960468", operator: "МТТ" },
{ startNum: "960469", operator: "Beeline" },
{ startNum: "96047", operator: "Beeline" },
{ startNum: "96048", operator: "Beeline" },
{ startNum: "96049", operator: "Beeline" },
{ startNum: "9605", operator: "Beeline" },
{ startNum: "9606", operator: "Beeline" },
{ startNum: "9607", operator: "Beeline" },
{ startNum: "9608", operator: "Beeline" },
{ startNum: "9609", operator: "Beeline" },
/***************************************  963  ********************************************************* */

{ startNum: "9630", operator: "Beeline" },
{ startNum: "9631", operator: "Beeline" },
{ startNum: "9632", operator: "Beeline" },
{ startNum: "9633", operator: "Beeline" },
{ startNum: "9634", operator: "Beeline" },
{ startNum: "96350", operator: "Beeline" },
{ startNum: "96351", operator: "Beeline" },
{ startNum: "96352", operator: "Beeline" },
{ startNum: "96353", operator: "Beeline" },
{ startNum: "96354", operator: "Beeline" },
{ startNum: "96355", operator: "Beeline" },
{ startNum: "96356", operator: "Beeline" },
{ startNum: "963560", operator: "МТТ" },
{ startNum: "963561", operator: "МТТ" },
{ startNum: "963562", operator: "Beeline" },
{ startNum: "963563", operator: "Beeline" },
{ startNum: "963564", operator: "Beeline" },
{ startNum: "963565", operator: "Beeline" },
{ startNum: "963566", operator: "Beeline" },
{ startNum: "963567", operator: "Beeline" },
{ startNum: "963568", operator: "Beeline" },
{ startNum: "963569", operator: "МТТ" },
{ startNum: "96357", operator: "Beeline" },
{ startNum: "96358", operator: "Beeline" },
{ startNum: "96359", operator: "Beeline" },
{ startNum: "9636", operator: "Beeline" },
{ startNum: "96370", operator: "Beeline" },
{ startNum: "96371", operator: "Beeline" },
{ startNum: "96372", operator: "Beeline" },
{ startNum: "963730", operator: "Beeline" },
{ startNum: "963731", operator: "Beeline" },
{ startNum: "963732", operator: "Beeline" },
{ startNum: "963733", operator: "Beeline" },
{ startNum: "963734", operator: "Beeline" },
{ startNum: "963735", operator: "Beeline" },
{ startNum: "963736", operator: "Beeline" },
{ startNum: "963737", operator: "Beeline" },
{ startNum: "963738", operator: "Beeline" },
{ startNum: "963739", operator: "МТТ" },
{ startNum: "96374", operator: "Beeline" },
{ startNum: "96375", operator: "Beeline" },
{ startNum: "96376", operator: "Beeline" },
{ startNum: "96377", operator: "Beeline" },
{ startNum: "96378", operator: "Beeline" },
{ startNum: "96379", operator: "Beeline" },
{ startNum: "9638", operator: "Beeline" },
{ startNum: "9639", operator: "Beeline" },
/***************************************  964  ********************************************************* */

{ startNum: "9640", operator: "Beeline" },
{ startNum: "9641", operator: "Beeline" },
{ startNum: "9642", operator: "Beeline" },
{ startNum: "9643", operator: "Beeline" },
{ startNum: "9644", operator: "Beeline" },
{ startNum: "9645", operator: "Beeline" },
{ startNum: "9646", operator: "Beeline" },
{ startNum: "9647", operator: "Beeline" },
{ startNum: "9648", operator: "Beeline" },
{ startNum: "9649", operator: "Beeline" },
/***************************************  965  ********************************************************* */

{ startNum: "9650", operator: "Beeline" },
{ startNum: "9651", operator: "Beeline" },
{ startNum: "9652", operator: "Beeline" },
{ startNum: "9653", operator: "Beeline" },
{ startNum: "9654", operator: "Beeline" },
{ startNum: "9655", operator: "Beeline" },
{ startNum: "9656", operator: "Beeline" },
{ startNum: "9657", operator: "Beeline" },
{ startNum: "9658", operator: "Beeline" },
{ startNum: "9659", operator: "Beeline" },
/***************************************  966  ********************************************************* */

{ startNum: "9660", operator: "Beeline" },
{ startNum: "9661", operator: "Beeline" },
{ startNum: "966200", operator: "Сбербанк-Телеком" },
{ startNum: "966201", operator: "МТТ" },
{ startNum: "966202", operator: "МТТ" },
{ startNum: "966203", operator: "МТТ" },
{ startNum: "966204", operator: "МТТ" },
{ startNum: "966205", operator: "МТТ" },
{ startNum: "966206", operator: "Перенос/Частный" },
{ startNum: "966207", operator: "Перенос/Частный" },
{ startNum: "966208", operator: "Перенос/Частный" },
{ startNum: "966209", operator: "Перенос/Частный" },
{ startNum: "96621", operator: "Beeline" },
{ startNum: "966220", operator: "Перенос/Частный" },
{ startNum: "966221", operator: "Перенос/Частный" },
{ startNum: "966222", operator: "МТТ" },
{ startNum: "966223", operator: "Перенос/Частный" },
{ startNum: "966224", operator: "Перенос/Частный" },
{ startNum: "966225", operator: "Перенос/Частный" },
{ startNum: "966226", operator: "Перенос/Частный" },
{ startNum: "966227", operator: "Перенос/Частный" },
{ startNum: "966228", operator: "Перенос/Частный" },
{ startNum: "966229", operator: "Перенос/Частный" },
{ startNum: "96623", operator: "Beeline" },
{ startNum: "96624", operator: "Beeline" },
{ startNum: "96625", operator: "Beeline" },
{ startNum: "966260", operator: "Beeline" },
{ startNum: "966261", operator: "МТТ" },
{ startNum: "966262", operator: "МТТ" },
{ startNum: "966263", operator: "МТТ" },
{ startNum: "966264", operator: "МТТ" },
{ startNum: "966265", operator: "МТТ" },
{ startNum: "966266", operator: "Сбербанк-Телеком" },
{ startNum: "966267", operator: "МТТ" },
{ startNum: "966268", operator: "МТТ" },
{ startNum: "966269", operator: "МТТ" },
{ startNum: "96627", operator: "Beeline" },
{ startNum: "96628", operator: "Beeline" },
{ startNum: "96629", operator: "Beeline" },
{ startNum: "9663", operator: "Beeline" },
{ startNum: "96640", operator: "Beeline" },
{ startNum: "96641", operator: "Beeline" },
{ startNum: "96642", operator: "Beeline" },
{ startNum: "96643", operator: "Beeline" },
{ startNum: "966440", operator: "Beeline" },
{ startNum: "966441", operator: "Beeline" },
{ startNum: "966442", operator: "Beeline" },
{ startNum: "966443", operator: "Beeline" },
{ startNum: "966444", operator: "МТТ" },
{ startNum: "966445", operator: "Beeline" },
{ startNum: "966446", operator: "Beeline" },
{ startNum: "966447", operator: "Beeline" },
{ startNum: "966448", operator: "Beeline" },
{ startNum: "966449", operator: "Beeline" },
{ startNum: "96645", operator: "Beeline" },
{ startNum: "96646", operator: "Beeline" },
{ startNum: "96647", operator: "Beeline" },
{ startNum: "96648", operator: "Beeline" },
{ startNum: "96649", operator: "Beeline" },
{ startNum: "96650", operator: "Beeline" },
{ startNum: "96651", operator: "Beeline" },
{ startNum: "96652", operator: "Beeline" },
{ startNum: "96653", operator: "Beeline" },
{ startNum: "96654", operator: "Beeline" },
{ startNum: "966550", operator: "Beeline" },
{ startNum: "966551", operator: "Beeline" },
{ startNum: "966552", operator: "Beeline" },
{ startNum: "966553", operator: "Beeline" },
{ startNum: "966554", operator: "Beeline" },
{ startNum: "966555", operator: "МТТ" },
{ startNum: "966556", operator: "Beeline" },
{ startNum: "966557", operator: "Beeline" },
{ startNum: "966558", operator: "Beeline" },
{ startNum: "966559", operator: "Beeline" },
{ startNum: "96656", operator: "МТТ" },
{ startNum: "96657", operator: "Beeline" },
{ startNum: "96658", operator: "Beeline" },
{ startNum: "96659", operator: "Beeline" },
{ startNum: "96660", operator: "Beeline" },
{ startNum: "96661", operator: "Beeline" },
{ startNum: "96662", operator: "Beeline" },
{ startNum: "96663", operator: "Beeline" },
{ startNum: "96664", operator: "Beeline" },
{ startNum: "96665", operator: "Beeline" },
{ startNum: "966660", operator: "Beeline" },
{ startNum: "966661", operator: "Beeline" },
{ startNum: "966662", operator: "Beeline" },
{ startNum: "966663", operator: "Beeline" },
{ startNum: "966664", operator: "Beeline" },
{ startNum: "966665", operator: "Beeline" },
{ startNum: "966666", operator: "Интернод" },
{ startNum: "966667", operator: "МТТ" },
{ startNum: "966668", operator: "МТТ" },
{ startNum: "966669", operator: "МТТ" },
{ startNum: "96667", operator: "Сим Телеком	" },
{ startNum: "96668", operator: "Перенос/Частный" },
{ startNum: "96669", operator: "СкайНэт" },
{ startNum: "9667", operator: "Beeline" },
{ startNum: "96680", operator: "Beeline" },
{ startNum: "96681", operator: "Beeline" },
{ startNum: "96682", operator: "Beeline" },
{ startNum: "96683", operator: "Beeline" },
{ startNum: "96684", operator: "Beeline" },
{ startNum: "96685", operator: "Beeline" },
{ startNum: "96686", operator: "Beeline" },
{ startNum: "96687", operator: "Beeline" },
{ startNum: "966880", operator: "Beeline" },
{ startNum: "966881", operator: "Beeline" },
{ startNum: "966882", operator: "Beeline" },
{ startNum: "966883", operator: "Beeline" },
{ startNum: "966884", operator: "Beeline" },
{ startNum: "966885", operator: "Beeline" },
{ startNum: "966886", operator: "Beeline" },
{ startNum: "966887", operator: "Beeline" },
{ startNum: "966888", operator: "Интернод" },
{ startNum: "966889", operator: "МТТ" },
{ startNum: "96689", operator: "Beeline" },
{ startNum: "96690", operator: "Beeline" },
{ startNum: "96691", operator: "Beeline" },
{ startNum: "96692", operator: "Beeline" },
{ startNum: "96693", operator: "Beeline" },
{ startNum: "96694", operator: "Beeline" },
{ startNum: "96695", operator: "Beeline" },
{ startNum: "96696", operator: "Beeline" },
{ startNum: "96697", operator: "Beeline" },
{ startNum: "96698", operator: "Beeline" },
{ startNum: "966990", operator: "Beeline" },
{ startNum: "966991", operator: "Beeline" },
{ startNum: "966992", operator: "Beeline" },
{ startNum: "966993", operator: "Beeline" },
{ startNum: "966994", operator: "Beeline" },
{ startNum: "966995", operator: "Beeline" },
{ startNum: "966996", operator: "Beeline" },
{ startNum: "966997", operator: "Beeline" },
{ startNum: "966998", operator: "Beeline" },
{ startNum: "966999", operator: "Интернод" },
/***************************************  970  ********************************************************* */

{ startNum: "9700", operator: "Перенос/Частный" },
{ startNum: "970100", operator: "Перенос/Частный" },
{ startNum: "9701010", operator: "Ростелеком" },
{ startNum: "9701011", operator: "Перенос/Частный" },
{ startNum: "9701012", operator: "Перенос/Частный" },
{ startNum: "9701013", operator: "Перенос/Частный" },
{ startNum: "9701014", operator: "Перенос/Частный" },
{ startNum: "9701015", operator: "Перенос/Частный" },
{ startNum: "9701016", operator: "Перенос/Частный" },
{ startNum: "9701017", operator: "Перенос/Частный" },
{ startNum: "9701018", operator: "Перенос/Частный" },
{ startNum: "9701019", operator: "Перенос/Частный" },
{ startNum: "970102", operator: "Перенос/Частный" },
{ startNum: "970103", operator: "Перенос/Частный" },
{ startNum: "970104", operator: "Перенос/Частный" },
{ startNum: "970105", operator: "Перенос/Частный" },
{ startNum: "970106", operator: "Перенос/Частный" },
{ startNum: "970107", operator: "Перенос/Частный" },
{ startNum: "970108", operator: "Перенос/Частный" },
{ startNum: "970109", operator: "Перенос/Частный" },
{ startNum: "97011", operator: "Перенос/Частный" },
{ startNum: "97012", operator: "Перенос/Частный" },
{ startNum: "97013", operator: "Перенос/Частный" },
{ startNum: "97014", operator: "Перенос/Частный" },
{ startNum: "97015", operator: "Перенос/Частный" },
{ startNum: "97016", operator: "Перенос/Частный" },
{ startNum: "97017", operator: "Перенос/Частный" },
{ startNum: "97018", operator: "Перенос/Частный" },
{ startNum: "97019", operator: "Перенос/Частный" },
{ startNum: "9702", operator: "Перенос/Частный" },
{ startNum: "9703", operator: "Перенос/Частный" },
{ startNum: "9704", operator: "Перенос/Частный" },
{ startNum: "97050", operator: "Оранж Бизнес Сервисез" },
{ startNum: "97051", operator: "Перенос/Частный" },
{ startNum: "97052", operator: "Перенос/Частный" },
{ startNum: "97053", operator: "Перенос/Частный" },
{ startNum: "97054", operator: "Перенос/Частный" },
{ startNum: "97055", operator: "Перенос/Частный" },
{ startNum: "97056", operator: "Перенос/Частный" },
{ startNum: "97057", operator: "Перенос/Частный" },
{ startNum: "97058", operator: "Перенос/Частный" },
{ startNum: "97059", operator: "Перенос/Частный" },
{ startNum: "9706", operator: "Перенос/Частный" },
{ startNum: "97070", operator: "Ростелеком" },
{ startNum: "97071", operator: "Перенос/Частный" },
{ startNum: "97072", operator: "Перенос/Частный" },
{ startNum: "97073", operator: "Перенос/Частный" },
{ startNum: "97074", operator: "Перенос/Частный" },
{ startNum: "97075", operator: "Перенос/Частный" },
{ startNum: "97076", operator: "Перенос/Частный" },
{ startNum: "97077", operator: "Перенос/Частный" },
{ startNum: "97078", operator: "Перенос/Частный" },
{ startNum: "97079", operator: "Перенос/Частный" },
{ startNum: "97080", operator: "Перенос/Частный" },
{ startNum: "97081", operator: "Ростелеком" },
{ startNum: "97082", operator: "Перенос/Частный" },
{ startNum: "97083", operator: "Перенос/Частный" },
{ startNum: "97084", operator: "Перенос/Частный" },
{ startNum: "97085", operator: "Перенос/Частный" },
{ startNum: "97086", operator: "Перенос/Частный" },
{ startNum: "97087", operator: "Перенос/Частный" },
{ startNum: "97088", operator: "Перенос/Частный" },
{ startNum: "97089", operator: "Перенос/Частный" },
{ startNum: "9709", operator: "Перенос/Частный" },
/***************************************  971  ********************************************************* */

{ startNum: "9710", operator: "Перенос/Частный" },
{ startNum: "971100", operator: "Перенос/Частный" },
{ startNum: "9711010", operator: "Ростелеком" },
{ startNum: "9711011", operator: "Перенос/Частный" },
{ startNum: "9711012", operator: "Перенос/Частный" },
{ startNum: "9711013", operator: "Перенос/Частный" },
{ startNum: "9711014", operator: "Перенос/Частный" },
{ startNum: "9711015", operator: "Перенос/Частный" },
{ startNum: "9711016", operator: "Перенос/Частный" },
{ startNum: "9711017", operator: "Перенос/Частный" },
{ startNum: "9711018", operator: "Перенос/Частный" },
{ startNum: "9711019", operator: "Перенос/Частный" },
{ startNum: "971102", operator: "Перенос/Частный" },
{ startNum: "971103", operator: "Перенос/Частный" },
{ startNum: "971104", operator: "Перенос/Частный" },
{ startNum: "971105", operator: "Перенос/Частный" },
{ startNum: "971106", operator: "Перенос/Частный" },
{ startNum: "971107", operator: "Перенос/Частный" },
{ startNum: "971108", operator: "Перенос/Частный" },
{ startNum: "971109", operator: "Перенос/Частный" },
{ startNum: "97111", operator: "Перенос/Частный" },
{ startNum: "97112", operator: "Перенос/Частный" },
{ startNum: "97113", operator: "Перенос/Частный" },
{ startNum: "97114", operator: "Перенос/Частный" },
{ startNum: "97115", operator: "Перенос/Частный" },
{ startNum: "97116", operator: "Перенос/Частный" },
{ startNum: "97117", operator: "Перенос/Частный" },
{ startNum: "97118", operator: "Перенос/Частный" },
{ startNum: "97119", operator: "Перенос/Частный" },
{ startNum: "9712", operator: "Перенос/Частный" },
{ startNum: "971300", operator: "Перенос/Частный" },
{ startNum: "971301", operator: "Перенос/Частный" },
{ startNum: "971302", operator: "Перенос/Частный" },
{ startNum: "971303", operator: "Ростелеком" },
{ startNum: "971304", operator: "Перенос/Частный" },
{ startNum: "971305", operator: "Перенос/Частный" },
{ startNum: "971306", operator: "Перенос/Частный" },
{ startNum: "971307", operator: "Перенос/Частный" },
{ startNum: "971308", operator: "Перенос/Частный" },
{ startNum: "971309", operator: "Перенос/Частный" },
{ startNum: "97131", operator: "Перенос/Частный" },
{ startNum: "97132", operator: "Перенос/Частный" },
{ startNum: "97133", operator: "Перенос/Частный" },
{ startNum: "97134", operator: "Перенос/Частный" },
{ startNum: "97135", operator: "Ростелеком" },
{ startNum: "97136", operator: "Перенос/Частный" },
{ startNum: "97137", operator: "Перенос/Частный" },
{ startNum: "97138", operator: "Перенос/Частный" },
{ startNum: "97139", operator: "Перенос/Частный" },
{ startNum: "9714", operator: "Перенос/Частный" },
{ startNum: "97150", operator: "Оранж Бизнес Сервисез" },
{ startNum: "97151", operator: "Перенос/Частный" },
{ startNum: "97152", operator: "Перенос/Частный" },
{ startNum: "97153", operator: "Перенос/Частный" },
{ startNum: "97154", operator: "Перенос/Частный" },
{ startNum: "97155", operator: "Перенос/Частный" },
{ startNum: "97156", operator: "Перенос/Частный" },
{ startNum: "97157", operator: "Перенос/Частный" },
{ startNum: "97158", operator: "Перенос/Частный" },
{ startNum: "97159", operator: "Перенос/Частный" },
{ startNum: "9716", operator: "Перенос/Частный" },
{ startNum: "97170", operator: "Ростелеком" },
{ startNum: "97171", operator: "Перенос/Частный" },
{ startNum: "97172", operator: "Перенос/Частный" },
{ startNum: "97173", operator: "Перенос/Частный" },
{ startNum: "97174", operator: "Перенос/Частный" },
{ startNum: "97175", operator: "Перенос/Частный" },
{ startNum: "97176", operator: "Перенос/Частный" },
{ startNum: "97177", operator: "Перенос/Частный" },
{ startNum: "97178", operator: "Перенос/Частный" },
{ startNum: "97179", operator: "Перенос/Частный" },
{ startNum: "97180", operator: "Перенос/Частный" },
{ startNum: "97181", operator: "Ростелеком" },
{ startNum: "97182", operator: "Перенос/Частный" },
{ startNum: "97183", operator: "Перенос/Частный" },
{ startNum: "97184", operator: "Перенос/Частный" },
{ startNum: "97185", operator: "Перенос/Частный" },
{ startNum: "97186", operator: "Перенос/Частный" },
{ startNum: "97187", operator: "Перенос/Частный" },
{ startNum: "97188", operator: "Перенос/Частный" },
{ startNum: "97189", operator: "Перенос/Частный" },
{ startNum: "9719", operator: "Перенос/Частный" },
/***************************************  979  ********************************************************* */

{ startNum: "97900", operator: "Миранда-медиа" },
{ startNum: "97901", operator: "Миранда-медиа" },
{ startNum: "97902", operator: "Миранда-медиа" },
{ startNum: "97903", operator: "Миранда-медиа" },
{ startNum: "97904", operator: "Миранда-медиа" },
{ startNum: "97905", operator: "Перенос/Частный" },
{ startNum: "97906", operator: "Перенос/Частный" },
{ startNum: "97907", operator: "Перенос/Частный" },
{ startNum: "97908", operator: "Перенос/Частный" },
{ startNum: "97909", operator: "Перенос/Частный" },
{ startNum: "9791", operator: "Перенос/Частный" },
{ startNum: "9792", operator: "Перенос/Частный" },
{ startNum: "9793", operator: "Перенос/Частный" },
{ startNum: "9794", operator: "Перенос/Частный" },
{ startNum: "9795", operator: "Перенос/Частный" },
{ startNum: "9796", operator: "Перенос/Частный" },
{ startNum: "9797", operator: "Перенос/Частный" },
{ startNum: "9798", operator: "Перенос/Частный" },
{ startNum: "9799", operator: "Перенос/Частный" },
/***************************************  980  ********************************************************* */

{ startNum: "9800", operator: "МТС" },
{ startNum: "9801", operator: "МТС" },
{ startNum: "98020", operator: "МТС" },
{ startNum: "98021", operator: "МТС" },
{ startNum: "980220", operator: "МТТ" },
{ startNum: "980221", operator: "МТТ" },
{ startNum: "980222", operator: "МТТ" },
{ startNum: "980223", operator: "Сбербанк-Телеком" },
{ startNum: "980224", operator: "Сбербанк-Телеком" },
{ startNum: "980225", operator: "Сбербанк-Телеком" },
{ startNum: "980226", operator: "Сбербанк-Телеком" },
{ startNum: "980227", operator: "Сбербанк-Телеком" },
{ startNum: "980228", operator: "Сбербанк-Телеком" },
{ startNum: "980229", operator: "Сбербанк-Телеком" },
{ startNum: "98023", operator: "МТС" },
{ startNum: "98024", operator: "МТС" },
{ startNum: "980250", operator: "МТС" },
{ startNum: "980251", operator: "МТС" },
{ startNum: "980252", operator: "МТС" },
{ startNum: "980253", operator: "МТС" },
{ startNum: "980254", operator: "МТТ" },
{ startNum: "980255", operator: "МТТ" },
{ startNum: "980256", operator: "МТТ" },
{ startNum: "980257", operator: "МТТ" },
{ startNum: "980258", operator: "МТТ" },
{ startNum: "980259", operator: "МТТ" },
{ startNum: "98026", operator: "МТС" },
{ startNum: "98027", operator: "МТС" },
{ startNum: "98028", operator: "МТС" },
{ startNum: "98029", operator: "МТС" },
{ startNum: "9803", operator: "МТС" },
{ startNum: "98040", operator: "МТС" },
{ startNum: "98041", operator: "МТС" },
{ startNum: "98042", operator: "МТС" },
{ startNum: "98043", operator: "МТС" },
{ startNum: "980440", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "980441", operator: "ЭКСПРЕСС МОБАЙЛ/Частный" },
{ startNum: "980442", operator: "ЭКСПРЕСС МОБАЙЛ/Частный" },
{ startNum: "980443", operator: "ЭКСПРЕСС МОБАЙЛ/Частный" },
{ startNum: "980444", operator: "БЕЗЛИМИТ" },
{ startNum: "980445", operator: "МТС" },
{ startNum: "980446", operator: "МТС" },
{ startNum: "980447", operator: "МТС" },
{ startNum: "980448", operator: "МТС" },
{ startNum: "980449", operator: "МТС" },
{ startNum: "98045", operator: "МТС" },
{ startNum: "98046", operator: "МТС" },
{ startNum: "98047", operator: "МТС" },
{ startNum: "98048", operator: "МТС" },
{ startNum: "98049", operator: "МТС" },
{ startNum: "9805", operator: "МТС" },
{ startNum: "9806", operator: "МТС" },
{ startNum: "9807", operator: "МТС" },
{ startNum: "98080", operator: "МТС" },
{ startNum: "980800", operator: "МАТРИКС телеком" },
{ startNum: "980801", operator: "Сбербанк-Телеком" },
{ startNum: "980802", operator: "Сбербанк-Телеком" },
{ startNum: "980803", operator: "Сбербанк-Телеком" },
{ startNum: "980804", operator: "Сбербанк-Телеком" },
{ startNum: "980805", operator: "Сбербанк-Телеком" },
{ startNum: "980806", operator: "Сбербанк-Телеком" },
{ startNum: "980807", operator: "Сбербанк-Телеком" },
{ startNum: "980808", operator: "МТС" },
{ startNum: "9808080", operator: "МТС" },
{ startNum: "9808081", operator: "МТС" },
{ startNum: "9808082", operator: "МТС" },
{ startNum: "9808083", operator: "МТС" },
{ startNum: "9808084", operator: "МТС" },
{ startNum: "9808085", operator: "МТС" },
{ startNum: "9808086", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "9808087", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "9808088", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "9808089", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "980809", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "98081", operator: "МТС" },
{ startNum: "980810", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "980811", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "980812", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "980813", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "980814", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "980815", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "980816", operator: "МТС/Частный" },
{ startNum: "980817", operator: "МТС/Частный" },
{ startNum: "980818", operator: "МТС/Частный" },
{ startNum: "980819", operator: "МТС/Частный" },
{ startNum: "98082", operator: "МТС" },
{ startNum: "98083", operator: "МТС" },
{ startNum: "98084", operator: "МТС" },
{ startNum: "98085", operator: "МТС" },
{ startNum: "98086", operator: "МТС" },
{ startNum: "98087", operator: "МТС" },
{ startNum: "98088", operator: "МТС" },
{ startNum: "980880", operator: "МТС/Частный" },
{ startNum: "980881", operator: "МТС/Частный" },
{ startNum: "980882", operator: "МТС/Частный" },
{ startNum: "980883", operator: "МТС/Частный" },
{ startNum: "980884", operator: "МТС/Частный" },
{ startNum: "980885", operator: "МТС/Частный" },
{ startNum: "980886", operator: "МТС/Частный" },
{ startNum: "980887", operator: "МТС/Частный" },
{ startNum: "980888", operator: "МТТ" },
{ startNum: "980889", operator: "Сбербанк-Телеком" },
{ startNum: "98089", operator: "Сбербанк-Телеком" },
{ startNum: "9809", operator: "МТС" },
{ startNum: "98090", operator: "МТС" },
{ startNum: "980900", operator: "МТС" },
{ startNum: "9809000", operator: "МТТ" },
{ startNum: "9809001", operator: "МТТ" },
{ startNum: "9809002", operator: "МТТ" },
{ startNum: "9809003", operator: "МТТ" },
{ startNum: "9809004", operator: "МТТ" },
{ startNum: "9809005", operator: "Газпром телеком" },
{ startNum: "9809006", operator: "Газпром телеком" },
{ startNum: "9809007", operator: "Интернод" },
{ startNum: "9809008", operator: "Интернод" },
{ startNum: "9809009", operator: "Интернод" },
{ startNum: "980901", operator: "Сбербанк-Телеком" },
{ startNum: "980902", operator: "Сбербанк-Телеком" },
{ startNum: "980903", operator: "Сбербанк-Телеком" },
{ startNum: "980904", operator: "Сбербанк-Телеком" },
{ startNum: "980905", operator: "Сбербанк-Телеком" },
{ startNum: "980906", operator: "Сбербанк-Телеком" },
{ startNum: "980907", operator: "Сбербанк-Телеком" },
{ startNum: "980908", operator: "Сбербанк-Телеком" },
{ startNum: "980909", operator: "Сбербанк-Телеком" },
{ startNum: "98091", operator: "Сбербанк-Телеком" },
{ startNum: "98092", operator: "Сбербанк-Телеком" },
{ startNum: "98093", operator: "Сбербанк-Телеком" },
{ startNum: "98094", operator: "Сбербанк-Телеком" },
{ startNum: "98095", operator: "Сбербанк-Телеком/МТТ" },
{ startNum: "98096", operator: "Сбербанк-Телеком/МТТ" },
{ startNum: "98097", operator: "Сбербанк-Телеком/МТТ" },
{ startNum: "98098", operator: "Сбербанк-Телеком" },
{ startNum: "98099", operator: "Частный" },
{ startNum: "980990", operator: "Частный" },
{ startNum: "980991", operator: "Частный" },
{ startNum: "980992", operator: "Частный" },
{ startNum: "980993", operator: "Частный" },
{ startNum: "980994", operator: "Частный" },
{ startNum: "980995", operator: "Частный" },
{ startNum: "980996", operator: "Частный" },
{ startNum: "980997", operator: "Частный" },
{ startNum: "980998", operator: "Частный" },
{ startNum: "980999", operator: "Билайн" },
/***************************************  981  ********************************************************* */

{ startNum: "9810", operator: "МТС" },
{ startNum: "98100", operator: "МТС" },
{ startNum: "98101", operator: "МТС" },
{ startNum: "98102", operator: "МТС" },
{ startNum: "98103", operator: "МТС" },
{ startNum: "98104", operator: "МТС" },
{ startNum: "98105", operator: "МТС" },
{ startNum: "98106", operator: "МТС" },
{ startNum: "981070", operator: "МТС" },
{ startNum: "981071", operator: "МТС" },
{ startNum: "981072", operator: "МТС" },
{ startNum: "981073", operator: "МТС" },
{ startNum: "981074", operator: "МТС" },
{ startNum: "981075", operator: "МТС" },
{ startNum: "981076", operator: "МТС" },
{ startNum: "981077", operator: "МТТ" },
{ startNum: "981078", operator: "МТТ" },
{ startNum: "981079", operator: "МТТ" },
{ startNum: "98108", operator: "МТС" },
{ startNum: "98109", operator: "МТС" },
{ startNum: "981000", operator: "МТС" },
{ startNum: "981001", operator: "МТС" },
{ startNum: "981002", operator: "МТС" },
{ startNum: "981003", operator: "МТС" },
{ startNum: "981004", operator: "МТС" },
{ startNum: "981005", operator: "МТС" },
{ startNum: "981006", operator: "МТС" },
{ startNum: "981007", operator: "МТС" },
{ startNum: "981008", operator: "МТС" },
{ startNum: "981009", operator: "МТС" },
{ startNum: "9810000", operator: "Спринт" },
{ startNum: "9810001", operator: "Спринт" },
{ startNum: "9810002", operator: "Спринт" },
{ startNum: "9810003", operator: "Спринт" },
{ startNum: "9810004", operator: "Спринт" },
{ startNum: "9810005", operator: "Спринт" },
{ startNum: "9810006", operator: "Спринт" },
{ startNum: "9810007", operator: "Спринт" },
{ startNum: "9810008", operator: "Спринт" },
{ startNum: "9810009", operator: "Спринт" },
{ startNum: "9811", operator: "МТС" },
{ startNum: "9812", operator: "МТС" },
{ startNum: "98120", operator: "МТС" },
{ startNum: "98121", operator: "МТС" },
{ startNum: "98122", operator: "МТС" },
{ startNum: "98123", operator: "МТС" },
{ startNum: "98124", operator: "МТС" },
{ startNum: "98125", operator: "МТС" },
{ startNum: "98126", operator: "МТС" },
{ startNum: "98127", operator: "МТС" },
{ startNum: "98128", operator: "МТС" },
{ startNum: "981290", operator: "МТС" },
{ startNum: "981291", operator: "МТС" },
{ startNum: "981292", operator: "МТС" },
{ startNum: "981293", operator: "МТС" },
{ startNum: "981294", operator: "МТС" },
{ startNum: "981295", operator: "МТС" },
{ startNum: "981296", operator: "МТС" },
{ startNum: "981297", operator: "МТС" },
{ startNum: "981298", operator: "МТС" },
{ startNum: "981299", operator: "Интернод" },
{ startNum: "9813", operator: "МТС" },
{ startNum: "98130", operator: "МТС" },
{ startNum: "98131", operator: "МТС" },
{ startNum: "981320", operator: "МТС" },
{ startNum: "981321", operator: "МТС" },
{ startNum: "981322", operator: "МТС" },
{ startNum: "981323", operator: "МТС" },
{ startNum: "981324", operator: "МТС" },
{ startNum: "981325", operator: "МТТ" },
{ startNum: "981326", operator: "МТТ" },
{ startNum: "981327", operator: "МТТ" },
{ startNum: "981328", operator: "МТТ" },
{ startNum: "981329", operator: "МТТ" },
{ startNum: "98133", operator: "МТТ" },
{ startNum: "98134", operator: "МТС" },
{ startNum: "98135", operator: "МТС" },
{ startNum: "98136", operator: "МТС" },
{ startNum: "98137", operator: "МТС" },
{ startNum: "98138", operator: "МТС" },
{ startNum: "98139", operator: "МТС" },
{ startNum: "9814", operator: "МТС" },
{ startNum: "9815", operator: "МТС" },
{ startNum: "9816", operator: "МТС" },
{ startNum: "9817", operator: "МТС" },
{ startNum: "98180", operator: "МТС" },
{ startNum: "98181", operator: "МТС" },
{ startNum: "98182", operator: "МТС" },
{ startNum: "98183", operator: "МТС" },
{ startNum: "98184", operator: "МТС" },
{ startNum: "98185", operator: "МТС" },
{ startNum: "981860", operator: "МТС" },
{ startNum: "981861", operator: "МТС" },
{ startNum: "981862", operator: "МТС" },
{ startNum: "981863", operator: "МТС" },
{ startNum: "981864", operator: "МТС" },
{ startNum: "981865", operator: "МТТ" },
{ startNum: "981866", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "981867", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "981868", operator: "МТС/ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "981869", operator: "МТС/ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "98187", operator: "МТС" },
{ startNum: "98188", operator: "МТС" },
{ startNum: "98189", operator: "МТС" },
{ startNum: "9819", operator: "МТС" },
/***************************************  982  ********************************************************* */

{ startNum: "982000", operator: "Спринт" },
{ startNum: "982001", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "982002", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "982003", operator: "МТС" },
{ startNum: "982004", operator: "МТС" },
{ startNum: "982005", operator: "МТС" },
{ startNum: "982006", operator: "МТС" },
{ startNum: "9820070", operator: "МТС" },
{ startNum: "9820071", operator: "МТС" },
{ startNum: "9820072", operator: "МТС" },
{ startNum: "9820073", operator: "МТС" },
{ startNum: "9820074", operator: "МТС" },
{ startNum: "9820075", operator: "МТС" },
{ startNum: "9820076", operator: "МТС" },
{ startNum: "9820077", operator: "МТТ" },
{ startNum: "9820078", operator: "МТТ" },
{ startNum: "9820079", operator: "МТТ" },
{ startNum: "982008", operator: "МТС" },
{ startNum: "982009", operator: "МТС" },
{ startNum: "98201", operator: "МТС" },
{ startNum: "98202", operator: "МТС" },
{ startNum: "98203", operator: "МТС" },
{ startNum: "98204", operator: "МТС" },
{ startNum: "98205", operator: "МТС" },
{ startNum: "98206", operator: "МТС" },
{ startNum: "98207", operator: "МТС" },
{ startNum: "98208", operator: "МТС" },
{ startNum: "98209", operator: "МТС" },
{ startNum: "9821", operator: "МТС" },
{ startNum: "9822", operator: "МТС" },
{ startNum: "9823", operator: "МТС" },
{ startNum: "9824", operator: "МТС" },
{ startNum: "9825", operator: "МТС" },
{ startNum: "9826", operator: "МТС" },
{ startNum: "9827", operator: "МТС" },
{ startNum: "9828", operator: "МТС" },
{ startNum: "9829", operator: "МТС" },
/***************************************  983  ********************************************************* */

{ startNum: "98300", operator: "МТС" },
{ startNum: "98301", operator: "МТС" },
{ startNum: "98302", operator: "МТС" },
{ startNum: "98303", operator: "МТС" },
{ startNum: "98304", operator: "МТС" },
{ startNum: "98305", operator: "МТС" },
{ startNum: "98306", operator: "МТС" },
{ startNum: "983070", operator: "МТС" },
{ startNum: "983071", operator: "МТТ" },
{ startNum: "983072", operator: "МТТ" },
{ startNum: "983073", operator: "МТТ" },
{ startNum: "983074", operator: "МТТ" },
{ startNum: "983075", operator: "МТС" },
{ startNum: "983076", operator: "МТТ" },
{ startNum: "983077", operator: "МТС" },
{ startNum: "983078", operator: "МТТ" },
{ startNum: "983079", operator: "МТТ" },
{ startNum: "98308", operator: "МТС" },
{ startNum: "98309", operator: "МТС" },
{ startNum: "9831", operator: "МТС" },
{ startNum: "9832", operator: "МТС" },
{ startNum: "9833", operator: "МТС" },
{ startNum: "9834", operator: "МТС" },
{ startNum: "9835", operator: "МТС" },
{ startNum: "98360", operator: "МТС" },
{ startNum: "98361", operator: "МТС" },
{ startNum: "98362", operator: "МТС" },
{ startNum: "98363", operator: "МТС" },
{ startNum: "98364", operator: "МТС" },
{ startNum: "98365", operator: "МТС" },
{ startNum: "9836600", operator: "МТС" },
{ startNum: "9836601", operator: "МТТ" },
{ startNum: "9836602", operator: "МТТ" },
{ startNum: "9836603", operator: "МТТ" },
{ startNum: "9836604", operator: "МТТ" },
{ startNum: "9836605", operator: "МТТ" },
{ startNum: "9836606", operator: "МТТ" },
{ startNum: "9836607", operator: "МТТ" },
{ startNum: "9836608", operator: "МТТ" },
{ startNum: "9836609", operator: "МТТ" },
{ startNum: "983661", operator: "МТС/МТТ" },
{ startNum: "983662", operator: "МТТ" },
{ startNum: "983663", operator: "МТТ" },
{ startNum: "983664", operator: "МТТ" },
{ startNum: "983665", operator: "МТТ" },
{ startNum: "983666", operator: "МТТ" },
{ startNum: "983667", operator: "МТС" },
{ startNum: "983668", operator: "МТС" },
{ startNum: "983669", operator: "МТС" },
{ startNum: "98367", operator: "МТС" },
{ startNum: "98368", operator: "МТС" },
{ startNum: "98369", operator: "МТС" },
{ startNum: "98370", operator: "МТС" },
{ startNum: "9837000", operator: "МТТ" },
{ startNum: "9837001", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "9837002", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "9837003", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "9837004", operator: "ЭКСПРЕСС МОБАЙЛ" },
{ startNum: "9837005", operator: "МТС/Частный" },
{ startNum: "9837006", operator: "МТС/Частный" },
{ startNum: "9837007", operator: "МТС/Частный" },
{ startNum: "9837008", operator: "МТС/Частный" },
{ startNum: "9837009", operator: "МТС/Частный" },
{ startNum: "98371", operator: "МТС/Частный" },
{ startNum: "98372", operator: "МТС/Частный" },
{ startNum: "98373", operator: "МТС/Частный" },
{ startNum: "98374", operator: "МТС/Частный" },
{ startNum: "98375", operator: "МТС/Частный" },
{ startNum: "98376", operator: "МТС/Частный" },
{ startNum: "98377", operator: "МТС/Частный" },
{ startNum: "98378", operator: "МТС/Частный" },
{ startNum: "98379", operator: "МТС/Частный" },
{ startNum: "98380", operator: "МТС/Частный" },
{ startNum: "98381", operator: "МТС/Частный" },
{ startNum: "98382", operator: "МТС/Частный" },
{ startNum: "98383", operator: "МТС/Частный" },
{ startNum: "98384", operator: "МТС/Частный" },
{ startNum: "98385", operator: "МТС/Частный" },
{ startNum: "98386", operator: "МТС/Частный" },
{ startNum: "98387", operator: "МТС/Частный" },
{ startNum: "98388", operator: "Билайн" },
{ startNum: "98389", operator: "МТС/Частный" },
{ startNum: "98390", operator: "МТС/Частный" },
{ startNum: "98391", operator: "МТС/Частный" },
{ startNum: "98392", operator: "МТС/Частный" },
{ startNum: "98393", operator: "МТС/Частный" },
{ startNum: "98394", operator: "МТС/Частный" },
{ startNum: "98395", operator: "МТС/Частный" },
{ startNum: "98396", operator: "МТС/Частный" },
{ startNum: "98397", operator: "МТС/Частный" },
{ startNum: "98398", operator: "МТС/Частный" },
{ startNum: "98399", operator: "Билайн" },

/***************************************  984  ********************************************************* */

{ startNum: "98400", operator: "МТС" },
{ startNum: "98401", operator: "МТС" },
{ startNum: "98402", operator: "МТС" },
{ startNum: "98403", operator: "МТС" },
{ startNum: "98404", operator: "МТС" },
{ startNum: "98405", operator: "МТС" },
{ startNum: "98406", operator: "МТС" },
{ startNum: "98407", operator: "МТС" },
{ startNum: "98408", operator: "МТС" },
{ startNum: "98409", operator: "МТС" },
{ startNum: "984000", operator: "БЕЗЛИМИТ" },
{ startNum: "984001", operator: "МТС" },
{ startNum: "984002", operator: "МТС" },
{ startNum: "984003", operator: "МТС" },
{ startNum: "984004", operator: "МТС" },
{ startNum: "984005", operator: "МТС" },
{ startNum: "984006", operator: "МТС" },
{ startNum: "984007", operator: "МТС" },
{ startNum: "984008", operator: "МТС" },
{ startNum: "984009", operator: "МТС" },
{ startNum: "98410", operator: "МТС" },
{ startNum: "98411", operator: "МТС" },
{ startNum: "98412", operator: "МТС" },
{ startNum: "98413", operator: "МТС" },
{ startNum: "98414", operator: "МТС" },
{ startNum: "98415", operator: "МТС" },
{ startNum: "98416", operator: "МТС" },
{ startNum: "98417", operator: "МТС" },
{ startNum: "984180", operator: "МТС" },
{ startNum: "984181", operator: "МТС" },
{ startNum: "984182", operator: "МТС" },
{ startNum: "984183", operator: "МТС" },
{ startNum: "984184", operator: "МТС" },
{ startNum: "984185", operator: "МТТ" },
{ startNum: "984186", operator: "МТТ" },
{ startNum: "984187", operator: "МТТ" },
{ startNum: "984188", operator: "МТС" },
{ startNum: "984189", operator: "МТС" },
{ startNum: "98419", operator: "МТС" },
{ startNum: "98420", operator: "МТС" },
{ startNum: "98421", operator: "МТС" },
{ startNum: "98422", operator: "МТС" },
{ startNum: "98423", operator: "МТТ" },
{ startNum: "98424", operator: "МТТ" },
{ startNum: "984250", operator: "МТС" },
{ startNum: "984251", operator: "МТТ" },
{ startNum: "984252", operator: "МТТ" },
{ startNum: "984253", operator: "МТТ" },
{ startNum: "984254", operator: "МТТ" },
{ startNum: "984255", operator: "МТС" },
{ startNum: "984256", operator: "МТТ" },
{ startNum: "984257", operator: "МТС" },
{ startNum: "984258", operator: "МТС" },
{ startNum: "984259", operator: "МТС" },
{ startNum: "98426", operator: "МТТ" },
{ startNum: "98427", operator: "МТС" },
{ startNum: "98428", operator: "МТС" },
{ startNum: "98429", operator: "МТС" },
{ startNum: "98430", operator: "МТС" },
{ startNum: "98431", operator: "МТС" },
{ startNum: "98432", operator: "МТС" },
{ startNum: "984330", operator: "МТС" },
{ startNum: "984331", operator: "МТС" },
{ startNum: "984332", operator: "МТС" },
{ startNum: "984333", operator: "Интернод" },
{ startNum: "984334", operator: "МТС" },
{ startNum: "984335", operator: "МТС" },
{ startNum: "984336", operator: "МТС" },
{ startNum: "984337", operator: "МТС" },
{ startNum: "984338", operator: "МТС" },
{ startNum: "984339", operator: "МТС" },
{ startNum: "98434", operator: "МТС" },
{ startNum: "98435", operator: "МТС" },
{ startNum: "98436", operator: "МТС" },
{ startNum: "98437", operator: "МТС" },
{ startNum: "98438", operator: "МТС" },
{ startNum: "98439", operator: "МТС" },
{ startNum: "98440", operator: "МТС" },
{ startNum: "98441", operator: "МТС" },
{ startNum: "98442", operator: "МТС" },
{ startNum: "98443", operator: "МТС" },
{ startNum: "984440", operator: "МТС" },
{ startNum: "984441", operator: "МТС" },
{ startNum: "984442", operator: "МТС" },
{ startNum: "984443", operator: "МТС" },
{ startNum: "984444", operator: "Спринт" },
{ startNum: "984445", operator: "НМК" },
{ startNum: "984446", operator: "НМК" },
{ startNum: "984447", operator: "НМК" },
{ startNum: "984448", operator: "НМК" },
{ startNum: "984449", operator: "НМК" },
{ startNum: "984460", operator: "НМК" },
{ startNum: "984461", operator: "НМК" },
{ startNum: "984462", operator: "НМК" },
{ startNum: "984463", operator: "НМК" },
{ startNum: "984464", operator: "НМК" },
{ startNum: "984465", operator: "МТС" },
{ startNum: "984466", operator: "МТС" },
{ startNum: "984467", operator: "МТС" },
{ startNum: "984468", operator: "МТС" },
{ startNum: "984469", operator: "МТС" },
{ startNum: "98445", operator: "НМК" },
{ startNum: "98447", operator: "МТС" },
{ startNum: "98448", operator: "МТС" },
{ startNum: "98449", operator: "МТС" },
{ startNum: "9845000", operator: "МТС" },
{ startNum: "9845001", operator: "МТС" },
{ startNum: "9845002", operator: "МТС" },
{ startNum: "9845003", operator: "МТС" },
{ startNum: "9845004", operator: "Частный" },
{ startNum: "9845005", operator: "Частный" },
{ startNum: "9845006", operator: "Частный" },
{ startNum: "9845007", operator: "Частный" },
{ startNum: "9845008", operator: "Частный" },
{ startNum: "9845009", operator: "Частный" },
{ startNum: "984501", operator: "МТС" },
{ startNum: "984502", operator: "МТС" },
{ startNum: "984503", operator: "МТС" },
{ startNum: "984504", operator: "МТС" },
{ startNum: "984505", operator: "МТС" },
{ startNum: "984506", operator: "МТС" },
{ startNum: "984507", operator: "МТС" },
{ startNum: "984508", operator: "МТС" },
{ startNum: "984509", operator: "МТС" },
{ startNum: "98451", operator: "МТС" },
{ startNum: "98452", operator: "МТС" },
{ startNum: "98453", operator: "МТС" },
{ startNum: "98454", operator: "МТС" },
{ startNum: "984550", operator: "МТС" },
{ startNum: "984551", operator: "МТС" },
{ startNum: "984552", operator: "МТС" },
{ startNum: "984553", operator: "МТС" },
{ startNum: "984554", operator: "МТС" },
{ startNum: "984555", operator: "МТТ" },
{ startNum: "984556", operator: "МТС" },
{ startNum: "984557", operator: "МТС" },
{ startNum: "984558", operator: "МТС" },
{ startNum: "984559", operator: "МТС" },
{ startNum: "98456", operator: "МТС" },
{ startNum: "98457", operator: "МТС" },
{ startNum: "98458", operator: "МТС" },
{ startNum: "98459", operator: "МТС" },
{ startNum: "98460", operator: "МТС" },
{ startNum: "98461", operator: "МТС" },
{ startNum: "98462", operator: "МТС" },
{ startNum: "98463", operator: "МТС" },
{ startNum: "98464", operator: "МТС" },
{ startNum: "98465", operator: "МТС" },
{ startNum: "98466", operator: "МТТ" },
{ startNum: "98467", operator: "МТС" },
{ startNum: "98468", operator: "МТС" },
{ startNum: "98469", operator: "МТС" },
{ startNum: "984700", operator: "МТТ" },
{ startNum: "984701", operator: "Частный" },
{ startNum: "984702", operator: "Частный" },
{ startNum: "984703", operator: "Частный" },
{ startNum: "984704", operator: "Частный" },
{ startNum: "984705", operator: "Частный" },
{ startNum: "984706", operator: "Частный" },
{ startNum: "984707", operator: "Спринт" },
{ startNum: "984708", operator: "МТТ" },
{ startNum: "984709", operator: "МТТ" },
{ startNum: "98471", operator: "НМК" },
{ startNum: "98472", operator: "НМК/МТТ" },
{ startNum: "98473", operator: "Частный" },
{ startNum: "98474", operator: "Частный" },
{ startNum: "98475", operator: "Частный" },
{ startNum: "98476", operator: "Частный" },
{ startNum: "984770", operator: "Частный" },
{ startNum: "984771", operator: "Частный" },
{ startNum: "984772", operator: "Частный" },
{ startNum: "984773", operator: "Частный" },
{ startNum: "984774", operator: "Частный" },
{ startNum: "984775", operator: "Частный" },
{ startNum: "984776", operator: "Частный" },
{ startNum: "984777", operator: "Спринт" },
{ startNum: "984778", operator: "Частный" },
{ startNum: "984779", operator: "Частный" },
{ startNum: "98478", operator: "НМК" },
{ startNum: "98479", operator: "НМК" },
{ startNum: "9848", operator: "Спринт/Частный" },
{ startNum: "9849", operator: "Спринт/Частный" },
/***************************************  985  ********************************************************* */

{ startNum: "9850", operator: "МТС" },
{ startNum: "9851", operator: "МТС" },
{ startNum: "98520", operator: "МТС" },
{ startNum: "985210", operator: "МТС" },
{ startNum: "985211", operator: "МТС" },
{ startNum: "985212", operator: "МТС" },
{ startNum: "985213", operator: "МТС" },
{ startNum: "985214", operator: "МТС" },
{ startNum: "985215", operator: "МТС" },
{ startNum: "985216", operator: "МТС" },
{ startNum: "985217", operator: "МТС" },
{ startNum: "985218", operator: "МТС" },
{ startNum: "9852190", operator: "МТС" },
{ startNum: "9852191", operator: "МТС" },
{ startNum: "9852192", operator: "МТС" },
{ startNum: "9852193", operator: "Цифра групп" },
{ startNum: "9852194", operator: "Цифра групп" },
{ startNum: "9852195", operator: "Цифра групп" },
{ startNum: "9852196", operator: "МиАТел" },
{ startNum: "9852197", operator: "МиАТел" },
{ startNum: "9852198", operator: "МТС" },
{ startNum: "9852199", operator: "МТС" },
{ startNum: "98522", operator: "МТС" },
{ startNum: "98523", operator: "МТС" },
{ startNum: "98524", operator: "МТС" },
{ startNum: "98525", operator: "МТС" },
{ startNum: "98526", operator: "МТС" },
{ startNum: "98527", operator: "МТС" },
{ startNum: "98528", operator: "МТС" },
{ startNum: "98529", operator: "МТС" },
{ startNum: "9853", operator: "МТС" },
{ startNum: "98540", operator: "МТС" },
{ startNum: "98541", operator: "МТС" },
{ startNum: "98542", operator: "МТС" },
{ startNum: "98543", operator: "МТС" },
{ startNum: "98544", operator: "МТС" },
{ startNum: "98545", operator: "МТС" },
{ startNum: "98546", operator: "МТС" },
{ startNum: "98547", operator: "МТС" },
{ startNum: "98548", operator: "МТС" },
{ startNum: "985490", operator: "МТС" },
{ startNum: "985491", operator: "МТС" },
{ startNum: "985492", operator: "МТС" },
{ startNum: "985493", operator: "МТС" },
{ startNum: "985494", operator: "МТС" },
{ startNum: "985495", operator: "МТС" },
{ startNum: "985496", operator: "МТС" },
{ startNum: "985497", operator: "МТС" },
{ startNum: "985498", operator: "МТС" },
{ startNum: "985499", operator: "МТТ" },
{ startNum: "9855", operator: "МТС" },
{ startNum: "985600", operator: "МТС" },
{ startNum: "985601", operator: "МТТ" },
{ startNum: "985602", operator: "МТТ" },
{ startNum: "985603", operator: "МТТ" },
{ startNum: "985604", operator: "МТС" },
{ startNum: "985605", operator: "МТТ" },
{ startNum: "985606", operator: "МТС" },
{ startNum: "985607", operator: "МТС" },
{ startNum: "985608", operator: "МТС" },
{ startNum: "985609", operator: "МТС" },
{ startNum: "98561", operator: "МТС" },
{ startNum: "98562", operator: "МТС" },
{ startNum: "98563", operator: "МТС" },
{ startNum: "98564", operator: "МТС" },
{ startNum: "985650", operator: "МТС" },
{ startNum: "985651", operator: "МТС" },
{ startNum: "985652", operator: "МТС" },
{ startNum: "985653", operator: "МТС" },
{ startNum: "985654", operator: "МТС" },
{ startNum: "9856550", operator: "МТС" },
{ startNum: "9856551", operator: "МТС" },
{ startNum: "9856552", operator: "Цифра групп" },
{ startNum: "9856553", operator: "Цифра групп" },
{ startNum: "9856554", operator: "Цифра групп" },
{ startNum: "9856555", operator: "МТТ" },
{ startNum: "9856556", operator: "МТТ" },
{ startNum: "9856557", operator: "МТТ" },
{ startNum: "9856558", operator: "МТТ" },
{ startNum: "9856559", operator: "МТТ" },
{ startNum: "985656", operator: "МТС" },
{ startNum: "985657", operator: "МТС" },
{ startNum: "985658", operator: "МТС" },
{ startNum: "985659", operator: "МТС" },
{ startNum: "98566", operator: "МТС" },
{ startNum: "98567", operator: "МТТ" },
{ startNum: "98568", operator: "МТС" },
{ startNum: "98569", operator: "МТС" },
{ startNum: "985700", operator: "МТС" },
{ startNum: "985701", operator: "МТТ" },
{ startNum: "985702", operator: "МТТ" },
{ startNum: "985703", operator: "МТТ/ВТК-Мобайл" },
{ startNum: "985704", operator: "МТС" },
{ startNum: "985705", operator: "Лайкамобайл" },
{ startNum: "985706", operator: "Лайкамобайл" },
{ startNum: "985707", operator: "Лайкамобайл" },
{ startNum: "985708", operator: "Лайкамобайл" },
{ startNum: "985709", operator: "Лайкамобайл" },
{ startNum: "98571", operator: "МТС" },
{ startNum: "98572", operator: "МТС" },
{ startNum: "98573", operator: "МТС" },
{ startNum: "98574", operator: "МТС" },
{ startNum: "98575", operator: "МТС" },
{ startNum: "98576", operator: "МТС" },
{ startNum: "98577", operator: "МТС" },
{ startNum: "98578", operator: "МТС" },
{ startNum: "98579", operator: "МТС" },
{ startNum: "9858", operator: "МТС" },
{ startNum: "9859000", operator: "МТТ" },
{ startNum: "9859001", operator: "МТТ" },
{ startNum: "9859002", operator: "ВТК-МОБАЙЛ" },
{ startNum: "9859003", operator: "ВТК-МОБАЙЛ" },
{ startNum: "9859004", operator: "ВТК-МОБАЙЛ" },
{ startNum: "9859005", operator: "ВТК-МОБАЙЛ" },
{ startNum: "9859006", operator: "ВТК-МОБАЙЛ" },
{ startNum: "9859007", operator: "ВТК-МОБАЙЛ" },
{ startNum: "9859008", operator: "ВТК-МОБАЙЛ" },
{ startNum: "9859009", operator: "ВТК-МОБАЙЛ" },
{ startNum: "985901", operator: "МТТ" },
{ startNum: "985902", operator: "МТТ" },
{ startNum: "985903", operator: "МТТ" },
{ startNum: "985904", operator: "МТС" },
{ startNum: "985905", operator: "МГТС" },
{ startNum: "985906", operator: "МГТС" },
{ startNum: "985907", operator: "МГТС" },
{ startNum: "985908", operator: "МГТС" },
{ startNum: "985909", operator: "МГТС" },
{ startNum: "98591", operator: "МТС" },
{ startNum: "98592", operator: "МТС" },
{ startNum: "98593", operator: "МГТС" },
{ startNum: "98594", operator: "МГТС" },
{ startNum: "98595", operator: "МГТС" },
{ startNum: "98596", operator: "МТС" },
{ startNum: "98597", operator: "МТС" },
{ startNum: "98598", operator: "МТС" },
{ startNum: "98599", operator: "МТС" },
/***************************************  986  ********************************************************* */

{ startNum: "986000", operator: "БЕЗЛИМИТ" },
{ startNum: "986001", operator: "Частный" },
{ startNum: "986002", operator: "Частный" },
{ startNum: "986003", operator: "Частный" },
{ startNum: "986004", operator: "Частный" },
{ startNum: "986005", operator: "Частный" },
{ startNum: "986006", operator: "Частный" },
{ startNum: "986007", operator: "Частный" },
{ startNum: "986008", operator: "Частный" },
{ startNum: "986009", operator: "Частный" },
{ startNum: "98601", operator: "МТС" },
{ startNum: "98602", operator: "МТС" },
{ startNum: "98603", operator: "МТС" },
{ startNum: "98604", operator: "МТС" },
{ startNum: "98605", operator: "МТС" },
{ startNum: "98606", operator: "МТС" },
{ startNum: "98607", operator: "МТС" },
{ startNum: "986080", operator: "МТС" },
{ startNum: "986081", operator: "МТС" },
{ startNum: "986082", operator: "МТС" },
{ startNum: "986083", operator: "МТС" },
{ startNum: "986084", operator: "МТС" },
{ startNum: "986085", operator: "МТС" },
{ startNum: "986086", operator: "МТС" },
{ startNum: "986087", operator: "МТС" },
{ startNum: "9860880", operator: "МТС" },
{ startNum: "9860881", operator: "МТС" },
{ startNum: "9860882", operator: "МТС" },
{ startNum: "9860883", operator: "МТС" },
{ startNum: "9860884", operator: "МТС" },
{ startNum: "9860885", operator: "МТС" },
{ startNum: "9860886", operator: "МТС" },
{ startNum: "9860887", operator: "МТС" },
{ startNum: "9860888", operator: "ООО ИК СИБИНТЕК" },
{ startNum: "9860889", operator: "Интернод" },
{ startNum: "986089", operator: "МТС" },
{ startNum: "98609", operator: "МТС" },
{ startNum: "98610", operator: "МТС" },
{ startNum: "986110", operator: "МТС" },
{ startNum: "986111", operator: "БЕЗЛИМИТ" },
{ startNum: "986112", operator: "МТС" },
{ startNum: "986113", operator: "МТС" },
{ startNum: "986114", operator: "МТС" },
{ startNum: "986115", operator: "МТС" },
{ startNum: "986116", operator: "МТС" },
{ startNum: "986117", operator: "МТС" },
{ startNum: "986118", operator: "МТС" },
{ startNum: "986119", operator: "МТС" },
{ startNum: "98612", operator: "МТС" },
{ startNum: "98613", operator: "МТС" },
{ startNum: "98614", operator: "МТС" },
{ startNum: "98615", operator: "МТС" },
{ startNum: "98616", operator: "МТС" },
{ startNum: "98617", operator: "МТС" },
{ startNum: "98618", operator: "МТС" },
{ startNum: "98619", operator: "МТС" },
{ startNum: "98620", operator: "МТС" },
{ startNum: "98621", operator: "МТС" },
{ startNum: "98622", operator: "МТТ" },
{ startNum: "98623", operator: "МТС" },
{ startNum: "98624", operator: "МТС" },
{ startNum: "98625", operator: "МТС" },
{ startNum: "98626", operator: "МТС" },
{ startNum: "98627", operator: "МТС" },
{ startNum: "98628", operator: "МТС" },
{ startNum: "98629", operator: "МТС" },
{ startNum: "98630", operator: "МТС" },
{ startNum: "98631", operator: "МТС" },
{ startNum: "98632", operator: "МТС" },
{ startNum: "986330", operator: "МТС" },
{ startNum: "986331", operator: "МТС" },
{ startNum: "986332", operator: "МТС" },
{ startNum: "986333", operator: "БЕЗЛИМИТ" },
{ startNum: "986334", operator: "МТС" },
{ startNum: "986335", operator: "МТС" },
{ startNum: "986336", operator: "МТС" },
{ startNum: "986337", operator: "МТС" },
{ startNum: "986338", operator: "МТС" },
{ startNum: "986339", operator: "МТС" },
{ startNum: "98634", operator: "МТС" },
{ startNum: "98635", operator: "МТС" },
{ startNum: "98636", operator: "МТС" },
{ startNum: "98637", operator: "МТС" },
{ startNum: "98638", operator: "МТС" },
{ startNum: "98639", operator: "МТС" },
{ startNum: "98640", operator: "МТС" },
{ startNum: "98641", operator: "МТС" },
{ startNum: "98642", operator: "МТС" },
{ startNum: "98643", operator: "МТС" },
{ startNum: "986440", operator: "МТС" },
{ startNum: "986441", operator: "МТС" },
{ startNum: "986442", operator: "МТС" },
{ startNum: "986443", operator: "МТС" },
{ startNum: "986444", operator: "БЕЗЛИМИТ" },
{ startNum: "986445", operator: "МТС" },
{ startNum: "986446", operator: "МТС" },
{ startNum: "986447", operator: "МТС" },
{ startNum: "986448", operator: "МТС" },
{ startNum: "986449", operator: "МТС" },
{ startNum: "98645", operator: "МТС" },
{ startNum: "98646", operator: "МТС" },
{ startNum: "98647", operator: "МТС" },
{ startNum: "98648", operator: "МТС" },
{ startNum: "98649", operator: "МТС" },
{ startNum: "98650", operator: "МТС" },
{ startNum: "98651", operator: "МТС" },
{ startNum: "98652", operator: "МТС" },
{ startNum: "98653", operator: "МТС" },
{ startNum: "98654", operator: "МТС" },
{ startNum: "986550", operator: "МТС" },
{ startNum: "986551", operator: "МТС" },
{ startNum: "986552", operator: "МТС" },
{ startNum: "986553", operator: "МТС" },
{ startNum: "986554", operator: "МТС" },
{ startNum: "986555", operator: "МТТ" },
{ startNum: "986556", operator: "МТС" },
{ startNum: "986557", operator: "МТС" },
{ startNum: "986558", operator: "МТС" },
{ startNum: "986559", operator: "МТС" },
{ startNum: "98656", operator: "МТС" },
{ startNum: "98657", operator: "МТС" },
{ startNum: "98658", operator: "МТС" },
{ startNum: "98659", operator: "МТС" },
{ startNum: "9866000", operator: "МТТ" },
{ startNum: "9866001", operator: "МТС" },
{ startNum: "9866002", operator: "МТС" },
{ startNum: "9866003", operator: "МТС" },
{ startNum: "9866004", operator: "МТС" },
{ startNum: "9866005", operator: "МТС" },
{ startNum: "9866006", operator: "МТС" },
{ startNum: "9866007", operator: "МТС" },
{ startNum: "9866008", operator: "МТС" },
{ startNum: "9866009", operator: "МТС" },
{ startNum: "986601", operator: "МТС" },
{ startNum: "986602", operator: "МТС" },
{ startNum: "986603", operator: "МТС" },
{ startNum: "986604", operator: "МТС" },
{ startNum: "986605", operator: "МТС" },
{ startNum: "986606", operator: "МТС" },
{ startNum: "986607", operator: "МТС" },
{ startNum: "986608", operator: "МТС" },
{ startNum: "986609", operator: "МТС" },
{ startNum: "98661", operator: "МТС" },
{ startNum: "98662", operator: "МТС" },
{ startNum: "98663", operator: "МТС" },
{ startNum: "98664", operator: "МТС" },
{ startNum: "98665", operator: "МТС" },
{ startNum: "986660", operator: "МТС" },
{ startNum: "986661", operator: "МТС" },
{ startNum: "986662", operator: "МТС" },
{ startNum: "986663", operator: "МТС" },
{ startNum: "986664", operator: "МТС" },
{ startNum: "986665", operator: "МТС" },
{ startNum: "986666", operator: "Билайн	" },
{ startNum: "986667", operator: "Билайн	" },
{ startNum: "986668", operator: "Билайн	" },
{ startNum: "986669", operator: "Билайн	" },
{ startNum: "986670", operator: "Билайн	" },
{ startNum: "986671", operator: "Билайн	" },
{ startNum: "986672", operator: "Билайн	" },
{ startNum: "986673", operator: "Билайн	" },
{ startNum: "986674", operator: "Частный" },
{ startNum: "986675", operator: "Частный" },
{ startNum: "986676", operator: "Частный" },
{ startNum: "986677", operator: "Частный" },
{ startNum: "986678", operator: "Частный" },
{ startNum: "986679", operator: "Частный" },
{ startNum: "98668", operator: "МТС" },
{ startNum: "98669", operator: "МТС" },
{ startNum: "9867", operator: "МТС" },
{ startNum: "986800", operator: "МТТ" },
{ startNum: "986801", operator: "МТС" },
{ startNum: "986802", operator: "МТС" },
{ startNum: "986803", operator: "МТС" },
{ startNum: "986804", operator: "МТС" },
{ startNum: "986805", operator: "МТС" },
{ startNum: "986806", operator: "МТС" },
{ startNum: "986807", operator: "МТС" },
{ startNum: "986808", operator: "МТС" },
{ startNum: "986809", operator: "МТС" },
{ startNum: "98681", operator: "МТС" },
{ startNum: "98682", operator: "МТС" },
{ startNum: "98683", operator: "МТС" },
{ startNum: "98684", operator: "МТС" },
{ startNum: "98685", operator: "МТС" },
{ startNum: "98686", operator: "МТС" },
{ startNum: "98687", operator: "МТС" },
{ startNum: "986880", operator: "МТС" },
{ startNum: "986881", operator: "МТС" },
{ startNum: "986882", operator: "МТС" },
{ startNum: "986883", operator: "МТС" },
{ startNum: "986884", operator: "МТС" },
{ startNum: "986885", operator: "МТС" },
{ startNum: "986886", operator: "МТС" },
{ startNum: "986887", operator: "МТС" },
{ startNum: "986888", operator: "Спринт" },
{ startNum: "986889", operator: "МТТ" },
{ startNum: "98689", operator: "МТС" },
{ startNum: "9869", operator: "МТС" },
/***************************************  987  ********************************************************* */

{ startNum: "9870", operator: "МТС" },
{ startNum: "9871", operator: "МТС" },
{ startNum: "9872", operator: "МТС" },
{ startNum: "9873", operator: "МТС" },
{ startNum: "9874", operator: "МТС" },
{ startNum: "9875", operator: "МТС" },
{ startNum: "9876", operator: "МТС" },
{ startNum: "9877", operator: "МТС" },
{ startNum: "9878", operator: "МТС" },
{ startNum: "9879", operator: "МТС" },
/***************************************  988  ********************************************************* */

{ startNum: "9880", operator: "МТС" },
{ startNum: "98810", operator: "МТС" },
{ startNum: "98811", operator: "МТС" },
{ startNum: "98812", operator: "МТС" },
{ startNum: "98813", operator: "МТС" },
{ startNum: "98814", operator: "МТС" },
{ startNum: "98815", operator: "МТС" },
{ startNum: "98816", operator: "МТС" },
{ startNum: "98817", operator: "МТС" },
{ startNum: "98818", operator: "МТС" },
{ startNum: "988190", operator: "МТТ" },
{ startNum: "988191", operator: "МТТ" },
{ startNum: "988192", operator: "МТТ" },
{ startNum: "988193", operator: "МТТ" },
{ startNum: "988194", operator: "МТТ" },
{ startNum: "988195", operator: "МТТ" },
{ startNum: "988196", operator: "МТТ" },
{ startNum: "988197", operator: "МТТ" },
{ startNum: "988198", operator: "МТТ" },
{ startNum: "988199", operator: "МТТ" },
{ startNum: "9882", operator: "МТС" },
{ startNum: "9883", operator: "МТС" },
{ startNum: "98840", operator: "МТС" },
{ startNum: "98841", operator: "МТС" },
{ startNum: "98842", operator: "МТС" },
{ startNum: "98843", operator: "МТС" },
{ startNum: "98844", operator: "МТС" },
{ startNum: "98845", operator: "МТС" },
{ startNum: "98846", operator: "МТС" },
{ startNum: "98847", operator: "МТС" },
{ startNum: "988480", operator: "МТС" },
{ startNum: "988481", operator: "МТС" },
{ startNum: "988482", operator: "МТС" },
{ startNum: "988483", operator: "МТС" },
{ startNum: "988484", operator: "МТТ" },
{ startNum: "988485", operator: "МТТ" },
{ startNum: "988486", operator: "МТС" },
{ startNum: "988487", operator: "МТС" },
{ startNum: "988488", operator: "МТС" },
{ startNum: "988489", operator: "МТС" },
{ startNum: "98849", operator: "МТС" },
{ startNum: "9885", operator: "МТС" },
{ startNum: "9886", operator: "МТС" },
{ startNum: "9887", operator: "МТС" },
{ startNum: "9888", operator: "МТС" },
{ startNum: "9889", operator: "МТС" },
/***************************************  989  ********************************************************* */

{ startNum: "98900", operator: "МТС" },
{ startNum: "98901", operator: "МТС" },
{ startNum: "98902", operator: "МТС" },
{ startNum: "98903", operator: "МТС" },
{ startNum: "98904", operator: "МТС" },
{ startNum: "98905", operator: "МТС" },
{ startNum: "98906", operator: "МТС" },
{ startNum: "98907", operator: "Лайкамобайл" },
{ startNum: "98908", operator: "МТС" },
{ startNum: "98909", operator: "МТС" },
{ startNum: "9891", operator: "МТС" },
{ startNum: "9892", operator: "МТС" },
{ startNum: "989300", operator: "Сбербанк-Телеком" },
{ startNum: "989301", operator: "МТС" },
{ startNum: "989302", operator: "МТС" },
{ startNum: "989303", operator: "МТС" },
{ startNum: "989304", operator: "МТС" },
{ startNum: "989305", operator: "МТС" },
{ startNum: "989306", operator: "МТС" },
{ startNum: "989307", operator: "МТС" },
{ startNum: "989308", operator: "МТС" },
{ startNum: "989309", operator: "МТС" },
{ startNum: "98931", operator: "МТС" },
{ startNum: "98932", operator: "МТС" },
{ startNum: "98933", operator: "МТС" },
{ startNum: "98934", operator: "МТС" },
{ startNum: "98935", operator: "МТС" },
{ startNum: "989360", operator: "МТС" },
{ startNum: "989361", operator: "МТС" },
{ startNum: "989362", operator: "МТС" },
{ startNum: "989363", operator: "МТТ" },
{ startNum: "989364", operator: "МТТ" },
{ startNum: "989365", operator: "МТС" },
{ startNum: "989366", operator: "МТС" },
{ startNum: "989367", operator: "МТС" },
{ startNum: "989368", operator: "МТС" },
{ startNum: "989369", operator: "МТС" },
{ startNum: "98937", operator: "МТС" },
{ startNum: "98938", operator: "МТС" },
{ startNum: "989390", operator: "МТС" },
{ startNum: "989391", operator: "МТС" },
{ startNum: "989392", operator: "МТС" },
{ startNum: "989393", operator: "МТС" },
{ startNum: "989394", operator: "МТС" },
{ startNum: "989395", operator: "МТС" },
{ startNum: "989396", operator: "МТС" },
{ startNum: "989397", operator: "МТС" },
{ startNum: "989398", operator: "МТС" },
{ startNum: "989399", operator: "МТТ" },
{ startNum: "98940", operator: "МТТ" },
{ startNum: "98941", operator: "МТС" },
{ startNum: "98942", operator: "МТС" },
{ startNum: "989430", operator: "МТС" },
{ startNum: "989431", operator: "МТС" },
{ startNum: "989432", operator: "МТС" },
{ startNum: "989433", operator: "МТС" },
{ startNum: "989434", operator: "МТС" },
{ startNum: "989435", operator: "МТС" },
{ startNum: "989436", operator: "МТТ" },
{ startNum: "989437", operator: "МТТ" },
{ startNum: "989438", operator: "МТТ" },
{ startNum: "989439", operator: "МТС" },
{ startNum: "98944", operator: "МТС" },
{ startNum: "98945", operator: "МТС" },
{ startNum: "98946", operator: "МТС" },
{ startNum: "98947", operator: "МТС" },
{ startNum: "98948", operator: "МТС" },
{ startNum: "98949", operator: "МТС" },
{ startNum: "9895", operator: "МТС" },
{ startNum: "9896", operator: "МТС" },
{ startNum: "9897", operator: "МТС" },
{ startNum: "9898", operator: "МТС" },
{ startNum: "9899", operator: "МТС" },
/***************************************  990  ********************************************************* */

{ startNum: "9640", operator: "К-телеком" },
{ startNum: "9641", operator: "МирТелеком" },
{ startNum: "9642", operator: "К-телеком" },
{ startNum: "9643", operator: "Перенос/Частный" },
{ startNum: "9644", operator: "Перенос/Частный" },
{ startNum: "9645", operator: "Перенос/Частный" },
{ startNum: "9646", operator: "Перенос/Частный" },
{ startNum: "9647", operator: "Перенос/Частный" },
{ startNum: "9648", operator: "Перенос/Частный" },
{ startNum: "9649", operator: "Перенос/Частный" },
];
export const countryCodes: Country[] = [
    { code: "+44", length: [10, 11], countryName: "Великобритания" },
    { code: "+49", length: 13, countryName: "Германия" }, // Germany
    { code: "+420", length: 9, countryName: "Чехия" }, // Czech Republic
    { code: "+39", length: 11, countryName: "Италия" }, // Italy
    { code: "+33", length: 9, countryName: "Франция" }, // France
    { code: "+34", length: 9, countryName: "Испания" }, // Spain
    { code: "+371", length: 8, countryName: "Латвия" }, // Latvia
    { code: "+370", length: 8, countryName: "Литва" }, // Lithuania
    { code: "+353", length: 9, countryName: "Ирландия" }, // Ireland
    { code: "+31", length: 9, countryName: "Нидерланды" }, // Netherlands
    { code: "+32", length: 9, countryName: "Бельгия" }, // Belgium
    { code: "+351", length: 9, countryName: "Португалия" }, // Portugal
    { code: "+358", length: 9, countryName: "Финляндия" }, // Finland
    { code: "+372", length: 7, countryName: "Эстония" }, // Estonia
    { code: "+46", length: 9, countryName: "Швеция" }, // Sweden
    { code: "+47", length: 8, countryName: "Норвегия" }, // Norway
    { code: "+43", length: 10, countryName: "Австрия" }, // Austria
    { code: "+421", length: 9, countryName: "Словакия" }, // Slovakia
    { code: "+41", length: 9, countryName: "Швейцария" }, // Switzerland
    { code: "+45", length: 8, countryName: "Дания" }, // Denmark
    { code: "+36", length: 9, countryName: "Венгрия" }, // Hungary
    { code: "+354", length: 7, countryName: "Исландия" }, // Iceland
    { code: "+385", length: 9, countryName: "Хорватия" }, // Croatia
    { code: "+1", length: 10, countryName: "США" }, // United States
    { code: "+81", length: 10, countryName: "Япония" }, // Japan
    { code: "+82", length: 10, countryName: "Южная Корея" }, // South Korea
    { code: "+86", length: 11, countryName: "Китай" }, // China
    { code: "+91", length: 10, countryName: "Индия" }, // India
    { code: "+55", length: 11, countryName: "Бразилия" }, // Brazil
    { code: "+52", length: 10, countryName: "Мексика" }, // Mexico
    { code: "+62", length: 11, countryName: "Индонезия" }, // Indonesia
    { code: "+234", length: 10, countryName: "Нигерия" }, // Nigeria
    { code: "+27", length: 9, countryName: "Южная Африка" }, // South Africa
    { code: "+63", length: 10, countryName: "Филиппины" }, // Philippines
    { code: "+90", length: 10, countryName: "Турция" }, // Turkey
    { code: "+61", length: 9, countryName: "Австралия" }, // Australia
    { code: "+64", length: 9, countryName: "Новая Зеландия" }, // New Zealand
    { code: "+66", length: 9, countryName: "Таиланд" }, // Thailand
    { code: "+60", length: 9, countryName: "Малайзия" }, // Malaysia
    { code: "+65", length: 8, countryName: "Сингапур" }, // Singapore
    { code: "+98", length: 10, countryName: "Иран" }, // Iran
    { code: "+94", length: 9, countryName: "Шри-Ланка" }, // Sri Lanka
    { code: "+386", length: 9, countryName: "Словения" }, // Slovenia
    { code: "+691", length: 10, countryName: "Федеративные Штаты Микронезии" }, // Federated States of Micronesia
    { code: "+382", length: 8, countryName: "Черногория" }, // Montenegro
    { code: "+48", length: 9, countryName: "Польша" }, // Poland
    { code: "+7", length: 10, countryName: "Казахстан" }, // Kazakhstan
    { code: "+79", length: 11, countryName: "Россия" }, // Kazakhstan

    { code: "+370", length: 8, countryName: "Литва" }, // Lithuania
    { code: "+380", length: 9, countryName: "Украина" }, // Ukraine
    { code: "+506", length: 8, countryName: "Коста-Рика" }, // Costa Rica
    { code: "+52", length: 10, countryName: "Мексика" }, // Mexico
    { code: "+855", length: 9, countryName: "Камбоджа" }, // Cambodia
];
export function checkOperator(phoneNumber: string): string {
    if (!phoneNumber) {
      return "Оператор не найден";
    }
  
    // Удаляем пробелы и дефисы
    phoneNumber = phoneNumber.replace(/\s|-/g, "");
    console.log("Очищенный номер:", phoneNumber); // Лог очищенного номера
  
    // Если номер не начинается с "+", добавляем "+" перед номером
    if (!phoneNumber.startsWith("+") && phoneNumber.length > 1) {
      for (const country of countryCodes) {
        if (phoneNumber.startsWith(country.code.replace("+", ""))) {
          phoneNumber = `+${phoneNumber}`;
          console.log("Добавлен символ + к номеру:", phoneNumber); // Лог после добавления "+"
          break;
        }
      }
    }
  
    // Проверка российских номеров
    if (phoneNumber.startsWith("+7") || phoneNumber.startsWith("8")) {
      // Удаляем код страны (первое "8" или "+7")
      const rawNumber = phoneNumber.startsWith("+7") ? phoneNumber.slice(2) : phoneNumber.slice(1);
  
      // Находим оператора, проверяя начало номера
      for (const operator of operators) {
        if (rawNumber.startsWith(operator.startNum)) {
          console.log(`Номер соответствует оператору ${operator.operator}`);
          return `Оператор: ${operator.operator}`;
        }
      }
  
      // Если оператор не найден
      return "Оператор не найден";
    }
  
    // Проверка номеров для других стран
    for (const country of countryCodes) {
      if (phoneNumber.startsWith(country.code)) {
        const countryCodeLength = country.code.length; // Длина кода страны, включая "+"
        
        // Ожидаемая длина номера (полная длина минус длина кода страны)
        const validLength = country.length;
  
        // Проверяем длину номера, учитывая код страны
        const numberLengthWithoutCode = phoneNumber.length - countryCodeLength;
  
        // Если длина номера без кода совпадает с одной из допустимых длин
        if (Array.isArray(validLength)) {
          // Если допустимых длин несколько (например, для Великобритании)
          if (validLength.includes(numberLengthWithoutCode)) {
            console.log(phoneNumber.length);
            console.log(validLength);
            return `Код страны: ${country.countryName}`;
          } else {
            console.log(phoneNumber.length);
            console.log(validLength);
            return `Некорректный номер для страны ${country.countryName}`;
          }
        } else {
          // Для стран с одной допустимой длиной
          if (numberLengthWithoutCode === validLength) {
            console.log(phoneNumber.length);
            console.log(validLength);
            return `Код страны: ${country.countryName}`;
          } else {
            console.log(phoneNumber.length);
            console.log(validLength);
            return `Некорректный номер для страны ${country.countryName}`;
          }
        }
      }
    }
  
    // Если не соответствует ни одному правилу
    return "Страна не найдена или некорректный номер";
  }